import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { getInputStatus } from '../../../services/commonServices';
import PrintInputError from '../../InputUtils/PrintInputError';
import ClickOutHandler from 'react-onclickout';
import InputDescription from '../../InputUtils/InputDescription';

const TypeFormColor = ({
	id,
	label,
	name,
	value,
	editable = true,
	onChange,
	isEditing = true,
	required,
	errors,
	labelColumn = {
		xs: 6,
		md: 2
	},
	inputColumn = {
		xs: 6,
		md: 10
	},
	alwaysEditableFields,
	relatedData
}) => {
	const [openColorPicker, setOpenColorPicker] = useState(false);
	const [volatileValue, setVolatileValue] = useState(value);

	useEffect(() => {
		setVolatileValue(value || '');
	}, [value]);

	const handleColorPicker = (newData) => {
		if (newData) onChange({ target: { value: newData.hex, name } });
	};

	const handleVolatileColor = (newData) => {
		if (newData) setVolatileValue(newData.hex);
	};

	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;

	return (
		<FormGroup tag="fieldset" row>
			<Col {...labelColumn}>
				<Label for={name}>
					{required ? '*' : ''} {label}
				</Label>
			</Col>
			<Col {...inputColumn}>
				<ClickOutHandler onClickOut={() => setOpenColorPicker(false)}>
					<Input
						name={name}
						id={id}
						required={required}
						value={value}
						onChange={onChange}
						disabled={!isEditable || !isEditing}
						className={getInputStatus(required, value, errors)}
						onFocus={() => setOpenColorPicker(true)}
					/>
					{openColorPicker && (
						<ChromePicker
							// className="position-absolute"
							color={volatileValue}
							onChange={handleVolatileColor}
							onChangeComplete={handleColorPicker}
						/>
					)}
				</ClickOutHandler>
				<PrintInputError errors={errors} />
				<InputDescription description={relatedData ? relatedData.description : ''} />
			</Col>
		</FormGroup>
	);
};

export default TypeFormColor;
