import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BarButton = (props) => {
	const { config, className, size, parseStyles, onClick = () => {}, validateBtn = () => true } = props;

	const handleOptions = () => onClick(config);

	const isValid = validateBtn(config);

	if (!isValid) return null;

	const styles = parseStyles(config.style);

	const renderBtn = () => (
		<Button
			size={size}
			onClick={handleOptions}
			className={config.class}
			style={{ backgroundColor: config.bgcolor, ...styles }}>
			<span className="btn-wrapper--icon">
				<FontAwesomeIcon icon={config.icon} className="text-light" />
			</span>
			<span className="btn-wrapper--label" style={{ color: 'inherit' }}>
				{config.title}
			</span>
		</Button>
	);

	if (!className) return renderBtn();

	return <div className={className}>{renderBtn()}</div>;
};

BarButton.propTypes = {
	config: PropTypes.object,
	className: PropTypes.string,
	size: PropTypes.string
};

BarButton.defaultProps = {
	size: 'sm'
};

export default BarButton;
