import fetcher from './fetcher';
import { API_ENDPOINTS, WITH_GLOBAL_FILTERS } from '../config';

export default async function getAppConfig(urlParams) {
	if (Object.keys(urlParams).length || !WITH_GLOBAL_FILTERS) {
		const config = await fetcher({ path: API_ENDPOINTS.config, urlParams });
		return config;
	} else {
		const companies = await fetcher({ path: '/companies/global-filter/', urlParams: { nitems: 1000 } });
		const contracts = await fetcher({
			path: '/contracts/global-filter/',
			urlParams: { nitems: 1000, client: companies[0]?.pk }
		});
		const config = await getAppConfig({ client: companies[0]?.pk, contract: contracts[0]?.pk });
		return config;
	}
}
