import React, { useContext, useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../config';
import AppContext from '../context';
import { UPDATE_GLOBAL_PARAMS } from '../reducers/config-reducer/types';
import fetcher from '../services/fetcher';
import { toastError, toastSuccess } from '../utils/ToastConfig';
import { useTranslation } from 'react-i18next';
import { getInputInfo } from '../utils';

export default function WithCustomConfig(
	OriginalComponent,
	pageInfo = { paramKey: '', endpoint: '', endpoint_data: '' },
	options = { autoFetch: true, autoSaveParam: true, showLoadingTitle: true, customConfig: {} }
) {
	const NewComponent = (props) => {
		const [loading, setLoading] = useState(true);
		const [basicInfo, setBasicInfo] = useState({});
		const [configInfo, setConfigInfo] = useState({});
		const [isReady, setIsReady] = useState();
		const [resetCount, setResetCount] = useState(1);
		const [dataPk, setDatapk] = useState();
		const [t] = useTranslation('app');
		const {
			config: { gParamsIsReady, ...globalConfig },
			dispatch
		} = useContext(AppContext);
		const { id } = props.id ? props : props.match?.params || {};
		const { formContext, initialConfig } = props;
		const { autoFetch = true, autoSaveParam = true, showLoadingTitle = true, customConfig = {} } = options;

		useEffect(() => {
			if (dataPk) {
				if (autoFetch) fetchAllData();
				if (autoSaveParam && !formContext) addNewParam();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [dataPk]);

		useEffect(() => {
			if (gParamsIsReady) {
				setIsReady(true);
				if (!formContext && id) {
					setDatapk(id);
				}
			}
			return () => {
				if (dispatch) {
					const currentGlobalParams = { ...globalConfig.globalParams };
					delete currentGlobalParams[pageInfo.paramKey];
					return dispatch({
						type: UPDATE_GLOBAL_PARAMS,
						payload: currentGlobalParams,
						replace: true
					});
				}
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [gParamsIsReady]);

		useEffect(() => {
			if (isReady && formContext && initialConfig) {
				const pkFromForm = formContext.allFormData[initialConfig.field_listen];
				if (pkFromForm !== dataPk) setDatapk(pkFromForm);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [formContext, isReady]);

		const getMethodConfig = (method = 'read', methods = []) => {
			if (methods && Array.isArray(methods)) return methods.find((item) => item.key === method);
			return null;
		};

		const resetAll = () => {
			fetchAllData();
			setResetCount((prev) => prev + 1);
		};

		async function addNewParam() {
			if (dispatch)
				dispatch({
					type: UPDATE_GLOBAL_PARAMS,
					payload: {
						[pageInfo.paramKey]: dataPk
					}
				});
		}

		async function fetchAllData() {
			setLoading(true);
			const params =
				globalConfig.globalParams && typeof globalConfig.globalParams === 'object'
					? globalConfig.globalParams
					: {};
			const configsFetched = pageInfo.endpoint
				? await fetcher({
						path: `${API_ENDPOINTS.config}${pageInfo.endpoint}/`.replace(/\/\//g, "/"),
						urlParams: { type: 'form', ...params }
				  })
				: customConfig;
			const dataFetched = await fetcher({
				path: `${pageInfo.endpoint_data || pageInfo.endpoint}${dataPk}/`.replace(/\/\//g, "/")
			});
			if (dataFetched && configsFetched) {
				const newFields = Object.keys(configsFetched || {})?.length
					? configsFetched.fields?.map((fieldInfo) => getInputInfo(fieldInfo, dataFetched))
					: dataFetched.fields;
				setConfigInfo({ ...configsFetched, fields: newFields });
				setBasicInfo(dataFetched);
				setLoading(false);
				return { data: dataFetched, config: configsFetched, error: false };
			}
			setLoading(false);
			return { error: true };
		}

		async function submitHandler(data, editInfo = {}) {
			setLoading(true);
			const methodConfig = getMethodConfig('edit', configInfo.actions);
			if (!methodConfig) return setLoading(false);
			const saveRecord = await fetcher({
				path: `${methodConfig.uri}${dataPk}/`,
				method: 'PATCH',
				body: data
			});

			if (saveRecord.error) toastError(`${saveRecord.error}`);
			else {
				await fetchAllData();
				toastSuccess(t('save'));
			}
			setLoading(false);
		}

		const pageConfig = {
			submitHandler,
			fetchAllData,
			basicInfo,
			configInfo,
			globalConfig,
			addNewParam,
			loading,
			id: dataPk,
			resetAll
		};
		if (showLoadingTitle && loading && basicInfo && !('error' in basicInfo)) return <p>{t('fetchingConfig')}</p>;
		return <OriginalComponent {...props} pageConfig={pageConfig} key={resetCount} />;
	};

	return NewComponent;
}
