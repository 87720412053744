import React from 'react';
import PropTypes from 'prop-types';

export default function DocumentsTableHeader(props) {
	const renderHeads = () => {
		return props.heads.map((item) => {
			if (('print' in item && item.print) || !('print' in item)) return <th>{item.title}</th>;
			return null;
		});
	};

	return <thead className={`DocumentsTable-head ${props.classNameHead || ''}`}>{renderHeads()}</thead>;
}

DocumentsTableHeader.propTypes = {
	heads: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string.isRequired })).isRequired
};
