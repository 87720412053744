export const readSessionStorage = (key, defaultValue = '') => {
	return sessionStorage.getItem(key) || defaultValue;
};

export const writeSessionStorage = (key, value, defaultValue = '') => {
	sessionStorage.setItem(key, value || defaultValue);
};

export const clearSessionStorage = () => sessionStorage.clear();

export const deleteItemToSessionStorage = (key) => sessionStorage.removeItem(key);
