import React from 'react';
import PropTypes from 'prop-types';
import ImatgesComponent from '../../CustomInstallacioTelegestio/ImatgesComponent';

const TypeGalleryTelegestio = (props) => {
    const { editable = true, isEditing = true, options: inputConfig, isCreating } = props;
    if (isCreating) return null;
    return (
        <ImatgesComponent
            postParams={inputConfig.params_upload}
            getParams={inputConfig.params}
            disabled={!editable || !isEditing}
        />
    );
};

TypeGalleryTelegestio.propTypes = {
    editable: PropTypes.bool,
    isEditing: PropTypes.bool,
    options: PropTypes.shape,
    isCreating: PropTypes.bool
};

export default TypeGalleryTelegestio;
