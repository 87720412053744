import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer } from 'react-leaflet';
import { useHistory } from 'react-router-dom';
// import PropTypes from 'prop-types';

import ConfirmMarkerMove from './ConfirmMarkerMove';
// import MapEventsHandler from './MapEventsHandler';
import MarkerLists from './MarkerLists';
import TableSearch from '../TableSearch';
import authenticatedFetcher from '../../services/fetcher';
import { RASTER_LAYERS } from './MapConfig';
import MapFilters from './MapFilters';
import BasicBtn from '../BasicBtn';
import AppContext from '../../context';
import { BASE_URL } from '../../config';
import DynamicRaster from './DynamicRaster';
import { getMapConfig, setMapConfig } from '../../services/mapServices';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import LoadingSpinner from '../LoadingSpinner';
import MapPrinter from './MapPrinter';
import { UncontrolledAlert } from 'reactstrap';
import MapCenter from './MapCenter';
let searchInputTimeOut = null;

const zoomFromMap = (mapRadius) => {
	switch (+mapRadius) {
		case 250:
			return 17;
		case 500:
			return 16.25;
		case 1000:
			return 15.75;
		case 2000:
			return 14.75;
		case 5000:
			return 13.5;
		case 10000:
			return 12.5;
		default:
			return 12;
	}
};

const Map = ({ endPointConfig, redirectTo, popupStructure, params }) => {
	const history = useHistory();
	const {
		config: { mainParams }
	} = useContext(AppContext);
	const mapRadius = zoomFromMap(params.radius);
	const [loading, setLoading] = useState(true);
	const [loadingExport, setLoadingExport] = useState(false);
	const [filterData, setFilterData] = useState(null);
	const [selectedRaster, setSelectedRaster] = useState(0);
	const [markerToUpdate, setMarkerToUpdate] = useState(null);
	const [mapCenter, setMapCenter] = useState(null);
	const [points, setPoints] = useState([]);
	const [resultFiltered, setResultFiltered] = useState({});
	const [isMounted, setIsMounted] = useState(false);
	const [isFilterLoading, setIsFilterLoading] = useState(false);
	const [markerBackup, setMarkerBackup] = useState({});
	const [searchValue, setSeachValue] = useState('');
	const [customRaster, setCustomRaster] = useState(RASTER_LAYERS);
	const [lastFilterApplied, setLastFilterApplied] = useState(null);

	const [t] = useTranslation(['map', 'modal', 'app']);

	useEffect(() => {
		getMapPoints();
		getCustomMaps();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isMounted) {
			clearTimeout(searchInputTimeOut);
			searchInputTimeOut = setTimeout(
				() => ((searchValue && searchValue.length > 2) || !searchValue ? getMapPoints(false) : null),
				500
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	useEffect(() => {
		if (isMounted) {
			getMapPoints(false);
		} else {
			setIsMounted(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resultFiltered]);

	const getCustomMaps = () =>
		authenticatedFetcher({
			path: '/contracts/custom-maps/',
			urlParams: mainParams
		}).then((res = {}) => {
			if (!res || res.error) return null;
			return setCustomRaster((prev) => {
				const newCustomRasters = res.map((item) => ({
					title: item.name,
					url: `${BASE_URL}/maps-config/shape/?`,
					params: {
						format: '',
						continuousWorld: true,
						shape: item.shape
					}
				}));
				return [...prev, ...newCustomRasters];
			});
		});

	async function getMapPoints(withLoad = true) {
		const currentParams = { ...params };
		if (withLoad) {
			setLoading(true);
		} else {
			setIsFilterLoading(true);
		}

		if (searchValue) {
			currentParams['q'] = searchValue;
			currentParams['fields'] = 'code,name';
		}
		//
		// console.log('33333');
		// console.log(currentParams);
		// console.log('33333');
		// console.log('4444');
		// console.log(resultFiltered);
		// console.log('44444');
		const data = await authenticatedFetcher({
			path: endPointConfig.action,
			urlParams: { ...currentParams, ...resultFiltered }
		}).then((res = {}) => {
			if (!res || res.error) return null;
			return res;
		});

		if (!data) return null;
		if (!data && withLoad) return setLoading(false);

		if (data?.point_coordinates) {
			setMapCenter({ geometry: { coordinates: [data.point_coordinates.lat, data.point_coordinates.lon] } });
		} else {
			setMapCenter({ geometry: { coordinates: [41.35, 2.08333] } });
		}

		if (data.convex_hull) {
			const getPoints = new XMLHttpRequest();
			getPoints.open('GET', `${process.env.REACT_APP_MEDIA_BASE_URL}${data.convex_hull}`);
			getPoints.responseType = 'json';
			getPoints.send();
			getPoints.onload = function () {

				setPoints(getPoints.response || []);
				if (withLoad) {
					setLoading(false);
				}
			};
		} else {
			if (withLoad) {
				setLoading(false);
			}
		}

		const getFilters = new XMLHttpRequest();
		getFilters.open('GET', `${process.env.REACT_APP_MEDIA_BASE_URL}${data.filters}`);
		getFilters.responseType = 'json';
		getFilters.send();
		getFilters.onload = function () {
			// console.log('*******************');
			// console.log(lastFilterApplied);
			// console.log('*******************');
			let response_filters = getFilters.response;
			setFilterData((prev) => {
				if (prev && Object.keys(prev).length) {
					if( lastFilterApplied !== undefined && lastFilterApplied !== null && response_filters !== undefined
						&& response_filters !== null && response_filters[lastFilterApplied] !== undefined
						&& resultFiltered !== undefined && resultFiltered !== null && resultFiltered[lastFilterApplied] !== undefined ) {
						// console.log('SÍ está!!!!');
						response_filters[lastFilterApplied] = eval('prev.'+lastFilterApplied);
					}
					// console.log('2');
					// console.log(response_filters);
					// console.log('2');
					return {
						...response_filters,
						family_parent: prev.family_parent
					};
				}
				return response_filters;
			});
			setIsFilterLoading(false);
		}
	}

	const getPopupData = async (markerData) => {
		const [lon, lat] = markerData.coordinates;
		const data = await authenticatedFetcher({
			path: `${endPointConfig.action}?lat=${lat}&lon=${lon}`,
			urlParams: { filters: 1, ...resultFiltered, ...mainParams }
		}).then((res) => {
			if (!res || res.error) return null;
			return res;
		});
		return data;
	};

	const handleFiltersChange = (newFilterData) => {
		// console.log('ENTRO AQUI!');
		const newObject = Object.entries(newFilterData).reduce((acc, [key, value]) => {
			if (Array.isArray(value) && value.length) {
				setLastFilterApplied(key);
				const newParams = value.join(',');
				return {
					...acc,
					[key]: newParams || 0
				};
			} else return acc;
		}, {});
		setResultFiltered(newObject);
	};

	const confirmMarkerChange = (newPosition) => {
		setMarkerToUpdate(newPosition);
		const beforePoint = points.find((item) => item.pk === newPosition.pk);
		setMarkerBackup(beforePoint);
		setPoints((prev) =>
			prev.map((item) => {
				if (item.pk === newPosition.pk)
					return {
						...item,
						coordinates: [newPosition.coordinate_x, newPosition.coordinate_y]
					};
				return item;
			})
		);
	};

	const handleSelectedRaster = (idx) => () => setSelectedRaster(idx);

	const updateMarkerPosition = (markerData = {}) => {
		if (!markerData) return;
		const { pk, coordinate_x, coordinate_y, update_endpoint } = markerData;
		authenticatedFetcher({
			path: `/${update_endpoint}/${pk}/coordinates/`,
			body: {
				coordinate_x,
				coordinate_y
			},
			method: 'PATCH'
		}).then(() => {
			getMapPoints(false);
		});
	};

	const toggleConfirmModal =
		(isConfirmed = false) =>
			() => {
				if (isConfirmed) updateMarkerPosition(markerToUpdate);
				else {
					setPoints((prev) =>
						prev.map((item) => {
							if (item.pk === markerToUpdate.pk) return markerBackup;
							return item;
						})
					);
				}
				setMarkerToUpdate(null);
			};

	const handleInventaryClick = () => {
		history.push(redirectTo);
	};

	const handleWithoutParams = () => {
		const mapData = getMapConfig();
		const configData = mapData?.endPointConfig;
		if (!configData) return history.push('/');
		const newConfig = { ...mapData, endPointConfig: { ...configData, params: {} } };
		setMapConfig(newConfig);
		history.push('/families/0/Map/');
	};

	const handleClearFilters = () => {
		history.go(0);
	};

	const handleExport = async () => {
		setLoadingExport(true);
		const currentParams = { ...params };

		if (searchValue) {
			currentParams['q'] = searchValue;
			currentParams['fields'] = 'code,name';
		}

		const data = await authenticatedFetcher({
			path: `${endPointConfig.action}export-map/`,
			urlParams: { ...currentParams, ...resultFiltered }
		})
			.then((res) => {
				if (!res || res.error) return toastError(res.error);
				return res;
			})
			.catch(() => {
				setLoadingExport(false);
			})
			.finally(() => {
				setLoadingExport(false);
			});

		toastSuccess(data.response, { autoClose: !data.print_fixed_message_response }, true);
	};

	const renderMap = () => {
		if (loading) return <p>{t('app:loading')}</p>;
		if (!mapCenter?.geometry?.coordinates[0] || !mapCenter?.geometry?.coordinates[1])
			return <p>{t('app:noApiResult')}</p>;
		return (
			<MapContainer center={mapCenter.geometry.coordinates} zoom={mapRadius} fullscreenControl preferCanvas>
				{customRaster[selectedRaster] && <DynamicRaster {...customRaster[selectedRaster]} />}
				{/* {displayComarques && RASTER_LAYER_COMARQUES} */}
				<MapCenter data={mapCenter} />
				<div className="CustomMap-extraContent">
					<div className="card card-box CustomMap-searcher">
						<TableSearch
							setKeywords={setSeachValue}
							disabled={isFilterLoading || loading}
							placeholder="Enter per Cerca Actiu..."
						/>
					</div>
					<div className="CustomMap-cleanFilters">
						<BasicBtn variant="CustomMap-cleanFilter mr-2" onClick={handleWithoutParams}>
							{t('app:seeAll')}
						</BasicBtn>
						<BasicBtn variant="CustomMap-cleanFilter mr-2" onClick={handleClearFilters}>
							{t('map:buttonNames.delete_filters')}
						</BasicBtn>
						<BasicBtn variant="CustomMap-cleanFilter mr-2" onClick={handleExport}>
							<LoadingSpinner show={loadingExport} />
							{t('map:buttonNames.export')}
						</BasicBtn>
					</div>
				</div>
				<MapPrinter />
				{points && points.length && (
					<MarkerLists
						markerRadiusValue={6}
						markers={points}
						popupOptions={{ onInventariClick: redirectTo ? handleInventaryClick : null }}
						getPopupData={getPopupData}
						popupStructure={popupStructure}
						confirmMarkerChange={confirmMarkerChange}
					/>
				) ||
					<UncontrolledAlert color="light" className="alert-center-map">
						{t('app:mapa_sense_actius')}
					</UncontrolledAlert>}
			</MapContainer>
		);
	};

	return (
		<React.Fragment>
			{endPointConfig.action.includes('tele-assets') && (
				<div style={{'display': 'flex'}}>
					<label style={{'width': '15px', 'height': '15px', 'background': 'transparent', 'border': '3px solid #00008B', 'border-radius': '15px'}}></label>
					<span style={{'color': '#00008B', 'padding-left': '5px'}}>No comunica</span>
					<label style={{'margin-left': '15px', 'width': '15px', 'height': '15px', 'background': 'transparent', 'border': '3px solid red', 'border-radius': '15px'}}></label>
					<span style={{'color': 'red', 'padding-left': '5px'}}>Amb alarmes</span>
					<label style={{'margin-left': '15px', 'width': '15px', 'height': '15px', 'background': 'transparent', 'border': '3px solid green', 'border-radius': '15px'}}></label>
					<span style={{'color': 'green', 'padding-left': '5px'}}>Sense alarmes</span>
					<label style={{'margin-left': '15px', 'width': '15px', 'height': '15px', 'background': 'white', 'border': '3px solid blue', 'border-radius': '15px'}}></label>
					<span style={{'padding-left': '5px'}}>Varis actius a la mateixa posició</span>
				</div>
			)}
			<div className="MapModal">
				<div className="MapContainer CustomMap">
					{renderMap()}
					{filterData && (
						<MapFilters
							defaultValues={params}
							rasters={customRaster}
							selectedRaster={selectedRaster}
							setSelectedRaster={handleSelectedRaster}
							filters={filterData}
							onChange={handleFiltersChange}
						/>
					)}
					{isFilterLoading && (
						<div className="LoadingFilters">
							<div className="loadingFiltersText">{t('app:loading')}</div>
						</div>
					)}
				</div>
				<ConfirmMarkerMove
					isOpen={!!markerToUpdate}
					toggleModal={toggleConfirmModal()}
					onConfirm={toggleConfirmModal(true)}
					onCancel={toggleConfirmModal()}
				/>
			</div>
		</React.Fragment>
	);
};

Map.propTypes = {};

export default Map;
