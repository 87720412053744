import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../DocumentsTable';
import TableRow from '../DocumentsTable/DocumetsTableRow';
import TableCell from '../DocumentsTable/DocumentsTableCell';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentMenuActions from '../../components/contentMenuActions';
import { Fragment } from 'react';
import TableFooterPag from '../TableFooterPag';

const BasicTable = ({
	header = [],
	items = [],
	useActions,
	className = '',
	tableProps = {},
	handleTrashClick,
	handleLupaClick,
	handleEditClick
}) => {
	const [current, setCurrent] = useState([]);
	const [lastPage, setLastPage] = useState(1);
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (Array.isArray(items)) {
			setLastPage(Math.ceil(items.length / 1000));
			if (items.length > 1000) setCurrent(items.slice(0, 999));
			else setCurrent(items);
		}
	}, [items]);

	const handlePage = (newPage) => {
		setPage(() => {
			const first = (newPage - 1) * 1000;
			setCurrent(items.slice(first, first + 999));
			return newPage;
		});
	};

	const addBtn = (
		<Button className="CustomTicketingTreball-btn-fields ml-3 position-relative" size="sm" color="success">
			<span className="btn-wrapper--icon">
				<FontAwesomeIcon icon={['fas', 'plus']} className="text-light" />
			</span>
		</Button>
	);

	const [t] = useTranslation('app');
	const defaultHeaderValues = [
		{ title: t('ticketing.element'), cellFieldName: 'element_name' },
		{ title: t('ticketing.state'), cellFieldName: 'status_name' },
		{ title: t('ticketing.action'), cellFieldName: 'sub_status_name' },
		{ title: t('ticketing.category'), cellFieldName: 'category_name' },
		{ title: t('ticketing.date'), cellFieldName: 'date' },
		{ title: t('ticketing.operator'), cellFieldName: 'team' },
		{ title: t('ticketing.time'), cellFieldName: 'hours_dedicated' },
		{ title: addBtn }
	];

	const onLupaClick = (item) => () => handleLupaClick && handleLupaClick(item);
	const onEditClick = (item) => () => handleEditClick && handleEditClick(item);
	const onTrashClick = (item) => () => handleTrashClick && handleTrashClick(item);

	const renderActions = (itemData) => {
		if (!useActions) return <Fragment />;

		return (
			<TableCell>
				<ContentMenuActions
					trash
					lupa={false}
					edit
					showActions
					handleLupaClick={onLupaClick(itemData)}
					handleEditClick={onEditClick(itemData)}
					handleTrashClick={onTrashClick(itemData)}
				/>
			</TableCell>
		);
	};

	const headerContent = header.length > 0 ? header : defaultHeaderValues;
	const renderRow = () => {
		return Array.isArray(headerContent)
			? current.map((item, index) => {
					const rowStyle = item.error ? { color: 'red' } : {};
					return (
						<TableRow key={`newRow ${index}`}>
							{headerContent.map((headInfo, cellIndex) => {
								if (('print' in headInfo && headInfo.print) || !('print' in headInfo))
									return (
										<TableCell key={`newCell ${cellIndex}`} style={rowStyle}>
											{typeof item[headInfo.cellFieldName] === 'boolean'
												? item[headInfo.cellFieldName]
													? 'Si'
													: 'No'
												: item[headInfo.cellFieldName]}
										</TableCell>
									);
								return null;
							})}
							{renderActions(item)}
						</TableRow>
					);
			  })
			: null;
	};
	return (
		<div className={`col-12 ${className}`}>
			<Table heads={headerContent} {...tableProps}>
				{renderRow()}
			</Table>
			{Array.isArray(items) && items.length > 1000 && (
				<TableFooterPag
					visiblePages={4}
					lastPage={lastPage}
					totalItems={items.length}
					page={page}
					setPage={handlePage}
					pagination
				/>
			)}
		</div>
	);
};

BasicTable.defaultValues = {
	items: [
		{
			category_name: 'Equip/Driver',
			date: '23/03/2021',
			element_name: 'DRIVER LED-Interior',
			hours_dedicated: '10 h',
			pk: 1,
			status_name: 'Pendent',
			sub_status_name: 'Pendent validar client',
			team: 'AJUNTAMENT DE CORNELLÀ'
		}
	]
};

export default BasicTable;
