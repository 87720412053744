import React from 'react';
import DynamicFormInput from '../DynamicForm/DynamicFormInput';

const BasicCoordinates = ({
	onChange,
	refetchFormData,
	isEditing,
	coordX = {
		label: 'X',
		name: 'coordinate_x',
		labelColumn: {
			md: '1'
		}
	},
	coordY = {
		label: 'Y',
		name: 'coordinate_y',
		labelColumn: {
			md: '1'
		}
	},
	flexDirection = 'column'
}) => {
	if (!Object.keys(coordX).length || !Object.keys(coordY).length) return null;
	return (
		<div className={`BasicCoordinates d-flex flex-${flexDirection}`}>
			<DynamicFormInput {...coordX} onChange={onChange} refetchFormData={refetchFormData} isEditing={isEditing} />
			<DynamicFormInput {...coordY} onChange={onChange} refetchFormData={refetchFormData} isEditing={isEditing} />
		</div>
	);
};

export default BasicCoordinates;
