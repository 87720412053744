import { useEffect } from 'react';

export default function useEventListener(
  isOpen,
  target,
  type,
  listener,
  ...options
) {
  useEffect(() => {
    const targetIsRef = target.hasOwnProperty('current');
    const currentTarget = targetIsRef ? target.current : target;
    if (currentTarget && isOpen)
      currentTarget.addEventListener(type, listener, ...options);
    else if (currentTarget)
      currentTarget.removeEventListener(type, listener, ...options);
    return () => {
      if (currentTarget)
        currentTarget.removeEventListener(type, listener, ...options);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
}
