import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { MEDIA_BASE_URL } from '../../config';
import AppContext from '../../context';
import WithFormConfig from '../../hocs/WithFormConfig';
import authenticatedFetcher from '../../services/fetcher';
import { toastError } from '../../utils/ToastConfig';
import ListContext from '../List/ListContext';
import FormFieldsSelector from './FormFieldsSelector';

const noValidFields = ['file', 'static', 'gallery', 'password', 'iframe'];

const ExportModal = ({ toggle, config, urlParams, exportConfig, actions }) => {
	const [fieldsToExport, setFieldsToExport] = useState([]);
	const [fileUrl, setFileUrl] = useState('');
	const [loading, setLoading] = useState(null);
	const [fields, setFields] = useState([]);
	const {
		config: { globalParams = {} }
	} = useContext(AppContext);
	const { advancedSearchForm } = useContext(ListContext);
	const [t] = useTranslation('app');

	useEffect(() => {
		const newFields1 =
			exportConfig.formfields && Array.isArray(config.fields)
				? config.fields.filter((item) => !noValidFields.some((fieldType) => fieldType === item.type))
				: [];
		const newFields2 = Array.isArray(exportConfig.fields) ? exportConfig.fields : [];
		const newOptions = [...newFields1, ...newFields2];
		const selectedOptions = newOptions.filter((item) => item.required).map((item) => item.name);
		setFieldsToExport(selectedOptions);
		setFields(newOptions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitHandler = async () => {
		if (!actions) return;
		const { export: exportApiConfig } = actions;
		const advancedSearchData = Object.keys(advancedSearchForm || {}).length ? advancedSearchForm : {};
		if (!exportApiConfig) return;
		if (!fieldsToExport.length) return toastError(t('exportModal.noSelectionError'));
		setLoading(true);
		const exportParams = exportApiConfig.params || {};
		const requestData = {
			fields_export: fieldsToExport.join(','),
			...advancedSearchData,
			...exportParams
		};
		let requestOptions = {
			urlParams: {
				...urlParams,
				...globalParams
			}
		};
		if (exportApiConfig.method.toLowerCase() === 'post') requestOptions['body'] = requestData;
		else
			requestOptions['urlParams'] = {
				...requestOptions.urlParams,
				...requestData
			};

		const fileInfo = await authenticatedFetcher({
			path: exportApiConfig.uri,
			method: exportApiConfig.method,
			...requestOptions
		});
		setFileUrl(fileInfo?.file_relative_path ? `${MEDIA_BASE_URL}${fileInfo.file_relative_path}` : '');

		setLoading(false);
	};

	const handleAllOptions = (checkAll) => () => {
		if (checkAll) setFieldsToExport(fields.map((item) => item.name));
		else setFieldsToExport([]);
	};

	const renderFileUrl = () => {
		if (typeof loading !== 'boolean') return null;
		if (loading) return <p>{t('exportModal.generatingFileLink')}</p>;
		if (fileUrl)
			return (
				<a href={fileUrl} className="btn btn-secondary submitButton" target="_blank" rel="noopener noreferrer">
					{t('exportModal.downloadBtn')}
				</a>
			);
		return <p>ERROR</p>;
	};

	return (
		<Modal isOpen toggle={toggle} className="persona">
			<ModalHeader toggle={toggle} charCode="x">
				{config.name || 'Create Form'}
			</ModalHeader>
			<ModalBody>
				<FormFieldsSelector
					items={fields}
					title={t('exportModal.export')}
					onChange={setFieldsToExport}
					selectedItems={fieldsToExport}
				/>
			</ModalBody>
			<ModalFooter>
				<Button onClick={handleAllOptions(true)} className="submitButton">
					{t('exportModal.markAll')}
				</Button>
				<Button onClick={handleAllOptions()} className="submitButton">
					{t('exportModal.unCheckAll')}
				</Button>
				<Button onClick={submitHandler} className="submitButton">
					{t('exportModal.exportBtn')}
				</Button>
				{renderFileUrl()}
			</ModalFooter>
		</Modal>
	);
};

export default WithFormConfig(ExportModal);
