export const RASTER_LAYERS = [
	{
		type: "tile",
		title: "Street Maps",
		attribution: `&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`,
		url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png"
	},
	{
		title: "Topogràfic",
		url: "https://geoserveis.icgc.cat/icc_mapesmultibase/utm/wms/service?",
		params: {
			layers: 'topo',
			format: 'image/jpeg',
			continuousWorld: true,
			attribution: 'Institut Cartogràfic i Geològic de Catalunya'
		}
	},
	{
		title: "Ortogràfic",
		url: "https://geoserveis.icgc.cat/icc_mapesmultibase/utm/wms/service?",
		params: {
			layers: 'orto',
			format: 'image/jpeg',
			continuousWorld: true,
			attribution: 'Institut Cartogràfic i Geològic de Catalunya'
		}
	},
	{
		title: "Topogràfic gris",
		url: "https://geoserveis.icgc.cat/icc_mapesmultibase/utm/wms/service?",
		params: {
			layers: 'topogris',
			format: 'image/jpeg',
			continuousWorld: true,
			attribution: 'Institut Cartogràfic i Geològic de Catalunya'
		}
	},
	{
		title: "Ortogràfic gris",
		url: "https://geoserveis.icgc.cat/icc_mapesmultibase/utm/wms/service?",
		params: {
			layers: 'ortogris',
			format: 'image/jpeg',
			continuousWorld: true,
			attribution: 'Institut Cartogràfic i Geològic de Catalunya'
		}
	}
];

export default RASTER_LAYERS;