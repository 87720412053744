import React from 'react';
import clsx from 'clsx';

import HeaderUserbox from '../HeaderUserbox';

const Header = (props) => {
	const { headerShadow, headerBgTransparent, openMenu, toggleMenu } = props;

	return (
		<>
			<div
				className={clsx(!openMenu ? 'app-header' : 'app-header withSpace', {
					'app-header--shadow': headerShadow,
					'app-header--opacity-bg': headerBgTransparent
				})}>
				<div className="app-header--pane">
					<button
						className={clsx('navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn', {
							'is-active': openMenu
						})}
						onClick={toggleMenu}>
						<span className="hamburger-box">
							<span className="hamburger-inner" />
						</span>
					</button>
				</div>
				<div style={{ width: '100%' }}>
					<HeaderUserbox />
				</div>
			</div>
		</>
	);
};

export default Header;
