import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Button } from 'reactstrap';
import authenticatedFetcher from '../../services/fetcher';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import { DynamicForm } from '../DynamicForm';
import LoadingSpinner from '../LoadingSpinner';
import BasicTable from '../Ticketing/BasicTable';

export default function CustomModalResult(props) {
	const [t] = useTranslation('app');
	const { resultFields2_default, resultFields_default } = getDefaultConfig(t);

	const { toggle, open, resultFields = resultFields_default, resultFields2 = resultFields2_default } = props;
	const [formData, setFormData] = useState({ online: 1 });
	const [tableConfig, setTableConfig] = useState();
	const [loading, setLoading] = useState(false);
	const [loadingError, setLoadingError] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [haveError, setHaveError] = useState(false);
	const [resultData, setResultData] = useState(null);
	const [refreshCount, setRefreshCount] = useState(0);
	const [resetCount, setResetCount] = useState(0);

	useEffect(() => setRefreshCount((prev) => prev + 1), [tableConfig]);

	const resetModal = () => {
		setTableConfig(null);
		setResultData(null);
		setHaveError(false);
		setFormData({ online: 1 });
		setResetCount((prev) => prev + 1);
	};

	const handleModalityChange = () => setFormData((prev) => ({ ...prev, online: prev.online === 0 ? 1 : 0 }));

	const updateData = (newData) => setFormData((prev) => ({ ...prev, ...newData }));

	const handleSendFiles = async () => {
		if (!formData.file_results && !formData.file_mod) return toastError(t('errors.noFilesSelected'));
		setLoading(true);
		const res = await authenticatedFetcher({
			path: '/exams/import-statistics/previous/',
			method: 'POST',
			body: formData
		}).then((res) => {
			if (res && 'error' in res) {
				toastError(t('saveError'));
				return null;
			}
			const header = Array.isArray(res.header)
				? res.header.map((item) => ({ title: item.label, cellFieldName: item.field }))
				: [];
			return {
				...res,
				header
			};
		});
		setLoading(false);
		setHaveError(Array.isArray(res?.items) && res.items.some((item) => item.error));
		if (res) {
			const { header, items, ...otherData } = res;
			setResultData(
				[
					{
						type: 'integer',
						label: t('erroneous'),
						name: 'total_error',
						editable: true
					},
					{
						type: 'integer',
						label: t('correct'),
						name: 'total_success',
						editable: true
					},
					{
						type: 'integer',
						label: t('total'),
						name: 'total',
						editable: true
					}
				].map((item) => (item.name ? { ...item, value: otherData[item.name] } : item))
			);
			setTableConfig(res);
		}
	};

	const onExportErrors = async () => {
		setLoadingError(true);
		const res = await authenticatedFetcher({
			path: '/exams/import-statistics/errors/',
			method: 'POST',
			body: formData
		});
		setLoadingError(false);
		if (res && 'response' in res) {
			return toastSuccess(res.response, { autoClose: !res.print_fixed_message_response }, true);
		}
	};

	const handleSubmit = (data) => async () => {
		setLoadingSubmit(true)
		const res = await authenticatedFetcher({
			path: '/exams/import-statistics/',
			method: 'POST',
			body: data
		});
		setLoadingSubmit(false)
		if (res?.action) {
			toastSuccess(res.action);
			toggle();
		} else {
			toastError('Error');
		}
	};

	return (
		<Modal toggle={toggle} isOpen={open} className="persona">
			<ModalHeader toggle={toggle} charCode="x">
				{t('modals.load_statistical_results')}
			</ModalHeader>
			<ModalBody>
				<Card>
					<CardBody>
						<div className="ExportTest">
							<div className="row">
								<div className="col-12">
									<div className="ModalResult-options">
										<div className="ModalResult-radios">
											<input
												id="OnLine"
												name="modalidad"
												type="radio"
												checked={formData.online}
												onClick={handleModalityChange}
											/>
											<label className="ModalResult-label" htmlFor="OnLine">
												OnLine
											</label>
											<input
												name="modalidad"
												id="Presencial"
												type="radio"
												checked={!formData.online}
												onClick={handleModalityChange}
											/>
											<label htmlFor="Presencial">{t('face_to_face')}</label>
										</div>
										<Button
											className="ml-auto submitButton"
											type="button"
											onClick={handleSendFiles}>
											{t('read_results')}
											<LoadingSpinner show={loading} className="m-0" />
										</Button>
									</div>
								</div>
								<div className="col-12 col-md-8">
									{resultFields?.length > 0 ? (
										<DynamicForm
											key={resetCount}
											inputs={resultFields}
											onChange={updateData}
											oneColum
											formData={formData}
										/>
									) : null}
								</div>
								<div className="col-12 col-md-4 ExportTest-typefile">
									{resultFields2?.length > 0 ? (
										<DynamicForm
											key={resetCount}
											inputs={resultFields2}
											onChange={updateData}
											oneColum={true}
											formData={formData}
										/>
									) : null}
								</div>
								{tableConfig && (
									<div className="col-12">
										<div className="ExportTest-table mt-5">
											<BasicTable
												key={refreshCount}
												className="pl-0 pr-0"
												header={tableConfig.header || []}
												items={tableConfig.items || []}
												useActions={false}
											/>
										</div>
									</div>
								)}
								<div className="col-12">
									<div className="ModalResult-fields mt-4">
										<div className="row">
											<div className="col-6">
												{resultData?.length > 0 ? (
													<DynamicForm
														key={refreshCount}
														inputs={resultData}
														onChange={updateData}
														oneColum={false}
														formData={formData}
														isEditing={false}
													/>
												) : null}
											</div>
										</div>
									</div>
									{(tableConfig || resultData) && (
										<div className="mt-3 d-flex justify-content-end">
											{!haveError && (
												<Button
													className="submitButton"
													type="submit"
													onClick={handleSubmit(formData)}>
													Importar
													<LoadingSpinner show={loadingSubmit} className="m-0" />
												</Button>
											)}
											{haveError && (
												<Button className="submitButton" type="button" onClick={onExportErrors}>
													{t('export_errors')}
													<LoadingSpinner show={loadingError} className="m-0" />
												</Button>
											)}
											<Button className="ml-2 submitButton" onClick={resetModal}>
												{t('restart_import')}
											</Button>
										</div>
									)}
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
}

const getDefaultConfig = (translateFunc) => {
	const resultFields_default = [
		{
			type: 'file',
			value: '',
			label: translateFunc('modals.archive_results') + ':',
			name: 'file_results',
			editable: true,
			useLabel: true,
			labelColumn: {
				md: '3'
			},
			inputColumn: {
				md: '9'
			}
		},
		{
			type: 'file',
			value: '',
			label: translateFunc('modals.model_preg_file') + ':',
			name: 'file_mod',
			editable: true,
			useLabel: true,
			labelColumn: {
				md: '3'
			},
			inputColumn: {
				md: '9'
			},
			hideInputFunc: (formData) => formData['with_mod'] !== '1'
		}
	];
	const resultFields2_default = [
		{
			type: 'radio',
			value: '0',
			label: translateFunc('modals.file_type'),
			name: 'with_mod',
			editable: true,
			labelColumn: {
				md: '4'
			},
			inputColumn: {
				md: '8'
			},
			items: [
				{ text: translateFunc('modals.with_mod_file'), value: 1 },
				{ text: translateFunc('modals.no_mod_file'), value: 0 }
			]
		}
	];
	return { resultFields2_default, resultFields_default };
};

CustomModalResult.defaultProps = {
	toggle: () => {},
	updateData: () => {}
};
