import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Category from '../pages/Category';
import Detail from './Detail';
import CustomMap from '../pages/CustomMap/CustomMap';

function RouteWithSubRoutes(route, index = 0) {
	return [
		<Route
			key={`${index}-${route.id}`}
			path={route.path}
			exact={route.exact}
			render={(props) => <Category {...props} {...route} />}
		/>,
		<Route
			key={`${index}-${route.id}/:id/Map/`}
			path={`${route.path}:id/Map/`}
			exact={route.exact}
			render={(props) => <CustomMap {...props} {...route} />}
		/>,
		<Route
			key={`${index}-${route.id}/:id`}
			path={`${route.path}:id`}
			exact={route.exact}
			render={(props) => <Detail {...props} {...route} />}
		/>
	];
}

RouteWithSubRoutes.propTypes = {
	route: PropTypes.shape({
		id: PropTypes.string,
		label: PropTypes.string,
		path: PropTypes.string
	}).isRequired
};

export default RouteWithSubRoutes;
