import { useEffect, useState } from 'react';

export default function useSelectFormListener({ options = {}, formData, isSearcher, ...props } = {}) {
	const [valueHeard, setValueHeard] = useState(null);
	const [customParams, setCustomParams] = useState({});

	useEffect(() => {
		if ('reload' in options) {
			if (Array.isArray(options.reload)) {
				const newParams = options.reload.reduce((acc, { form_field, var_to_send }) => {
					if (customParams[var_to_send] !== formData[form_field])
						return acc
							? { ...acc, [var_to_send]: formData[form_field] }
							: { [var_to_send]: formData[form_field] };
					return acc;
				}, null);
				if(newParams) setCustomParams((prev) => ({ ...prev, ...newParams }));
			} else {
				const formValue = formData[options.reload.form_field];
				if (formValue !== valueHeard) {
					setValueHeard(formValue);
					setCustomParams({ [options.reload.var_to_send]: formValue });
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);

	return [customParams, 'reload' in options, valueHeard];
}
