import React, { Fragment, useEffect, useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import BasicBtn from '../../BasicBtn';
import BasicLink from '../../BasicLink';
import ImgsWithLightBox from '../../ImgsWithLightBox';
import LoadingSpinner from '../../LoadingSpinner';
import Message from '../../Message';

const imgRegexTest = /.(gif|jpeg|jpg|png)$/i;
const refreshLimit = 12;

const TypeIFrame = ({
	id,
	label,
	name,
	value,
	required,
	editable = true,
	isEditing = true,
	style,
	labelColumn = {
		xs: '6',
		md: '2'
	},
	inputColumn = {
		xs: '6',
		md: '10'
	},
	open = false
}) => {
	const [showIframe, setShowIframe] = useState(open);
	const { current: isImg } = useRef(imgRegexTest.test(value));
	const [loading, setLoading] = useState(!isImg && value);
	const [refreshFrame, setRefreshFrame] = useState(0);
	const [validFrame, setValidFrame] = useState();
	const iframeResetInterval = useRef();

	const handleShowIframe = () => setShowIframe((prev) => !prev);
	const handleRefresh = () => setRefreshFrame((prev) => prev + 1);
	function handleValid() {
		setValidFrame((prev) => (prev || 0) + 1);
	}
	const handleLoading = () => setLoading((prev) => !prev);

	useEffect(() => {
		iframeResetInterval.current = setInterval(() => {
			handleValid();
		}, 4000);
		return () => clearInterval(iframeResetInterval.current);
	}, []);

	useEffect(() => {
		if (loading && refreshFrame < refreshLimit) handleRefresh();
		else clearInterval(iframeResetInterval.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [validFrame]);

	const renderValue = () => {
		if (!value) return null;
		if (isImg && showIframe)
			return (
				<div className="w-100 d-flex justify-content-center">
					<ImgsWithLightBox src={value} variant="big" isOne disabled noDelete />
				</div>
			);
		return (
			<Fragment>
				{refreshFrame >= refreshLimit && (
					<Message type="error" className="mt-2" text="Error al cargar el documento..." />
				)}
				<iframe
					key={refreshFrame}
					className="w-100 mt-2"
					style={
						showIframe
							? { minHeight: '500px', maxWidth: '100%' }
							: { width: '0', height: '0', border: 'none', position: 'absolute' }
					}
					title={name}
					loading="lazy"
					src={`https://docs.google.com/gview?url=${value}&embedded=true`}
					onLoad={handleLoading}
				/>
			</Fragment>
		);
	};

	return (
		<FormGroup tag="fieldset">
			<Row className="align-items-center">
				<Col {...labelColumn}>
					<Label style={style} for="form-2-last-name">
						{required ? '*' : ''} {label}
					</Label>
				</Col>
				<Col {...inputColumn}>
					<div className="d-flex justify-content-between align-items-center">
						<BasicLink link={value} text="Abrir documento en nueva ventana" blank />
						<LoadingSpinner show={showIframe && loading} />
						<BasicBtn
							disabled={!value}
							onClick={handleShowIframe}
							icon={showIframe ? 'faEyeSlash' : 'faEye'}
							text={showIframe ? 'Ocultar' : 'Mostrar'}
						/>
					</div>
				</Col>
			</Row>
			{renderValue()}
		</FormGroup>
	);
};

TypeIFrame.propTypes = {};

export default TypeIFrame;
