import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DynamicFormLayout } from './DynamicForm';
import fetcher from '../services/fetcher';
import WithFormConfig from '../hocs/WithFormConfig';

import 'react-toastify/dist/ReactToastify.css';
import { formatFormFields } from '../services/commonServices';
import { toastError, toastSuccess } from '../utils/ToastConfig';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const DEFAULT_ACTIONS = [
	// TODO: borrar cuando la API este actualizada con las actions para cada formulario.
	{
		key: 'edit',
		uri: '/persons/',
		method: 'PUT'
	},
	{
		key: 'read',
		uri: '/persons/',
		method: 'GET'
	},
	{
		key: 'delete',
		uri: '/persons/',
		method: 'DELETE'
	},
	{
		key: 'write',
		uri: '/persons/',
		method: 'POST'
	}
];

const Detail = ({ config }) => {
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [dataFromApi, setDataFromApi] = useState({});
	const [formErrors, setFormErrors] = useState({});
	const [massiveCreationData, setMassiveCreationData] = useState();
	const [resetCount, setResetCount] = useState(1);
	const [t] = useTranslation('app');

	const { actions = DEFAULT_ACTIONS } = config;

	const [read = false] = actions.filter((action) => action.key === 'read');
	const [edit = false] = actions.filter((action) => action.key === 'edit');

	async function fetchDataFormById() {
		setLoading(true);

		if (read) {
			const dataFetched = await fetcher({ path: `${read.uri}${id}/`.replace(/\/\//g, "/") });

			dataFetched && setDataFromApi(dataFetched);
		}

		setLoading(false);
	}

	useEffect(() => {
		fetchDataFormById(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	async function submitHandler(data) {
		setSubmitLoading(true)
		const newData = formatFormFields(data);
		const saveRecord = await fetcher({
			path: `${edit.uri}${id}/`,
			method: 'PATCH',
			body: newData
		});

		if (massiveCreationData) {
			let requests = [];
			const write = massiveCreationData.gridConfig.actions.find((item) => item.key === 'write');
			massiveCreationData.gridData.forEach((item) =>
				requests.push(
					fetcher({
						path: write.uri,
						method: 'POST',
						body: {
							...item,
							[massiveCreationData.gridRelatedField]: id
						}
					}).then((res) => res && !('error' in res))
				)
			);
			const valid = await Promise.all(requests).then((allreq) => !allreq.some((item) => item === false));
			if (!valid) toastError(`Ocurrió un error en la creación masiva!`);
		}

		if (saveRecord.error) {
			setFormErrors(saveRecord.error_data);
			toastError(`${saveRecord.error}`);
		} else {
			await fetchDataFormById();
			setFormErrors({});
			toastSuccess(t('save'));
		}
		setSubmitLoading(false)
	}

	const saveMassiveCreationData = (gridData, gridConfig, gridRelatedField) =>
		setMassiveCreationData({ gridData, gridConfig, gridRelatedField });

	const resetAll = () => {
		fetchDataFormById(id);
		setResetCount((prev) => prev + 1);
	};

	if (loading) {
		return <p>{t('fetchingConfig')}</p>;
	}

	return (
		<Fragment>
			<DynamicFormLayout
				key={resetCount}
				config={config}
				onSubmit={submitHandler}
				data={dataFromApi}
				formErrors={formErrors}
				resetAll={resetAll}
				saveMassiveCreationData={saveMassiveCreationData}
				loadingSubmit={submitLoading}
			/>
			<Helmet>
				<script src="preventExit.js" type="text/javascript" />
			</Helmet>
		</Fragment>
	);
};

Detail.propTypes = {
	config: PropTypes.object.isRequired
};

export default WithFormConfig(Detail);
