import React from 'react';
import EditElement from '../../components/EditElementTelegestio';
import { CUSTOM_PAGES_INFO } from '../../config';
import ButtonsBar from '../../components/ButtonsBar';
import WithCustomConfig from '../../hocs/WithCustomConfig';

const CustomElementTelegestio = (props) => {
	const { configInfo = {}, submitHandler, id, basicInfo } = props.pageConfig;

	return (
		<div className={props.className || 'CustomElement'}>
			<ButtonsBar id={id} config={configInfo} formData={basicInfo} />
			{Array.isArray(configInfo.fields) ? (
				<EditElement
					id={id}
					basicInfo={basicInfo}
					config={configInfo}
					noEditable={props.noEditable || basicInfo.block_form}
					onSubmit={submitHandler}
					dadesTecniques={basicInfo['supplementary_data']}
				/>
			) : (
				<p>Error: form info not found...</p>
			)}
		</div>
	);
};

export default WithCustomConfig(CustomElementTelegestio, CUSTOM_PAGES_INFO.element_telegestio);
