import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Card, FormGroup, Label } from 'reactstrap';

import LoadingSpinner from '../LoadingSpinner';
import authenticatedFetcher from '../../services/fetcher';
import { useTranslation } from 'react-i18next';
import BasicIcon from '../BasicIcon';
import DynamicFormInput from '../DynamicForm/DynamicFormInput';
import Message from '../Message';
import { toastError, toastSuccess } from '../../utils/ToastConfig';

const CustomApiCommands = (props) => {
	const { toggle } = props;
	const { title } = props.initialConfig || {};
	const { data } = props.formContext || {};
	const [isLoading, setIsLoading] = useState();
	const [tablesData, setTablesData] = useState({});
	const [itemsNewData, setItemsNewData] = useState({});
	const [errorText, setErrorText] = useState('');
	const [t] = useTranslation('app');

	const getTablesData = async () => {
		setIsLoading(true);
		authenticatedFetcher({ path: `/tele-assets/${data.id || data.pk}/get-api-data/?only_lines=1` }).then((res) => {
			setIsLoading(false);
			if (res && !res.error) {
				let defaultData = {};
				setTablesData(res);
				if (Array.isArray(res.data_tables)) {
					res.data_tables.forEach(
						(item) =>
							Array.isArray(item.table_body) &&
							item.table_body.forEach((line) => {
								defaultData = {
									...defaultData,
									[line.col2_select_var]: line.col2_select || 1,
									[line.col2_var]: line.col2,
									[line.col3_select_var]: line.col3_select || 1,
									[line.col3_var]: line.col3
								};
							})
					);
				}
				if (Array.isArray(res.data_gap)) {
					res.data_gap.forEach((item) => {
						defaultData = {
							...defaultData,
							[item.col2_var]: item.col2
						};
					});
				}
				setItemsNewData(defaultData);
			}
		});
	};

	useEffect(() => {
		getTablesData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveAll = () => {
		authenticatedFetcher({
			path: `/tele-assets/${data.id || data.pk}/update-api-lines-data/`,
			method: 'POST',
			body: itemsNewData
		}).then((res) => {
			if (res && res.error && res.status === 400) {
				toastError(res.error || res.error_data);
			} else {
				toastSuccess(Array.isArray(res?.response) && res.response.length && res.response[0]);
			}
		});
	};

	const handleSaveTableData = () => {
		if (
			Object.entries(itemsNewData).some(([key, value]) => value === 2 && !itemsNewData[key.replace('_type', '')])
		) {
			return setErrorText(t('customApiCommands.error'));
		}
		setErrorText('');
		saveAll();
	};

	const selectItems = [
		{ key: 'Astro', value: 1 },
		{ key: 'Hora fixa', value: 2 },
		{ key: 'Cap/Nul', value: 3 }
	];

	const onChangeItemData =
		(isSelect) =>
		({ target: { value, name } }) =>
			setItemsNewData((prev) => ({
				...prev,
				[name]: isSelect ? +value : value
			}));

	const getValue = (name, defaultValue) => (name in itemsNewData ? itemsNewData[name] : defaultValue || 1);

	const reload = () => {
		if (typeof window !== 'undefined') window.location.reload();
	};

	const renderHeader = (headers = []) => (
		<tr>{headers && headers.map((item, i) => <th key={'headerItem-' + i}>{item}</th>)}</tr>
	);

	const renderBody = (lines = [], tableID) => (
		<tbody>
			{lines &&
				lines.map((line, i) => {
					return (
						<tr key={'row' + i}>
							<td>{line.col1}</td>
							<td>
								<div className="flex-row-center">
									<select
										className="form-control form-control-sm BasicSelect w-auto mx-2"
										id={`select-select-1-${i}`}
										value={getValue(line.col2_select_var, line.col2_select)}
										name={line.col2_select_var}
										onChange={onChangeItemData(1)}>
										{selectItems.map(({ key, value }, i) => (
											<option value={value}>{key}</option>
										))}
									</select>

									{getValue(line.col2_select_var, line.col2) === 2 && (
										<DynamicFormInput
											type="time"
											label="Hora"
											name={line.col2_var}
											value={line.col2 ? line.col2 : '00:00:00'}
											onlyInput
											editable
											onChange={onChangeItemData()}
										/>
									)}
								</div>
							</td>
							<td>
								<div className="flex-row-center">
									<select
										className="form-control form-control-sm BasicSelect w-auto mx-2"
										id={`select-select-2-${i}`}
										value={getValue(line.col3_select_var, line.col3_select)}
										name={line.col3_select_var}
										onChange={onChangeItemData(1)}>
										{selectItems.map(({ key, value }, i) => (
											<option value={value}>{key}</option>
										))}
									</select>
									{getValue(line.col3_select_var, line.col3) === 2 && (
										<DynamicFormInput
											type="time"
											label="Hora"
											name={line.col3_var}
											value={line.col3 ? line.col3 : '00:00:00'}
											onlyInput
											editable
											onChange={onChangeItemData()}
										/>
									)}
								</div>
							</td>
						</tr>
					);
				})}
		</tbody>
	);

	return (
		<Card className="card-box mb-3 mt-4 CustomApiCommands">
			{title && (
				<div className="card-header py-3">
					<div className="card-header--title font-size-lg">
						PROGRAMACIÓ SORTIDES
						<LoadingSpinner show={isLoading} />
					</div>
					{!isLoading && tablesData.last_update && (
						<Fragment>
							Última lectura del PLC:
							<strong style={{'padding': '0 10px 0 5px'}}>
								{tablesData.last_update}</strong>
						</Fragment>
					)}
					{!isLoading && (
						<Button size="sm" className="mr-1" onClick={getTablesData}>
							<BasicIcon iconName="faRedo" />
						</Button>
					)}
					{toggle && (
						<Button size="sm" onClick={toggle}>
							{t('close')}
						</Button>
					)}
				</div>
			)}
			{tablesData.message_error && (
				<div className="card-body py-3 ">
					<div className="customApiCommands message message-error">{tablesData.message_error}</div>
				</div>
			)}

			{errorText && <Message text={errorText} type="error" />}

			{!isLoading && !tablesData.message_error && (
				<div className="card-body py-3 customApiCommands">
					<>
						<button type="button" className="submitButton mt-2 mr-2" onClick={handleSaveTableData}>
							{t('submit')}
						</button>
						<button type="button" className="submitButton mt-2" onClick={reload}>
							{t('cancel')}
						</button>
					</>
				</div>
			)}

			<div className="card-body py-3 customApiCommands">
				{!tablesData.message_error &&
					tablesData.num_tables &&
					Array.isArray(tablesData.data_tables) &&
					tablesData.data_tables.map((tableData, i) => (
						<table key={i} className="mb-3">
							<thead>
								<tr>
									<th colspan="3">
										<strong>{tableData.name}</strong>
									</th>
								</tr>
								{renderHeader(tableData.table_header)}
							</thead>
							{renderBody(tableData.table_body, tableData.name)}
						</table>
					))}
			</div>

			{!isLoading && !tablesData.message_error && (
				<div className="card-body py-3 customApiCommands">
					<table>
						<thead>
							<tr>
								<td colSpan="2">
									<strong>DESFASAMENT</strong>
								</td>
							</tr>
						</thead>
						<tbody>
							{Array.isArray(tablesData.data_gap) &&
								tablesData.data_gap.map((item) => (
									<tr>
										<td className="text-center">{item.col1}</td>
										<td>
											<FormGroup className="mb-0" tag="fieldset" row>
												<div style={{ width: '100px' }}>
													<input
														name={item.col2_var}
														defaultValue={item.col2}
														type="number"
														step="any"
														className={`form-control form-control-sm ml-4 input-text-wrapper validation`}
														onChange={onChangeItemData()}
													/>
												</div>
												<div className="ml-4" style={{ display: 'flex', alignItems: 'center' }}>
													<Label className="form-2-last-name ml-2 mb-0">minuts</Label>
												</div>
											</FormGroup>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			)}

			{!isLoading && !tablesData.message_error && (
				<div className="card-body py-3 customApiCommands">
					<>
						<button type="button" className="submitButton mt-2 mr-2" onClick={handleSaveTableData}>
							{t('submit')}
						</button>
						<button type="button" className="submitButton mt-2" onClick={reload}>
							{t('cancel')}
						</button>
					</>
				</div>
			)}
		</Card>
	);
};

CustomApiCommands.propTypes = {
	withoutSelect: PropTypes.bool,
	formContext: PropTypes.object,
	initialConfig: PropTypes.object,
	toggle: PropTypes.func
};

export default CustomApiCommands;
