import React, { useEffect, useRef, useState } from 'react';
import fetcher from '../services/fetcher';

const FilterSelect = (props) => {
	const {
		className,
		config,
		dispatch,
		size,
		globalParams = {},
		firstSelected,
		hearThis,
		style,
		initialValue,
		saveInitialValue
	} = props;
	const [filterOptions, setFilterOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState(initialValue);
	const mountedRef = useRef(true);

	const fetchData = () =>
		fetcher({ path: config.options.endpoint, urlParams: globalParams }).then((res) => {
			if (res && Array.isArray(res) && res.length && mountedRef.current) {
				setFilterOptions(res);
				if (firstSelected) {
					if (dispatch) dispatch({ [config.name_var]: res[0].pk });
					setSelectedOption(res[0].pk);
				}
			}
		});

	useEffect(() => {
		if (config) fetchData();
		if (saveInitialValue && dispatch) dispatch({ [config.name_var]: initialValue });
		
		return () => {
			mountedRef.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = ({ target: { value = '' } }) => {
		setSelectedOption(value);
		if (dispatch) dispatch({ [config.name_var]: value }, config.name_var, !hearThis);
	};

	const renderOptions = () =>
		Array.isArray(filterOptions)
			? filterOptions.map((fOption, index) => (
					<option key={`filterOption-${fOption.pk}-${index}`} value={fOption.pk}>
						{fOption[config.options.print]}
					</option>
			  ))
			: null;
	return (
		<div className={className} style={style}>
			<select
				className={`form-control form-control-${size} BasicSelect mt-2 mr-1`}
				id={config.name_var}
				name={config.title}
				onChange={handleChange}
				value={selectedOption || ''}>
				{!config.without_blank && <option value={''}>{config.title}</option>}
				{renderOptions()}
			</select>
		</div>
	);
};

FilterSelect.defaultProps = {
	className: '',
	config: {},
	size: 'sm'
};

export default FilterSelect;
