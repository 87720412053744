import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function SeoPreview(props) {
	console.log(props);
	const { title, link, date, description } = props;
	const [t] = useTranslation('app');

	return (
		<div className="SeoPreview">
			<span className="SeoPreview-notice">{`${t('preview')} / snipet`}</span>
			<div className="SeoPreview-content">
				<p className="SeoPreview-title">{title}</p>
				<div className="SeoPreview-info">
					<span className="SeoPreview-link">{link}</span>
					<span>{link ? ' - ' : ''}</span>
					<span className="SeoPreview-date">{date}</span>
				</div>
				<p className="SeoPreview-description">{description}</p>
			</div>
		</div>
	);
}

SeoPreview.propTypes = {
	title: PropTypes.string,
	link: PropTypes.string,
	date: PropTypes.string,
	description: PropTypes.string
};

SeoPreview.defaultProps = {
	title: (
		<Fragment>
			<span>Como seducir a las grandes marcas del </span>
			<span className="SeoPreview-bold">ecommerce</span>
		</Fragment>
	),
	link: 'Expansión.com',
	date: moment().format('DD MMM. YYYY'),
	description: (
		<Fragment>
			El <span className="SeoPreview-bold">ecommerce</span> ha cambiado las reglas del juego en lo que a las
			exportaciones se refiere. Permite llegar a cualquier sitio en poco tiempo, ...
		</Fragment>
	)
};
