import customOperators from '../services/customOperators';

const getProcessedInfo = (value, fieldInfo) => {
	if (fieldInfo.type === 'boolean') return typeof value === 'boolean' ? value : !!parseInt(value);
	return value;
};

export const validateConditions = (
	validations = [],
	data = {},
	options = { printValidation: false, printKey: 'Validation' },
	othersData = {},
	inputsConfig
) => {
	if (!Array.isArray(validations)) return true;
	const results = validations.map(({ condition, field, operator }) => {
		const fieldValue = field in data ? data[field] : othersData[field];
		const fieldInfo = Array.isArray(inputsConfig) && inputsConfig.find((fieldInfo) => fieldInfo.name === field);
		const processedCondition = fieldInfo ? getProcessedInfo(condition, fieldInfo) : condition;
		const processedValue = fieldInfo ? getProcessedInfo(fieldValue, fieldInfo) : fieldValue;
		if (options && options.printValidation)
			console.log(options.printKey, {
				condition,
				processedCondition,
				field,
				operator,
				fieldValue,
				processedValue,
				result: customOperators[operator]
					? customOperators[operator](processedValue, processedCondition)
					: false
			});
		if (customOperators[operator] && customOperators[operator](processedValue, processedCondition)) return true;
		return false;
	});
	return !results.some((item) => item === false);
};
