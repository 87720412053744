import React, { useState, useEffect, memo, useContext, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import WithListConfig from '../../hocs/WithListConfig';
import DynamicTable from '../DynamicTable';
import fetcher from '../../services/fetcher';
import ListContext from './ListContext';
import AppContext from '../../context';
import ButtonsBar from '../ButtonsBar';
import { searchFields } from '../../services/commonServices';
import parse from 'html-react-parser';
import { TO_UPPER_CASE } from '../../config';

const getInitialParams = (props) => {
	const { params = {} } = props;
	return { ...params, cpages: 1, page: 1 };
};

const List = (props) => {
	const {
		id,
		config,
		withoutPlusBtn,
		withoutButtonsBar,
		withoutFilters,
		withoutHeader,
		withoutSearcher,
		withBtnExcel,
		customName,
		addBtn,
		withoutMargin,
		className,
		getItems,
		formContext
	} = props;
	const {
		config: { globalParams }
	} = useContext(AppContext);
	const fetchTimeout = useRef(null);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const [urlParams, setUrlParams] = useState(getInitialParams(props));
	const [page, setPage] = useState(1);
	const [advancedSearchForm, setAdvancedSearchForm] = useState(null);
	const [QuantityRecord, setQuantityRecord] = useState(10);
	const [orderFields, setOrderFields] = useState('');
	const [keywords, setKeywords] = useState('');
	const [orderType, setOrderType] = useState('desc');
	const [fields, setFields] = useState('');
	const [checkFromInput, setCheckFromInputs] = useState([]);
	const [currentIds, setCurrentIds] = useState([]);
	const [selectedPage, setSelectedPage] = useState([]);
	const [headerData, setHeaderData] = useState(null);
	const [firstItemInfo, setFirstItemInfo] = useState({});

	useEffect(() => {
		const allIds = data.items?.map((item) => item.pk);
		allIds && setCurrentIds(allIds);
		return () => {
			clearTimeout(fetchTimeout.current);
		};
	}, [data]);

	const handleToSaveAllCheckInputs = (id) => {
		setCheckFromInputs((prev) => {
			if (!prev.length > 0) return [id];
			if (prev.includes(id)) return prev.filter((x) => x !== id);
			return [...prev, id];
		});
		// const newArr = id.filter(value => checkFromInput.includes(value))
	};

	const getListData = useCallback(() => {
		setLoading(true);
		if (getItems) {
			const items = getItems();
			let headerOptions = null;
			if (items && Array.isArray(items.items) && items.items.length) {
				headerOptions = searchFields([['header__', ''], 'list_title'], items.items[0]);
				setFirstItemInfo(items.items[0]);
			}
			if (headerOptions && Object.keys(headerOptions).length) setHeaderData(headerOptions);
			setLoading(false);
			return setData(items);
		}
		if (config.uri === '/inventory/' && 'asset' in globalParams) {
			delete globalParams['asset']
			console.log('Aplicando guarrada de borrar asset...');
		}
		const { read, advanced_search } = config.actions;
		const isAdvancedSeach = Object.keys(advancedSearchForm || {}).length;
		if (!read) return;
		if (urlParams['q'] && urlParams['q'].length <= 2) return;
		fetcher({
			path: isAdvancedSeach ? advanced_search.uri : read.uri,
			urlParams: { ...globalParams, ...urlParams },
			body: isAdvancedSeach ? advancedSearchForm : undefined,
			method: isAdvancedSeach ? advanced_search.method : read.method
		})
			.then((res) => {
				if (Array.isArray(res) || 'error' in res) setAdvancedSearchForm(null);
				let headerOptions = null;
				if (res && Array.isArray(res.items) && res.items.length) {
					headerOptions = searchFields([['header__', ''], 'list_title'], res.items[0]);
					setFirstItemInfo(res.items[0]);
				}
				if (headerOptions && Object.keys(headerOptions).length) setHeaderData(headerOptions);
				return setData(res);
			})
			.then(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [advancedSearchForm, globalParams, urlParams]);

	const fetchData = (fromWhere) => {
		clearTimeout(fetchTimeout.current);
		fetchTimeout.current = setTimeout(getListData, 500);
	};

	const createNewParams = (newPage = 1, newParams = {}) => {
		const params = {
			...urlParams,
			...newParams,
			page: newPage,
			nitems: QuantityRecord,
			order: orderFields,
			order_type: orderType
		};

		params['q'] = keywords && TO_UPPER_CASE ? keywords.toUpperCase() : keywords || '';
		params['fields'] = fields;

		setUrlParams(params);
	};

	useEffect(() => {
		if (page !== 1) setPage(1);
		else createNewParams();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keywords, globalParams, advancedSearchForm]);

	useEffect(() => {
		createNewParams(page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, QuantityRecord, orderFields, orderType]);

	useEffect(() => {
		fetchData('use effect');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [urlParams]);

	const handleFilterOptions = (paramsToAdd) => {
		createNewParams(1, paramsToAdd);
	};

	const renderButtonsBar = () => {
		if (withoutButtonsBar) return null;
		return (
			<ButtonsBar
				id={id}
				config={config}
				selectedItems={checkFromInput}
				fetchData={fetchData}
				formData={firstItemInfo}
				formContext={formContext}
			/>
		);
	};

	const checkAll = (isChecked) => {
		if (isChecked)
			setCheckFromInputs((prev) => {
				setSelectedPage((prev) => {
					if (prev && !prev.length > 0) return [page];
					if (prev.includes(page)) return prev.filter((x) => x !== page);
					return [...prev, page];
				});
				if (!prev.length > 0) return currentIds;
				return [...prev, ...currentIds];
			});
		else setCheckFromInputs([]);
	};

	const deleteAdvFilterItem = ({ name }) => {
		if (!advancedSearchForm) return;
		const currentAdvFilters = { ...advancedSearchForm };
		delete currentAdvFilters[name];
		delete currentAdvFilters[`${name}__init`];
		delete currentAdvFilters[`${name}__finish`];
		setAdvancedSearchForm(currentAdvFilters);
	};

	const renderHeader = () => {
		if (withoutHeader) return null;
		return headerData ? <div className={headerData.class}>{parse(headerData.text || '')}</div> : null;
	};

	// if (loading) return t('fetchingConfig');
	return (
		<div className={`ListComponent ${className}`}>
			{renderButtonsBar()}
			{renderHeader()}
			<ListContext.Provider
				value={{
					config,
					page,
					setPage,
					QuantityRecord,
					setQuantityRecord,
					setOrderFields,
					orderFields,
					orderType,
					setOrderType,
					handleToSaveAllCheckInputs,
					checkFromInput,
					checkAll,
					currentIds,
					selectedPage,
					urlParams,
					advancedSearchForm,
					setAdvancedSearchForm
				}}>
				<DynamicTable
					id={id}
					setKeywords={setKeywords}
					setFields={setFields}
					config={config}
					data={data}
					fetchData={fetchData}
					page={urlParams?.page}
					onChangeFilters={handleFilterOptions}
					onDeleteAdvFilter={deleteAdvFilterItem}
					withoutFilters={withoutFilters}
					withoutSearcher={withoutSearcher}
					withBtnExcel={withBtnExcel}
					withoutPlusBtn={withoutPlusBtn}
					customName={headerData?.list_title || customName}
					addBtn={addBtn}
					loading={loading}
					globalParams={globalParams}
					withoutMargin={withoutMargin}
					withoutHeader={withoutHeader}
				/>
			</ListContext.Provider>
		</div>
	);
};

List.propTypes = {
	id: PropTypes.string.isRequired,
	config: PropTypes.object.isRequired,
	params: PropTypes.object,
	withoutButtonsBar: PropTypes.bool,
	withoutFilters: PropTypes.bool,
	withoutMargin: PropTypes.bool,
	withoutPlusBtn: PropTypes.bool,
	withoutHeader: PropTypes.bool,
	withBtnExcel: PropTypes.bool,
	withoutSearcher: PropTypes.bool,
	customName: PropTypes.string,
	addBtn: PropTypes.bool,
	className: PropTypes.string,
	formContext: PropTypes.object
};

List.defaultProps = {
	params: {}
};

export default memo(WithListConfig(List));
