import currencyFormatter from 'currency-formatter';
import { getInputValue } from '../utils/DynamicForm.utils';
import authenticatedFetcher from './fetcher';

export const stringToStyleObj = (str = '') => {
	if (!str) return {};
	const allProperties = str.split(';').reduce((acc, newProperty) => {
		const propertyData = newProperty.split(':');
		if (propertyData.length < 2) return acc;
		const field = propertyData[0]?.trim() || 'defaultField';
		const value = propertyData[1]?.trim() || '';
		const newField = field.includes('-')
			? field.split('-').reduce((fieldAcc, fieldPart) => {
					if (!fieldAcc) return fieldPart;
					return `${fieldAcc}${fieldPart[0].toUpperCase() + fieldPart.slice(1)}`;
			  }, '')
			: field;
		return {
			...acc,
			[newField]: value
		};
	}, {});
	return allProperties;
};

export const searchFields = (fields = [], withinObject = {}) => {
	if (!fields?.length || !withinObject) return {};
	return Object.entries(withinObject).reduce((acc, field) => {
		const fieldData = fields.find((f) => RegExp(`^${typeof f === 'string' ? f : f[0]}`).test(field[0]));
		if (typeof fieldData === 'string') {
			return { ...acc, [field[0]]: field[1] };
		}
		if (fieldData && fieldData.length) {
			return {
				...acc,
				[field[0].replace(fieldData[0], fieldData[1])]: field[1]
			};
		}
		return acc;
	}, {});
};

export const getKeyStartWith = (keys = [], data = {}) => {
	if (!keys?.length || !data) return {};
	let result = {};

	Object.entries(data).forEach(([key, value]) => {
		keys.forEach((k) => {
			if (key.startsWith(k)) result = { ...result, [key]: value };
		});
	});

	return result;
};

export const searchParams = (
	urlOptions = { params: {}, 'params-fixed': {} },
	allData = {},
	mainField = 'params',
	optionalField = 'params-fixed'
) => {
	if (!urlOptions) return {};
	const options = urlOptions[mainField] ? urlOptions[mainField] : {};
	const otherOptions = urlOptions[optionalField] ? urlOptions[optionalField] : {};
	return Object.entries(options).reduce(
		(acc, [key, value]) => {
			return {
				...acc,
				[key]:
					typeof allData[value] === 'boolean'
						? allData[value]
							? 1
							: 0
						: value === 'pk'
						? allData[value] || allData['id']
						: allData[value]
			};
		},
		{ ...otherOptions }
	);
};

export const removeMatchingFields = (objToFilter = {}, filterFieldName = '') => {
	if (!filterFieldName || !objToFilter) return objToFilter;
	return Object.entries(objToFilter).reduce((acc, [key, value]) => {
		if (RegExp(`^${filterFieldName}`).test(key)) {
			return acc;
		}
		return {
			...acc,
			[key]: value
		};
	}, {});
};

export const formatFormFields = (data) => {
	return Object.entries(data).reduce((acc, [key, value]) => {
		// if (value || value === 0 || typeof value === 'boolean')
		// 	return {
		// 		...acc,
		// 		[key]: typeof value === 'boolean' ? Number(value) : value
		// 	};
		// return acc;
		return {
			...acc,
			[key]: typeof value === 'boolean' ? Number(value) : value
		};
	}, {});
};

export const validateFormFields = (formData = {}, fieldsInfo = []) => {
	return !fieldsInfo.find((fieldInfo) => {
		const value = formData[fieldInfo.name];
		if (fieldInfo.required) {
			if (fieldInfo.type === 'multiselect')
				return !Array.isArray(value) || (Array.isArray(value) && !value.length);
			if (fieldInfo.type !== 'boolean' && !value) return true;
		}
		return false;
	});
};

export const formatQuantity = (value = 0, options = { precision: 0, code: '', decimal: ',', thousand: '.' }) => {
	const { precision = 0, code = '', decimal = ',', thousand = '.' } = options;
	return currencyFormatter.format(value, {
		code,
		decimal,
		thousand,
		precision
	});
};

export const getInputStatus = (isRequired, value, hasErrors) => {
	if (isRequired) return !value || hasErrors ? 'input-failed' : 'input-success';
	return hasErrors ? 'input-failed' : '';
};

export const getItems = (apiRes) => {
	if (Array.isArray(apiRes)) return apiRes;
	if ('items' in apiRes && Array.isArray(apiRes[`items`])) return apiRes.items;
	return [];
};

export const getCustomFieldsWithConfig = (customFields = [], allFields = []) => {
	return customFields.map((item) => {
		if (item.type === 'custom') return item;
		const fieldInfo = allFields.find((field) => field.name === item.name);
		if (fieldInfo)
			return {
				...fieldInfo,
				...item
			};
		return item;
	});
};

export const getMethods = (actions = [], routes = []) => {
	if (!Array.isArray(actions) || !Array.isArray(routes)) return {};
	return actions.reduce((acc, { key, ...method }) => {
		const routeData = routes.find((item) => item.path === method.uri);
		if (routeData) {
			return { ...acc, [key]: { ...method, configUri: `/${routeData.id}/`.replace(/\/\//g, '/') } };
		}
		return { ...acc, [key]: method };
	}, {});
};

export const fileUploader = async (inputEvent, { fileType, getValue = true, params = {}, ...otherOptions } = {}) => {
	const { target = {} } = inputEvent;
	const value = getValue ? target && getInputValue(target) : target.value;
	const options = params || {};
	if (fileType === 'img' && 'size' in otherOptions) options[`${target.name || ''}_size`] = otherOptions.size;
	const data = new FormData();
	data.append('file', value);

	const fileUploaded = await authenticatedFetcher({
		path: `/file-upload/`,
		method: 'POST',
		urlParams: options,
		body: data,
		file: true
	});
	if (!fileUploaded.error) {
		const newPath = `${fileUploaded.mediaBaseUrl}${fileUploaded.path}`;
		return { [target.name]: newPath, [`${target.name}_url`]: newPath, ...options };
	} else {
		return false;
	}
};

export const objectToUrlParams = (obj) => {
	const newParams = Object.entries(obj).reduce((acc, [key, value]) => {
		if (!(typeof value === 'boolean') && !value) return acc;
		if (Array.isArray(value)) {
			if (value.length) return { ...acc, [key]: value.join(',') };
			return acc;
		}
		if (typeof value === 'object') {
			if (Object.keys(value).length) return { ...acc, [key]: JSON.stringify(value) };
			return acc;
		}
		return { ...acc, [key]: value };
	}, {});
	const ret = [];
	for (let d in newParams) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(newParams[d]));
	return ret.join('&');
};

export const searchFileType = (fileName) => {
	if (!(typeof fileName === 'string')) return null;
	const fileExtention = fileName.split('.').pop().toLowerCase();
	switch (fileExtention) {
		case 'jpg':
		case 'jpeg':
		case 'gif':
		case 'png':
		case 'svg':
			return 'img';
		case 'pdf':
		case 'doc':
		case 'docx':
		case 'xls':
		case 'xlsx':
		case 'txt':
		case 'zip':
		case 'rar':
			return 'file';
		default:
			return null;
	}
};
