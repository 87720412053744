import React, { useEffect } from 'react';
import { useMapEvents } from 'react-leaflet';

export default function MapEventsHandler({
	onMove = () => {},
	onZoom = () => {},
	onClick = () => {},
	onInit = () => {}
}) {
	const mapRefer = useMapEvents({
		click: onClick,
		move: onMove,
		zoom: onZoom
	});

	useEffect(() => {
		if (typeof onInit === 'function') onInit(mapRefer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <></>;
}
