import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Button } from 'reactstrap';
import authenticatedFetcher from '../../services/fetcher';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import { DynamicForm } from '../DynamicForm';
import BasicTable from '../Ticketing/BasicTable';
import { MEDIA_BASE_URL } from '../../config';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../LoadingSpinner';

export default function CustomModalImportDiscount(props) {
	const { toggle, open, fetchData } = props;
	const [tableContent, setTableContent] = useState(null);
	const [formData, setFormData] = useState({});
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [t] = useTranslation('app');

	const handleLoadFiles = async () => {
		const res = await authenticatedFetcher({
			path: '/exams/discounts/import/previous/',
			method: 'POST',
			body: formData
		});
		if (!('error' in res))
			setTableContent({
				...res,
				header: res.header.map((item) => ({ cellFieldName: item.field, title: item.label }))
			});
		else {
			const msg = Object.values(res.error_data || {})
				.map((item) => item[0])
				.join('<br/>');
			toastError(msg, { autoClose: false }, true);
			return;
		}
	};

	const handleChange = (data) => setFormData((prev) => ({ ...prev, ...data }));

	const handleSubmit = async () => {
		setLoadingSubmit(true);
		const res = await authenticatedFetcher({
			path: '/exams/discounts/import/',
			method: 'POST',
			body: formData
		});
		setLoadingSubmit(false);
		if (!('error' in res)) {
			toastSuccess(res.response);
			if ('reload' in res) fetchData();
			toggle();
		} else {
			toastError('Error');
		}
	};

	const formFields = [
		{
			type: 'custom',
			title: 'Plantilla',
			content: (
				<a
					href={`${MEDIA_BASE_URL}/media/import-templates-examples/plantilla-importacion-descuentos.xlsx`}
					target="_blank"
					rel="noopener noreferrer">
					Descargar plantilla de ejemplo
				</a>
			),
			labelColumn: {
				md: '4'
			},
			inputColumn: {
				md: '8'
			}
		},
		{
			type: 'select',
			label: t('modals.select_the_exam_to_import_the_discounts'),
			name: 'exam',
			editable: true,
			options: {
				endpoint: '/exams/not-closed-exams/?nitems=1000',
				key: 'pk',
				print: 'title'
			},
			labelColumn: {
				md: '4'
			},
			inputColumn: {
				md: '8'
			}
		},
		{
			type: 'file',
			value: '',
			label: t('modals.enter_the_excel_to_import'),
			name: 'file',
			editable: true,
			useLabel: true,
			labelColumn: {
				md: '4'
			},
			inputColumn: {
				md: '8'
			}
		}
	];

	return (
		<Modal toggle={toggle} isOpen={open} className="persona">
			<ModalHeader toggle={toggle} charCode="x">
				{t('modals.import_discounts')}
			</ModalHeader>
			<ModalBody>
				<Card>
					<CardBody>
						<div className="CustomModalImportDiscount">
							<div className="row">
								<DynamicForm
									inputs={formFields}
									onChange={handleChange}
									className="w-100 col-12"
									oneColum
								/>
								<div className="col-12">
									<Button
										className="mt-3 mb-4"
										style={{ border: '1px solid', padding: '0.5rem 2rem' }}
										onClick={handleLoadFiles}>
										{t('modals.load_discounts')}
									</Button>
								</div>
								{tableContent && (
									<>
										<div className="col-12">
											<div className="ExportTest-table mt-5 ml-0 mr-0">
												<BasicTable
													className="pl-0 pr-0"
													header={tableContent.header}
													items={tableContent.items}
													useActions={false}
												/>
											</div>
										</div>
										<div className="col-12">
											<Button
												className="mt-3 mb-4"
												style={{ border: '1px solid', padding: '0.5rem 2rem' }}
												onClick={handleSubmit}>
												Importar <LoadingSpinner show={loadingSubmit} />
											</Button>
										</div>
									</>
								)}
							</div>
						</div>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
}
