import React, { useState } from 'react';
import { DynamicForm } from '../DynamicForm';
import BasicTable from '../Ticketing/BasicTable';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Button } from 'reactstrap';
import authenticatedFetcher from '../../services/fetcher';
import { MEDIA_BASE_URL } from '../../config';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../LoadingSpinner';

export default function CustomModalExportTest(props) {
	const { toggle, open } = props;
	const [formData, setFormData] = useState({});
	const [tableConfig, setTableConfig] = useState(null);
	const [fields2, setFields2] = useState([]);
	const [showSaveBtn, setShowSaveBtn] = useState(false);
	const [loading, setLoading] = useState(false);
	const [resetCount, setResetCount] = useState(0);
	const [t] = useTranslation('app');

	const updateData = (newData) => setFormData((prev) => ({ ...prev, ...newData }));

	const resetModal = () => {
		setFormData({});
		setTableConfig(null);
		setFields2([]);
		setShowSaveBtn(false);
		setResetCount((prev) => prev + 1);
	};

	const handleSendFiles = async () => {
		const res = await authenticatedFetcher({
			path: '/exams/import-results/previous/',
			method: 'POST',
			body: formData
		}).then((res) => {
			if (!res || 'error' in res) return res;
			const header = Array.isArray(res.header)
				? res.header.map((item) => ({ title: item.label, cellFieldName: item.field }))
				: [];
			return {
				...res,
				header
			};
		});
		if (!res || 'error' in res) {
			const msg = Object.values(res.error_data || {})
				.map((item) => item[0])
				.join('<br/>');
			toastError(msg, { autoClose: false }, true);
			return;
		} else {
			const { header, items, ...otherData } = res;
			const newFields2 = [
				{
					type: 'text',
					label: t('total_lines'),
					name: 'total_lines',
					style: { color: '#A9A9A9' },
					editable: false,
					labelColumn: {
						md: '6'
					},
					inputColumn: {
						md: '6'
					}
				},
				{
					type: 'text',
					label: t('modals.total_appointments'),
					name: 'total_aptos',
					style: { color: '#A9A9A9' },
					editable: false,
					labelColumn: {
						md: '6'
					},
					inputColumn: {
						md: '6'
					}
				},
				{
					type: 'text',
					label: t('modals.total_unsuitable'),
					name: 'total_no_aptos',
					style: { color: '#A9A9A9' },
					editable: false,
					labelColumn: {
						md: '6'
					},
					inputColumn: {
						md: '6'
					}
				},
				{
					type: 'text',
					label: t('modals.total_not_shown'),
					name: 'total_no_presentados',
					style: { color: '#A9A9A9' },
					editable: false,
					labelColumn: {
						md: '6'
					},
					inputColumn: {
						md: '6'
					}
				},
				{
					type: 'text',
					label: t('modals.total_erroneous'),
					name: 'total_erroneos',
					style: { color: '#A9A9A9' },
					editable: false,
					labelColumn: {
						md: '6'
					},
					inputColumn: {
						md: '6'
					}
				}
			].map((item) => ({ ...item, value: otherData[item.name] }));
			setFields2(newFields2);
			updateData(otherData);
			if (Array.isArray(items) && !items.some((item) => item.error)) setShowSaveBtn(true);
			else setShowSaveBtn(false);
		}
		setTableConfig(res);
	};

	const handleSubmit = async () => {
		setLoading(true);
		const res = await authenticatedFetcher({
			path: '/exams/import-results/',
			method: 'POST',
			body: formData
		});
		setLoading(false);
		if (res?.action) {
			resetModal();
			toastSuccess(res.action);
		} else {
			toastError('Error');
		}
	};

	const fields = [
		{
			type: 'checkbox',
			value: false,
			label: 'Examen OnLine',
			name: 'online',
			editable: true
		},
		{
			type: 'custom',
			title: 'Plantilla',
			content: (
				<a
					href={`${MEDIA_BASE_URL}/media/import-templates-examples/832-plantilla-importacion-resultados-examen.xlsx`}
					target="_blank"
					rel="noopener noreferrer">
					{t('modals.donwload_sample_template')}
				</a>
			)
		},
		{
			type: 'text',
			label: t('modals.minimum_qualifying_score'),
			name: 'limit_score',
			editable: true
		},
		{
			type: 'file',
			value: '',
			label: t('modals.notes_file') + ':',
			name: 'file',
			editable: true,
			useLabel: true,
			labelColumn: {
				md: '3'
			},
			inputColumn: {
				md: '9'
			}
		},
		{
			type: 'custom',
			content: ({ formData }) =>
				formData['file'] ? (
					<Button
						className="mb-4"
						onClick={handleSendFiles}
						style={{ border: '1px solid', padding: '0.5rem 2rem' }}>
						{t('modals.preload_excel')}
					</Button>
				) : null
		}
	];

	return (
		<Modal toggle={toggle} isOpen={open} className="persona">
			<ModalHeader toggle={toggle} charCode="x">
				{t('modals.exam_import')}
			</ModalHeader>
			<ModalBody>
				<Card>
					<CardBody>
						<div className="ExportTest">
							<div className="row">
								<div className="col-12 col-md-6">
									{fields?.length > 0 ? (
										<DynamicForm
											inputs={fields}
											onChange={updateData}
											oneColum
											formData={formData}
											key={resetCount}
										/>
									) : null}
								</div>
								<div className="col-12 col-md-6 ExportTest-disable">
									{fields2?.length > 0 && tableConfig ? (
										<DynamicForm inputs={fields2} oneColum={false} formData={formData} />
									) : null}
								</div>
								{tableConfig && (
									<div className="col-12">
										<div className="ExportTest-table mt-5">
											<BasicTable
												className="pl-0 pr-0"
												header={tableConfig.header || []}
												items={tableConfig.items || []}
												useActions={false}
												tableProps={{ syle: { textTransform: 'none' } }}
											/>
										</div>
									</div>
								)}
								{showSaveBtn && (
									<div className="col-12">
										<div className="d-flex justify-content-end mt-3">
											<Button
												className="mr-2"
												type="button"
												style={{ border: '1px solid', padding: '0.5rem 2rem' }}
												onClick={handleSubmit}>
												Importar <LoadingSpinner show={loading} />
											</Button>
											<Button
												type="button"
												style={{ border: '1px solid', padding: '0.5rem 2rem' }}
												onClick={toggle}>
												{t('cancel')}
											</Button>
										</div>
									</div>
								)}
							</div>
						</div>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
}

CustomModalExportTest.defaultProps = {
	toggle: () => {},
	updateData: () => {}
};
