import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody } from 'reactstrap';
import { loginMethod } from '../../services/fetcher';
import { toastError } from '../../utils/ToastConfig';

export default function Login(props) {
	const [loginData, setLoginData] = useState({});
	const [loginFailed, setLoginStatus] = useState(false);
	const [t] = useTranslation('app');
	const logo = process.env.REACT_APP_IMAGE_BAR;

	const handleChange = ({ target: { value, name } }) => {
		setLoginData((prev) => {
			return {
				...prev,
				[name]: value
			};
		});
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		const userInfo = await loginMethod(loginData);
		if (userInfo && typeof userInfo === 'object' && userInfo.login) setLoginStatus(false);
		else {
			if (!userInfo) setLoginStatus(true);
			else toastError('Acceso no autorizado!');
		}
	};

	return (
		<form onSubmit={onSubmit}>
			<div className="Login">
				<div className="container">
					<div className="Login-wrapper">
						<Card className="Login-card">
							<CardBody className="Login-content">
								<img className="Login-logo" src={logo} alt="logo" />
								<input
									className="Login-input"
									type="text"
									placeholder={t('login.user')}
									name="username"
									onChange={handleChange}
								/>
								<input
									className="Login-input"
									type="password"
									placeholder={t('login.password')}
									name="password"
									onChange={handleChange}
								/>
								{loginFailed ? <p>{t('login.loginError')}</p> : null}
								<Button className="Login-btn" type="submit">
									{t('login.login')}
								</Button>
							</CardBody>
							{/* <CardFooter className="Login-footer">
							<a className="Login-link" href="#/">
							Forgot Password?
							</a>
						</CardFooter> */}
						</Card>
					</div>
				</div>
			</div>
		</form>
	);
}
