import React from 'react';
// import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import WithMapConfig from '../../hocs/WithMapConfig';
import Map from './Map';

const MapModal = (props) => {
	const { toggle, title } = props;
	return (
		<Modal isOpen toggle={toggle} centered>
			<ModalHeader toggle={toggle} charCode="x">
				{title}
			</ModalHeader>
			<ModalBody>
				<Map {...props} />
			</ModalBody>
		</Modal>
	);
};

MapModal.propTypes = {};

export default WithMapConfig(MapModal, true);
