import React, { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Col, FormGroup, Label } from 'reactstrap';
import { Types } from '../../../utils/DynamicForm.config';
import { format } from 'date-fns';
import { getInputStatus } from '../../../services/commonServices';
import PrintInputError from '../../InputUtils/PrintInputError';
import DateMask from '../../MaskedInput/DateMask';
import InputDescription from '../../InputUtils/InputDescription';
import clsx from "clsx";

const getUTCDate = (dateString = Date.now()) => {
	const changeDate = new Date(dateString);
	return new Date(
		changeDate.getFullYear(),
		changeDate.getMonth(),
		changeDate.getDate(),
		changeDate.getHours(),
		changeDate.getMinutes(),
		changeDate.getSeconds()
	);
};

const fromApiToPicker = (value) => {
	if (!value) return new Date();
	const changeDate = new Date();
	const [hour, mins, seconds] = value.split(':');
	return new Date(changeDate.getFullYear(), changeDate.getMonth(), changeDate.getDate(), hour, mins, seconds);
};

const fromPickerToApi = (value) => {
	return format(getUTCDate(value), 'HH:mm:ss');
};

const TypeFormTimepicker = ({
	id,
	name,
	label,
	value,
	onChange,
	editable,
	isEditing = true,
	required,
	errors,
	type,
	labelColumn = {
		xs: 6,
		md: 2
	},
	inputColumn = {
		xs: 6,
		md: 10
	},
	alwaysEditableFields,
	relatedData,
	inputWrapperClassName,
	onlyInput
}) => {
	const [startDate, setStartDate] = useState('');
	const datePickerRef = useRef();

	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;

	useEffect(() => {
		if (required || value) {
			const initDate = fromApiToPicker(value, true);
			onChange({ target: { value: value ? value : fromPickerToApi(initDate), name } });
			setStartDate(initDate);
		}
		if (document) {
			const dateMaskInput = document.getElementById(name);
			if (dateMaskInput)
				dateMaskInput.addEventListener('keydown', (e) => {
					if (e.isComposing || e.key === 'Tab' || e.key === 'Esc' || e.key === 'Escape')
						datePickerRef.current.setOpen(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnChange = (date) => {
		if (!isEditable || !isEditing) return;
		setStartDate(date);
		onChange({
			target: { name, value: date ? fromPickerToApi(date) : null }
		});
	};

	const renderInput = () => (
		<div className={clsx('Datetimepicker-wrapper', inputWrapperClassName)}>
			<ReactDatePicker
				id={id || name}
				ref={datePickerRef}
				name={name}
				showTimeSelect
				showTimeSelectOnly
				timeIntervals={15}
				dateFormat="HH:mm"
				locale="es-ES"
				value={startDate}
				selected={startDate}
				readOnly={!isEditable || !isEditing}
				autocomplete="off"
				onChange={handleOnChange}
				className={getInputStatus(required, value, errors)}
				customInput={<DateMask type={type} />}
			/>
		</div>
	);

	if (onlyInput) return renderInput();

	return (
		<FormGroup tag="fieldset" row>
			<Col {...labelColumn}>
				<Label for={name}>
					{required ? '*' : ''} {label}
				</Label>
			</Col>
			<Col {...inputColumn}>
				{renderInput()}
				<PrintInputError errors={errors} />
				<InputDescription description={relatedData ? relatedData.description : ''} />
			</Col>
		</FormGroup>
	);
};

TypeFormTimepicker.propTypes = Types.inputType;

export default TypeFormTimepicker;
