import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SidebarUserbox from '../SidebarUserbox';
import AppContext from '../../context';
import './index.scss';
import cx from 'classnames';
import { setMapConfig } from '../../services/mapServices';
// import { useTranslation } from 'react-i18next';
// import { deleteUserInfo } from '../../services/fetcher';

const SidebarMenu = ({ sidebarUserbox, toggleMobileMenu }) => {
	const [dropdownOpen, setDropdownOpen] = useState();
	// const [t] = useTranslation('app');
	const toggleDropDown = (id) => {
		setDropdownOpen((prev) => (prev === id ? null : id));
	};
	const history = useHistory();

	const { config } = useContext(AppContext);

	const isSubMenuActive =
		(url = '') =>
		() => {
			const currentPath = window.location.pathname;
			if (currentPath === url) return true;
			const currentPathArray = currentPath.split('/');
			currentPathArray.pop();
			return `${currentPathArray.join('/')}/` === url;
		};
	const goToMap = (route) => (e) => {
		e.preventDefault();
		if (!route) return;
		setMapConfig({
			endPointConfig: route['map_config'],
			originData: route['origin_data']
		});
		if (toggleMobileMenu) toggleMobileMenu();
		history.push(route.url);
	};

	const renderOption = (route) => {
		if (route.map_config)
			return (
				<NavLink to="#Redirect" onClick={goToMap(route)}>
					<li className="submenu-list" key={route.id}>
						{route.title}
					</li>
				</NavLink>
			);
		switch (route.id) {
			case 'advanced-search-generic':
				return (
					<NavLink to="/searcher" onClick={toggleMobileMenu}>
						<li className="submenu-list" key={route.id}>
							{route.title}
						</li>
					</NavLink>
				);
			case 'planifications-reports':
				return (
					<NavLink to="/informes-planificacio" onClick={toggleMobileMenu}>
						<li className="submenu-list" key={route.id}>
							{route.title}
						</li>
					</NavLink>
				);
			case 'external-link':
				return (
					<a href={route.url} target="_blank" rel="noopener noreferrer" onClick={toggleMobileMenu}>
						<li className="submenu-list" key={route.id}>
							{route.title}
						</li>
					</a>
				);
			default:
				return (
					<NavLink to={route.url} onClick={toggleMobileMenu}>
						<li className="submenu-list" key={route.id}>
							{route.title}
						</li>
					</NavLink>
				);
		}
	};

	return (
		<>
			<PerfectScrollbar>
				{sidebarUserbox && <SidebarUserbox />}
				<div className="sidebar-navigation">
					<div className="sidebar-header">
						<span>Menú</span>
					</div>
					<ul>
						{config.dashboard.map((route, index) => {
							return (
								<div key={index} className="wrapper-sidebar-menu">
									<div
										onClick={() => toggleDropDown(route.id)}
										className={cx(
											'wrapper-sidebar-submenu',
											dropdownOpen === route.id && 'selected'
										)}>
										{route.submenu ? (
											<li className="submenu-list" key={route.id}>
												{route.title}
											</li>
										) : (
											renderOption(route)
										)}
										{route.submenu &&
											(dropdownOpen === route.id ? (
												<FontAwesomeIcon icon={['fas', 'angle-up']} />
											) : (
												<FontAwesomeIcon icon={['fas', 'angle-down']} />
											))}
									</div>
									{dropdownOpen === route.id &&
										route.submenu &&
										route.submenu.map((route) => {
											const { title, url } = route;
											return (
												<div key={title} className="content">
													{route.map_config ? (
														<NavLink
															onClick={goToMap(route)}
															to="#Redirect"
															className="nav-link-simple"
															isActive={isSubMenuActive(url)}
															key={route.id}>
															{title}
														</NavLink>
													) : (
														<NavLink
															to={url}
															className="nav-link-simple"
															activeClassName="active"
															isActive={isSubMenuActive(url)}
															onClick={toggleMobileMenu}>
															{title}
														</NavLink>
													)}
												</div>
											);
										})}
								</div>
							);
						})}
						{/* <div className="wrapper-sidebar-menu">
							<div className="wrapper-sidebar-submenu" onClick={deleteUserInfo}>
								<li>{t('login.closeSession')}</li>
							</div>
						</div> */}
					</ul>
				</div>
			</PerfectScrollbar>
		</>
	);
};

export default SidebarMenu;
