import React, { memo, useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../context';
import getConfigByType from '../services/get-config-by-type';
import { UPDATE_FORM_RELATION } from '../reducers/config-reducer/types';
import { getCustomFormConfig } from '../utils';
import { useTranslation } from 'react-i18next';

const FORM_TYPE = 'form';
const CREATE_TYPE = 'create';

export default function WithFormConfig(FormComponent, creating, customId) {
	const Wrapper = (props) => {
		const [loading, setLoading] = useState(true);
		const { id = customId, customConfig, filterMode, isCopy } = props;
		const data = useRef(props.data);
		const {
			config: { globalParams, gParamsIsReady, ...config },
			dispatch
		} = useContext(AppContext);
		const relationConfig = config.forms[id];
		const formConfig = config.forms[id];
		const [t] = useTranslation('app');

		useEffect(() => {
			if (gParamsIsReady) getConfigData();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [gParamsIsReady]);

		const getConfigData = () => {
			const isCreating = !filterMode && creating;
			if (!relationConfig || relationConfig.withCustomConfig !== !!customConfig) {
				const otherParams = globalParams && typeof globalParams === 'object' ? globalParams : {};
				getConfigByType({ id, urlParams: { type: isCreating ? CREATE_TYPE : FORM_TYPE, ...otherParams } }).then(
					(configResponse) => {
						if ('error' in configResponse) return;
						const newConfig = getCustomFormConfig(customConfig, configResponse);
						if (isCopy && data.current)
							data.current = newConfig.fields.reduce((acc, item) => {
								if (item.editable) return { ...acc, [item.name]: data.current[item.name] };
								return acc;
							}, {});
						dispatch({
							type: UPDATE_FORM_RELATION,
							payload: { [id]: { ...newConfig, withCustomConfig: !!customConfig } }
						});
						setLoading(false);
					}
				);
			} else {
				setLoading(false);
			}
		};

		if (loading) return t('fetchingConfig');

		return <FormComponent config={formConfig} {...props} data={data.current} />;
	};
	Wrapper.propTypes = {
		id: PropTypes.string
	};

	return memo(Wrapper);
}
