import { useEffect } from 'react';

const ScrollToTop = ({ children }) => {
	const location = window !== undefined ? window.location : {};

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}, [location.pathname]);

	return children || null;
};

export default ScrollToTop;
