import React, { useState, useEffect } from 'react';
const COUNT_PARTS = 10;

export default function WithMarkerList(WrappedComponent) {
	const Component = ({ markers, ...props }) => {
		const [loadingMarkers, setloadingMarkers] = useState(true);
		const [currentMarkers, setCurrentMarkers] = useState([]);

		useEffect(() => {
			setCurrentMarkers([])
			if (markers && markers?.length) {
				const pageSize = Math.ceil(markers.length / COUNT_PARTS);
				for (let pageNumber = 1; pageNumber <= COUNT_PARTS; pageNumber++) {
					setTimeout(() => {
						const skip = (pageNumber - 1) * pageSize;
						const data = markers.slice(skip, skip + pageSize);

						buildCurrentMarkersWithCluster(data).then((result) => {
							setCurrentMarkers((prev) => [...prev, ...result]);
							if (pageNumber === COUNT_PARTS) setloadingMarkers(false);
						});
					}, 1000);
				}
			}
		}, [markers]);

		async function buildCurrentMarkersWithCluster(_markers = []) {
			return new Promise((resolve, reject) => {
				const _result = _markers?.reduce(
					(acc, marker) => {
						// console.log('\n acc...', acc);
						const { points, clusters } = acc;

						const [lon, lat] = marker?.coordinates || [];
						const haveClusterField = clusters[`${lon},${lat}`];

						const isCluster =
							haveClusterField ||
							_markers.some(
								({ coordinates = [], pk = null }) =>
									coordinates[0] === lon && coordinates[1] === lat && pk !== marker?.pk
							);


						if (isCluster) {
							const _cluster = haveClusterField
								? {
									...haveClusterField,
									points: [...haveClusterField.points, marker],
									totalPoints: haveClusterField.totalPoints + 1
								}
								: {
									coordinates: [lon, lat],
									points: [marker],
									totalPoints: 1,
									point_color: 'blue',
									isCluster: true,
									pk: `${lon},${lat}`
								};
							return {
								...acc,
								clusters: {
									...clusters,
									[`${lon},${lat}`]: _cluster
								}
							};
						} else {
							return {
								...acc,
								points: [...points, marker]
							};
						}
					},
					{
						points: [],
						clusters: {}
					}
				);

				const _points = _result.points;
				const _clusters = Object.values(_result.clusters);
				resolve([..._points, ..._clusters]);
			});
		}

		// if (loadingMarkers) return <OriginalComponent {...props} markers={[]} />;
		return <WrappedComponent {...props} markers={currentMarkers} loadingMarkers={loadingMarkers} />;
	};

	return Component;
}
