import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import cx from 'classnames';

const Dropdown = (props) => {
	const { name, value, onChange, disabled, label, required, options, isEdit, classNameSelect } = props;
	const [localvalue, setLocalvalue] = useState(value);

	useEffect(() => {
		setLocalvalue(value)
	}, [value])


	return (
		<FormGroup tag="fieldset" row>
			<Row className="w-100">
				<Col xs="6" xl="5" className="text-sm-left text-md-right p-0">
					<Label for="form-2-last-name" className="w-100 font-weight-bold">
						{required ? '*' : ''} {label}
					</Label>
				</Col>
				<Col xs="6" xl="7">
					<span className="input-select-async-wrapper w-100 EditElement-dropdown">
						<select
							className={cx('form-control', !isEdit && 'remove-arrow', classNameSelect)}
							name={name}
							onChange={onChange}
							style={{ padding: 0 }}
							defaultValue={localvalue}
							value={localvalue}
							disabled={disabled}>
							{options &&
								options.map((option, index) => {
									return (
										<option key={index} value={option.value}>
											{option.label}
										</option>
									);
								})}
						</select>
					</span>
				</Col>
			</Row>
		</FormGroup>
	);
};

Dropdown.defaultProps = {
	required: false,
	disabled: false,
	onChange: () => { },
	options: []
};

Dropdown.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.any,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.number
		})
	)
};

export default Dropdown;
