import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const BasicToggleActivado = (props) => {
    const { label = "", value, variant, onChange, name, withDefaultLabel } = props;

    return (
        <label class="BasicToggle">
            <input type="checkbox" checked={!!value} onChange={onChange} name={name} />
            <span className={clsx('slider', variant)}></span>
            <span className="BasicToggle-text">{withDefaultLabel ? `${label} ${(!!value ? 'Activat' : 'Desactivat')}`.trim() : label}</span>
        </label>
    );
};

BasicToggleActivado.propTypes = {
    label: PropTypes.string,
    value: PropTypes.bool,
    variant: PropTypes.string,
    onChange: PropTypes.func,
    withDefaultLabel: PropTypes.bool
};

export default BasicToggleActivado;
