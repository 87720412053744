import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import DynamicFormInput from './DynamicFormInput';
import { Types } from '../../utils/DynamicForm.config';
import { defaultFormDataFromInputs, getInputValue } from '../../utils/DynamicForm.utils';
import fetcher from '../../services/fetcher';
import { Col, Row } from 'reactstrap';
import { fileUploader, getKeyStartWith, validateFormFields } from '../../services/commonServices';
import SeoPreview from '../SeoPreview/SeoPreview';
import AppContext from '../../context';
import { useTranslation } from 'react-i18next';
import { validateConditions } from '../../utils';
import MapModal from '../MapComponents/MapModal';
import { writeSessionStorage } from '../../services/sessionStorageManagement';

const DynamicForm = ({
	name = 'defaultName',
	inputs,
	onChange,
	isEditing,
	labelWithBold,
	oneColum = true,
	formErrors,
	formData: parentFormData = undefined,
	isContractPage,
	isSLAField,
	isEnrollment,
	isIncidence,
	isUsuari,
	isOrdreTreball,
	isOperations,
	isCreating,
	filterMode,
	alwaysEditableFields,
	className = '',
	conditionalFields,
	inputsAlign = { labelColumn: {}, inputColumn: {} },
	allFields,
	originalData,
	isSearcher,
	onlyMainParams
}) => {
	const confirmExitTimeout = useRef(null);
	const [formData, setFormData] = useState(defaultFormDataFromInputs(inputs, isCreating));
	const [fieldConditionals, setFieldConditionals] = useState({});
	const [mapConfig, setMapConfig] = useState();
	const [isValidated, setIsValidated] = useState(false);
	const {
		config: { globalParams, mainParams }
	} = useContext(AppContext);
	const [traductionFunc] = useTranslation('app');

	useEffect(() => {
		if (onChange) {
			const formIsValid = validateFormFields(formData, inputs);
			if (originalData && typeof window !== 'undefined') {
				const thereAreDiff = Object.entries(formData).find(([key, value]) => originalData[key] !== value);
				if (thereAreDiff) {
					clearTimeout(confirmExitTimeout.current);
					confirmExitTimeout.current = setTimeout(
						() => writeSessionStorage('confirmExit', window.location.pathname),
						2000
					);
				}
			}
			onChange(formData, filterMode ? null : { formState: formIsValid, formName: name });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);

	useEffect(() => {
		if (!isValidated) setIsValidated(true);
		if (conditionalFields)
			setFieldConditionals((prev) => {
				let thereWereChange = false;
				const newValue = Object.entries(conditionalFields).reduce((acc, [name, fieldCondition]) => {
					const isValid = validateConditions(
						fieldCondition,
						parentFormData ? parentFormData : formData,
						{},
						originalData,
						inputs
					);
					if (prev[name] !== isValid) thereWereChange = true;
					if (prev[name] && !isValid) {
						setFormData((prev) => ({ ...prev, [name]: undefined }), null);
					}
					return {
						...acc,
						[name]: isValid
					};
				}, {});
				if (thereWereChange) return newValue;
				return prev;
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parentFormData ? parentFormData : formData]);

	const handleChange = async (inputEvent, options = {}) => {
		// inputEvent.persist();
		const { target } = inputEvent;

		let newState;
		if ('isObject' in options) {
			newState = target;
		}
		if (target.files) {
			const fileUploaded = await fileUploader(inputEvent, options);
			if (!fileUploaded) return;
			newState = fileUploaded;
		}
		if (!newState) {
			const value = target && getInputValue(target);
			newState = { [target?.name]: value };
		}
		setFormData((prevVal) => ({ ...prevVal, ...newState }));
		return true;
	};

	const refetchFormData = async () => {
		try {
			const [, category, id] = window.location.pathname.split('/');
			const formStructure = await fetcher({ path: `/${category}/${id}` });
			return formStructure;
		} catch (error) {
			return null;
		}
	};

	const displayMdSize = (input) => {
		const incidence = input?.name?.includes('sla_incidence');
		if (isContractPage && isSLAField && !incidence) {
			return 'col-12 col-md-5';
		} else if (isContractPage && isSLAField && incidence) {
			return 'col-12 col-md-7';
		} else if (isContractPage && !isSLAField) {
			return '9';
		} else if (input.name === 'COORD') {
			return '12';
		} else {
			return '6';
		}
	};

	const getInputType = (inputType, editable) => {
		if (isCreating || filterMode) return editable ? inputType : 'unknow';
		return inputType;
	};

	const getInputData = (inputName) => Array.isArray(allFields) && allFields.find((item) => item?.name === inputName);

	const toggleMapModal = (originData, endPointConfig) => () =>
		endPointConfig ? setMapConfig({ endPointConfig, originData }) : setMapConfig();

	const renderDynamicInputs = () => {
		const newInputs = inputs.map((input, i) => {
			const inputType = getInputType(input.type, input.editable || input.editable_in_create);
			const withSlugField = !!input.slugfield;
			const isValid = input.name in fieldConditionals ? fieldConditionals[input.name] : true;
			const divClassName = input.divClassName ? input.divClassName : ''
			if (!isValid) return null;
			const newInput = (
				<DynamicFormInput
					alwaysEditableFields={alwaysEditableFields}
					isOperations={isOperations}
					isOrdreTreball={isOrdreTreball}
					isEnrollment={isEnrollment}
					isIncidence={isIncidence}
					isSLAField={isSLAField}
					isUsuari={isUsuari}
					isContractPage={isContractPage}
					key={`${input?.name || input.title}-${i}`}
					inputColumn={inputsAlign.inputColumn}
					labelColumn={inputsAlign.labelColumn}
					{...input}
					name={input?.name !== undefined ? input.name : '-'}
					label={input?.label !== undefined ? input.label : ''}
					relatedData={input.relatedData}
					type={inputType}
					globalParams={onlyMainParams ? mainParams : globalParams}
					traductionFunc={traductionFunc}
					formData={parentFormData ? parentFormData : formData}
					value={
						input.type === 'custom' ? undefined : input.label === 'ID' ? input.value : formData[input?.name || '']
					}
					editable={isCreating ? input.editable || input.editable_in_create : input.editable}
					onChange={handleChange}
					refetchFormData={refetchFormData}
					isEditing={isEditing}
					errors={formErrors ? formErrors[input?.name] : null}
					withSlugField={withSlugField}
					slugField={input.slugfield}
					slugFieldValue={
						withSlugField ? parentFormData[input.slugfield] || formData[input.slugfield] : undefined
					}
					isCreating={isCreating}
					toggleMap={toggleMapModal}
					getInputData={getInputData}
					isSearcher={isSearcher}
					divClassName={divClassName}
				/>
			);
			if (input.type === 'unknow') return null;
			if (oneColum) return newInput;
			return (
				<Col key={`dynamicInput-${i}`} md={displayMdSize(input)}>
					<span>{newInput}</span>
				</Col>
			);
		});

		if (oneColum) return newInputs;
		return <Row>{newInputs}</Row>;
	};

	const keyStartWith = (form, text) => {
		const keys = Object.keys(form);
		return keys.some((key) => key.startsWith(text));
	};

	const renderSeoPreview = () => {
		if (
			keyStartWith(formData, 'meta_title') ||
			keyStartWith(formData, 'meta_description') ||
			keyStartWith(formData, 'slug')
		) {
			let data = {};
			const result = getKeyStartWith(['meta_title', 'meta_description', 'slug'], formData);
			Object.entries(result).forEach(([key, value]) => {
				data = {
					...data,
					...(key.startsWith('meta_description') ? { meta_description: value } : {}),
					...(key.startsWith('meta_title') ? { meta_title: value } : {}),
					...(key.startsWith('slug') ? { slug: value } : {})
				};
			});
			const { meta_title, meta_description, slug } = data;
			return <SeoPreview title={meta_title} description={meta_description} link={slug} />;
		}
		return null;
	};

	const renderMapModal = () => mapConfig && <MapModal toggle={toggleMapModal()} {...mapConfig} />;
	return (
		<div className={`dynamic-form${labelWithBold ? ' withLabelBold' : ''} ${className}`}>
			{isValidated && renderDynamicInputs()}
			{renderSeoPreview()}
			{renderMapModal()}
		</div>
	);
};

DynamicForm.propTypes = {
	inputs: PropTypes.arrayOf(PropTypes.shape(Types.inputType)).isRequired,
	onChange: PropTypes.func.isRequired,
	isEditing: PropTypes.bool,
	labelWithBold: PropTypes.bool,
	oneColum: PropTypes.bool
};

DynamicForm.defaultProps = {
	oneColum: true
};

export default DynamicForm;
