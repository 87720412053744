import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Marker, Tooltip } from 'react-leaflet';

import iconMarkerFromLeaflet from '../../utils/iconMarkerFromLeaflet';
import MarkerPopup from '../MapComponents/MarkerPopup';

const DraggableMarker = ({
	position,
	markerData: marker = {},
	tooltipText,
	onChange,
	popupOptions = {},
	getPopupData: fetchPopup,
	isEditing = true,
	popupStructure,
	setDragState = () => {},
	atLoadOpenPopup,
	isCluster
}) => {
	const [draggable, setDraggable] = useState(false);
	const [popupLoad, setPopupLoad] = useState(true);
	const [popupContent, setPopupContent] = useState(null);
	const [isMounted, setIsMounted] = useState(true);
	const [selectedMarker, setSelectedMarker] = useState(0);
	const [refreshCount, setRefreshCount] = useState(0);
	const [markerData, setMarkerData] = useState(isCluster ? marker.points[selectedMarker] : marker);
	const markerRef = useRef(null);

	useEffect(() => {
		setIsMounted(false);
		setSelectedMarker(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [marker]);

	useEffect(() => {
		setMarkerData(isCluster ? marker.points[selectedMarker] : marker);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedMarker, marker]);

	const toggleDraggable = useCallback((popupData) => {
		setDragState((prev) => !prev);
		setDraggable((d) => {
			if (!d === false) {
				if (typeof onChange === 'function') {
					const marker = markerRef.current;
					if (!marker || !marker._latlng) return;
					const coor = marker.getLatLng();
					const isSameCoordinate = coor.lat === position[0] && coor.lng === position[1];
					if (isSameCoordinate) return;

					const body = {
						...markerData,
						...popupData,
						coordinate_x: coor.lng,
						coordinate_y: coor.lat
					};
					onChange(body);
				}
			}
			return !d;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [markerData]);

	async function getPopupData() {
		// if (popupContent && markerData.pk === popupContent.pk) return;
		setPopupLoad(true);
		const data = await fetchPopup(isCluster ? marker.points[selectedMarker] : marker);
		setPopupContent(data);
		setPopupLoad(false);
	}

	const handleLoadMarker = () => {
		if (atLoadOpenPopup && !isMounted) {
			markerRef.current.openPopup();
			setIsMounted(true);
		}
	};

	const onCancel = () => {
		setDragState((prev) => !prev);
		setRefreshCount((prev) => prev + 1);
		setDraggable(false)
	};

	return (
		<Marker
			icon={iconMarkerFromLeaflet(markerData?.point_color)}
			draggable={draggable}
			position={position}
			ref={markerRef}
			key={refreshCount}
			eventHandlers={{
				popupopen: getPopupData
			}}>
			{markerRef.current && handleLoadMarker()}
			{tooltipText ? <Tooltip direction="bottom">{tooltipText}</Tooltip> : null}
			<MarkerPopup
				dragOptions={{ toggleDraggable, draggable, onCancel }}
				loading={popupLoad}
				popupData={popupContent}
				popupStructure={popupStructure}
				popupOptions={popupOptions}
				isEditing={isEditing}
				onChangeSelectedMarker={setSelectedMarker}
				selectedMarker={selectedMarker}
			/>
		</Marker>
	);
};

export default DraggableMarker;

DraggableMarker.propTypes = {
	position: PropTypes.array,
	markerData: PropTypes.object,
	popupContent: PropTypes.object,
	tooltipText: PropTypes.string,
	onChange: PropTypes.func,
	markerColor: PropTypes.string,
	popupOptions: PropTypes.object,
	isEditing: PropTypes.bool
};
