import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import DynamicFormInput from '../DynamicForm/DynamicFormInput';
import { useState } from 'react';
import BasicModal from '../BasicModal';

const DeleteModal = (props) => {
	const { toggle, isOpen, onDelete, rowData } = props;
	const { custom_modal_delete } = rowData && typeof rowData === 'object' ? rowData : {};
	const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));

	const [t] = useTranslation('app');

	const handleDateChange = ({ target: { value } }) => {
		setStartDate(value);
	};

	const handleDeleteBtn = () => {
		if (custom_modal_delete) {
			if (startDate) return onDelete({ date_start: startDate });
		} else return onDelete(null);
	};

	return (
		<BasicModal
			title={t('trashModal.text')}
			description={t('trashModal.warningText')}
			onCancel={toggle}
			onConfirm={handleDeleteBtn}
			isOpen={isOpen}
			toggle={toggle}
			variant="delete">
			{custom_modal_delete && (
				<Fragment>
					<p className="mt-4 font-weight-bold">{t('trashModal.otsTexts.extraText')}</p>
					<div className="container ShowValidations">
						<DynamicFormInput
							type="date"
							value={startDate}
							name="date_start"
							onChange={handleDateChange}
							editable
							required
							label={t('trashModal.otsTexts.inputLabel')}
							labelColumn={{ md: '6', className: 'text-right' }}
							inputColumn={{ md: '6' }}
						/>
					</div>
				</Fragment>
			)}
		</BasicModal>
	);
};

DeleteModal.propTypes = {
	toggle: PropTypes.func,
	isOpen: PropTypes.bool,
	onDelete: PropTypes.func,
	rowData: PropTypes.object
};

export default DeleteModal;
