import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import authenticatedFetcher from '../../services/fetcher';
import { searchParams } from '../../services/commonServices';
import { buildCreateParams } from '../../utils';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Create from '../Create';

const AlarmCreation = (props) => {
	const {
		title,
		setLoading,
		endpoint,
		endpoint_method,
		config_file,
		formData,
		'params-creation': paramsCreations,
		toggle
	} = props;
	const [t] = useTranslation('app');
	const history = useHistory();
	const [createConfig, setCreateConfig] = useState(null);

	useEffect(() => {
		getConfigData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endpoint]);

	const getConfigData = async () => {
		const urlParams = searchParams(props, formData);
		if (setLoading) setLoading(title);
		const res = await authenticatedFetcher({
			path: endpoint,
			method: endpoint_method,
			urlParams
		});
		if (setLoading) setLoading(null);
		if (res && 'error' in res) return toastError(t('saveError'));
		if (res.pk) return history.push('/' + config_file + '/' + res.pk);
		const toCreateParams = buildCreateParams(paramsCreations);
		const createFormData = searchParams(toCreateParams, formData, 'params', 'paramsFixed');
		const reqParams = searchParams({ params: toCreateParams.reqParams }, formData);
		setCreateConfig({ fieldWithParams: reqParams, data: createFormData });
	};

	const handleComplete = () => toastSuccess(t('save'));

	return createConfig ? (
		<Create {...createConfig} toggle={toggle} id={config_file} fetchData={handleComplete} />
	) : (
		<Fragment />
	);
};

AlarmCreation.propTypes = {
	title: PropTypes.string,
	endpoint: PropTypes.string,
	endpoint_method: PropTypes.string,
	config_file: PropTypes.string,
	formData: PropTypes.object,
	params: PropTypes.object,
	'params-creation': PropTypes.object,
	'params-fixed': PropTypes.object,
	setLoading: PropTypes.func,
	toggle: PropTypes.func
};

export default AlarmCreation;
