import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Card } from 'reactstrap';
import WithFormConfig from '../../hocs/WithFormConfig';
import { DynamicForm } from '../DynamicForm';
import { useTranslation } from 'react-i18next';
import authenticatedFetcher from '../../services/fetcher';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import LoadingSpinner from '../LoadingSpinner';

const CustomModalOTSAssing = (props) => {
	const { open, toggle, fields, config, selectedItems = [], fetchData, buttonText, titleText } = props;
	const [formData, setFormData] = useState({});
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [t] = useTranslation(['modal', 'app']);

	const handleChange = (newData) => {
		setFormData((prev) => ({ ...prev, ...newData }));
	};

	const handleSubmit = async () => {
		const writeMethod = config.actions.find((item) => item.key === 'edit');
		if (!writeMethod || !selectedItems || (Array.isArray(selectedItems) && !selectedItems.length)) return;
		setLoadingSubmit(true);
		let allRequest = [];
		selectedItems.forEach((itemId) => {
			allRequest.push(
				authenticatedFetcher({ path: `${writeMethod.uri}${itemId}/`, method: 'PATCH', body: formData })
			);
		});
		const allRes = await Promise.all(allRequest);
		setLoadingSubmit(false);
		if (allRes.some((res) => !!res.error)) return toastError(t('app:errors.inOneSelectedItem'));
		toastSuccess(t('app:saved_successfully'));
		fetchData();
		return toggle();
	};

	return (
		<Modal toggle={toggle} isOpen={open}>
			<Card className="card-box">
				<div className="card-header py-3">
					<div className="card-header--title font-size-lg">{titleText}</div>
				</div>

				{Array.isArray(fields) && Array.isArray(config.fields) && (
					<>
						<div className="card-body">
							<DynamicForm
								onChange={handleChange}
								inputs={config.fields.filter((item) =>
									fields.some((allowField) => allowField === item.name)
								)}
							/>
						</div>
						<div className="card-footer">
							<Button type="submit" className="submitButton mr-2" onClick={toggle}>
								{t('modal:cancel')}
							</Button>
							<Button type="submit" className="submitButton" onClick={handleSubmit}>
								{buttonText}
								<LoadingSpinner show={loadingSubmit} />
							</Button>
						</div>
					</>
				)}
			</Card>
		</Modal>
	);
};

CustomModalOTSAssing.propTypes = {
	open: PropTypes.bool,
	toggle: PropTypes.func,
	fields: PropTypes.array,
	config: PropTypes.shape,
	selectedItems: PropTypes.array,
	fetchData: PropTypes.func,
	buttonText: PropTypes.string,
	titleText: PropTypes.string,
};

export default WithFormConfig(CustomModalOTSAssing);
