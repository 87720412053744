import React from 'react';

export default function DocumentsTableCell(props) {
	return (
		<td className={`DocumentsTable-cell ${props.className}`}>
			<div className={`DocumentsTable-cellContent ${props.classNameCell}`} style={props.style}>
				{props.children}
			</div>
		</td>
	);
}

DocumentsTableCell.defaultProps = {
	className: ''
};
