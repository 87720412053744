import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';

export default function OptionsTabs({
	selected = 0,
	options = [],
	onChange = () => {},
	labelField = 'name',
	disableUnselected = false
}) {
	const [selectedOption, setSelectedOption] = useState(0);

	useEffect(() => {
		if (selected !== selectedOption) setSelectedOption(selected);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	const handleChange = (newOption) => () => {
		if (typeof onChange === 'function') onChange(newOption);
		setSelectedOption(newOption);
	};

	return (
		<Nav tabs className="OptionsTabs">
			{options &&
				Array.isArray(options) &&
				options.map((option, i) => (
					<NavItem
						className={i === selectedOption ? 'active' : disableUnselected ? 'disabled' : ''}
						onClick={handleChange(i)}
						key={`options-${i}`}>
						<NavLink>{option[labelField]}</NavLink>
					</NavItem>
				))}
		</Nav>
	);
}

OptionsTabs.defaultProps = {
	selected: 0,
	options: [],
	onChange: () => {},
	labelField: 'name',
	disableUnselected: false
};

OptionsTabs.propTypes = {
	selected: PropTypes.number,
	options: PropTypes.arrayOf(PropTypes.object),
	onChange: PropTypes.func,
	labelField: PropTypes.string,
	disableUnselected: PropTypes.bool
};
