import React from 'react';
import PropTypes from 'prop-types';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const defaultOptions = {
	responsive: true,
	plugins: {
		legend: {
			display: false
		},
		title: {
			display: true,
			text: 'Chart.js Line Chart'
		}
	},
	tension: '0.3',
	scales: {}
};

const getGraphData = ({ xTitle, yTitle, title, xLabels, lineColor = 'rgb(255, 99, 132)', data }) => {
	let options = {...defaultOptions};
	let graphData = {
		labels: xLabels,
		datasets: [
			{
				data: Array.isArray(data) && data,
				borderColor: lineColor
			}
		]
	};
	if (title)
		options['plugins'] = {
			legend: {
				display: false
			},
			title: {
				display: true,
				text: title
			}
		};
	if (xTitle)
		options['scales'] = {
			xAxes: {
				title: {
					display: true,
					text: xTitle
				}
			}
		};
	if (yTitle)
		options['scales'] = {
			...options['scales'],
			yAxes: {
				title: {
					display: true,
					text: yTitle
				}
			}
		};
	return { options, data: graphData };
};

const LineGraph = (props) => {
	const { className } = props;
	const { options, data } = getGraphData(props);

	return <div className={className}>{data && options && <Line options={options} data={data} />}</div>;
};

LineGraph.propTypes = {
	className: PropTypes.string,
	xTitle: PropTypes.string,
	yTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	xLabels: PropTypes.array.isRequired,
	lineColor: PropTypes.string,
	data: PropTypes.array.isRequired
};

export default LineGraph;
