import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Label, Row, Input as InputComponent } from 'reactstrap';
import { Form } from 'react-bootstrap';

const Input = (props) => {
	const { id, name, value, placeholder, onChange, disabled, label, required, textAreaType, isEdit } = props;
	return (
		<FormGroup tag="fieldset" row>
			<Row className="w-100">
				<Col xs="6" md="5" className="text-sm-left text-md-right p-0">
					<Label for="form-2-last-name" className="w-100 font-weight-bold">
						{required ? '*' : ''} {label}
					</Label>
				</Col>
				<Col xs="6" md="7">
					{textAreaType ? (
						<span>
							<InputComponent
								id={`form-2-${id}`}
								type="textarea"
								style={{ height: 200 }}
								name={name}
								value={value}
								placeholder={placeholder}
								onChange={onChange}
								required={required}
								disabled={disabled}
							/>
						</span>
					) : (
						<span>
							<Form.Control
								className={isEdit ? '' : 'pt-0'}
								id={`form-2-${id}`}
								type="text"
								name={name}
								value={value}
								placeholder={placeholder}
								onChange={onChange}
								required={required}
								disabled={disabled}
							/>
							<FormFeedback>{value}</FormFeedback>
						</span>
					)}
				</Col>
			</Row>
		</FormGroup>
	);
};

Input.defaultProps = {
	required: false,
	disabled: false,
	onChange: () => {},
	textAreaType: false
};

Input.propTypes = {
	id: PropTypes.any,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.any,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	textAreaType: PropTypes.bool
};

export default Input;
