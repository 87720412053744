import React, { useEffect, useRef, useState } from 'react';
import slugify from 'slugify';
import { Form } from 'react-bootstrap';
import { Col, FormGroup, Label, FormFeedback } from 'reactstrap';
import { getInputStatus } from '../../../services/commonServices';
import { Types } from '../../../utils/DynamicForm.config';
import PrintInputError from '../../InputUtils/PrintInputError';
import InputDescription from '../../InputUtils/InputDescription';
import { MAX_SLUG_SIZE, TO_UPPER_CASE } from '../../../config';
import clsx from 'clsx';
import htmlToText from "html2plaintext"
import useDebounce from '../../../hooks/useDebounce';

const TypeFormTextbox = (props) => {
	const {
		id,
		label,
		name = '',
		value,
		placeholder,
		onChange,
		required,
		editable = true,
		isEditing = true,
		errors,
		style,
		withSlugField,
		to_upper_case,
		slugField = '',
		slugFieldValue = '',
		isSLAField,
		type,
		labelColumn = {
			xs: '6',
			md: '2'
		},
		inputColumn = {
			xs: '6',
			md: '10'
		},
		isEnrollment,
		alwaysEditableFields,
		relatedData,
		parse_slugfield = true,
		limit_slugfield = MAX_SLUG_SIZE,
		getInputData
	} = props;

	const [prevSlugField, setPrevSlugField] = useState(slugField);
	const [localValue, setLocalValue] = useState(value || '');
	const deboucedTerm = useDebounce(localValue, 500);

	useEffect(() => {
		setLocalValue((prev) => (prev === value ? prev : value));
		const slugFieldInfo = getInputData && getInputData(slugField);
		const parseField = name.includes('meta') ? false : parse_slugfield;
		let newSlugValue = slugFieldValue;
		if (slugFieldInfo && slugFieldInfo.type === 'textarea-rich' && typeof slugFieldValue === 'string')
			newSlugValue = htmlToText(slugFieldValue);
		if (limit_slugfield && typeof slugFieldValue === 'string')
			newSlugValue = newSlugValue.slice(0, limit_slugfield);
		const prevSlug = parseField ? slugify(prevSlugField, { strict: true, lower: true }) : prevSlugField;
		const newSlug = parseField ? slugify(newSlugValue, { strict: true, lower: true }) : newSlugValue;
		if (withSlugField && (!value || !!value === !!prevSlug)) {
			if (typeof newSlugValue === 'string') {
				onChange({
					target: {
						name,
						value: newSlug
					}
				});
				setPrevSlugField(newSlugValue);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slugFieldValue, value]);

	useEffect(() => {
		setLocalValue(deboucedTerm);
		onChange({ target: { value: deboucedTerm || "", name } })
	}, [deboucedTerm]);

	const handleChange = ({ target: { value } }) => {
		setLocalValue(value)
	};

	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;

	if (isEnrollment) {
		return (
			<FormGroup tag="fieldset" row>
				{label !== false ? (
					<Col
						{...labelColumn}
						className={`${labelColumn.className} ${isSLAField && 'CustomContractesInfo-sla-text'}`}>
						<Label style={style} for="form-2-last-name">
							{required ? '*' : ''} {label}
						</Label>
					</Col>
				) : (
					''
				)}
				<Col
					{...inputColumn}
					className={clsx(inputColumn.className, isSLAField && 'CustomContractesInfo-sla-text regular')}>
					<Form.Control
						id={`form-2-${id}`}
						type={type}
						name={name}
						placeholder={placeholder}
						defaultValue={localValue || ''}
						onChange={handleChange}
						style={{ textTransform: TO_UPPER_CASE && to_upper_case ? 'uppercase' : 'none' }}
						disabled={!isEditable || !isEditing}
						className={clsx(getInputStatus(required, localValue, errors), !isEditing && 'pl-0')}
					/>
					<FormFeedback>{localValue}</FormFeedback>
					<PrintInputError errors={errors} />
					<InputDescription description={relatedData ? relatedData.description : ''} />
				</Col>
			</FormGroup>
		);
	}

	if (label !== 'ID (web)') {
		return (
			<FormGroup tag="fieldset" row>
				{label !== false ? (
					<Col
						{...labelColumn}
						className={`${labelColumn.className} ${isSLAField && 'CustomContractesInfo-sla-text'}`}>
						<Label style={style} for="form-2-last-name">
							{required ? '*' : ''} {label}
						</Label>
					</Col>
				) : (
					''
				)}
				<Col
					{...inputColumn}
					className={`${inputColumn.className} ${isSLAField && 'CustomContractesInfo-sla-text regular'}`}>
					<Form.Control
						id={`form-2-${id}`}
						type={type}
						name={name}
						defaultValue={localValue || ''}
						placeholder={placeholder}
						onChange={handleChange}
						// required={required}
						disabled={!isEditable || !isEditing}
						className={!isEditing ? 'pl-0' : '' && getInputStatus(required, localValue, errors)}
					/>
					<FormFeedback>{localValue}</FormFeedback>
					<PrintInputError errors={errors} />
				</Col>
			</FormGroup>
		);
	}

	return (
		<FormGroup>
			<Label for={name} style={{ color: '#DCDCDC' }}>
				{label}
			</Label>
			: {localValue}
		</FormGroup>
	);
};
TypeFormTextbox.propTypes = Types.inputType;

TypeFormTextbox.defaultProps = {
	type: 'text'
};

export default TypeFormTextbox;
