import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { getInputStatus } from '../../../services/commonServices';
import PrintInputError from '../../InputUtils/PrintInputError';
import { Types } from '../../../utils/DynamicForm.config';
import InputDescription from '../../InputUtils/InputDescription';

let handleChangeTimeout = null;

const TypeFormTextarea = ({
	id,
	label,
	name,
	value,
	placeholder,
	onChange,
	required,
	editable,
	isEditing = true,
	errors,
	labelColumn = {
		xs: 6,
		md: 2
	},
	inputColumn = {
		xs: 6,
		md: 10
	},
	alwaysEditableFields,
	relatedData
}) => {
	const [, setIsLupaClicked] = useState(false);
	const [currentValue, setCurrentValue] = useState(value);

	useEffect(() => {
		setIsLupaClicked(() => JSON.parse(localStorage.getItem('isLupaClicked')));
	}, []);

	useEffect(() => {
		clearTimeout(handleChangeTimeout);
		handleChangeTimeout = setTimeout(() => onChange({ target: { name, value: currentValue } }), 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentValue]);

	const handleChange = ({ target: { value } }) => setCurrentValue(value);

	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;

	return (
		<FormGroup row>
			<Col {...labelColumn}>
				<Label for="form-2-last-name">
					{required ? '*' : ''} {label}
				</Label>
			</Col>
			<Col {...inputColumn}>
				<Input
					type="textarea"
					name={name}
					style={{ height: 200 }}
					id={id}
					required={required}
					value={currentValue}
					onChange={handleChange}
					disabled={!isEditable || !isEditing}
					className={getInputStatus(required, value, errors)}
				/>
				<PrintInputError errors={errors} />
				<InputDescription description={relatedData ? relatedData.description : ''} />
			</Col>
		</FormGroup>
	);
};

TypeFormTextarea.propTypes = Types.inputType;

export default TypeFormTextarea;
