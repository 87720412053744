import React, { useState } from 'react';
import slugify from 'react-slugify';
import { Button, Card } from 'reactstrap';
import cx from 'classnames';
import convertPythonDictToJSON from '../../utils/convertPythonDictToJSON';
import { useTranslation } from 'react-i18next';

const CustomModalSetting = ({ setFormData, formData, handleSubmit }) => {
	const [name, setName] = useState();
	const [type, setType] = useState('valor');
	const [filter, setFilter] = useState(0);
	const [show, setShow] = useState(1);
	const [exportValue, setExportValue] = useState(1);

	const [t] = useTranslation('modal');

	const handleToConfigNewData = () => {
		const nameSlug = slugify(name, { delimiter: '_' });
		const newFieldConfig = {
			[nameSlug]: '',
			[`${nameSlug}__name`]: name,
			[`${nameSlug}__type`]: type,
			[`${nameSlug}__filter`]: filter,
			[`${nameSlug}__show`]: show,
			[`${nameSlug}__export`]: exportValue,
		};
		setFormData(prev => {
			const prevObject = convertPythonDictToJSON(prev.supplementary_data);
			const newFormData = {
				...prev,
				supplementary_data: JSON.stringify({ ...prevObject, ...newFieldConfig })
			}
			handleSubmit(newFormData)
			return newFormData
		});
	};
	return (
		<Card className={cx('card-box', 'settings-tecniques')}>
			<div>
				<span className="title">{t("elements.importFeatures")}</span>
			</div>
			<div className="setting-line" />
			<div className="setting-container">
				<div>
					<label>{t("elements.name")}</label>
					<input onChange={(e) => setName(e.target.value)} value={name} />
				</div>
				<div>
					<label>{t("elements.type")}</label>
					<select onChange={(e) => setType(e.target.value)}>
						<option selected value="valor">
							Valor
						</option>
						<option value="texto">{t("elements.text")}</option>
						<option value="fecha">{t("elements.date")}</option>
					</select>
				</div>
				<div>
					<label>Filtrable</label>
					<select onChange={(e) => setFilter(e.target.value)}>
						<option value="1">Sí</option>
						<option selected value="0">
							No
						</option>
					</select>
				</div>
				<div>
					<label>Mostrable</label>
					<select onChange={(e) => setShow(e.target.value)}>
						<option selected value="1">
							Sí
						</option>
						<option value="0">No</option>
					</select>
				</div>
				<div>
					<label>Exportable</label>
					<select onChange={(e) => setExportValue(e.target.value)}>
						<option selected value="1">
							Sí
						</option>
						<option value="0">No</option>
					</select>
				</div>
			</div>
			{/* {...formData, supplementary_data: JSON.stringify({tipus: 'Trifàsica', entrada: 'Soterrada', envolvent: 'Integrada a Quadre', seccio_entrada: 10, material: 'Coure', caixes: 'CS+GGP', 'fusibles': ""})} */}
			<Button onClick={handleToConfigNewData} className="button" size="sm" color="success">
				Guardar Canvis
			</Button>
		</Card>
	);
};

export default CustomModalSetting;
