import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import formatDate from './TypeFormDate';
import DatePicker from 'react-datepicker';

// import months from '../../../data/months';
// import days from '../../../data/days';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Types } from '../../../utils/DynamicForm.config';
import { getInputStatus } from '../../../services/commonServices';
import PrintInputError from '../../InputUtils/PrintInputError';
import DateMask from '../../MaskedInput/DateMask';
import InputDescription from '../../InputUtils/InputDescription';
import moment from 'moment';

const TypeFormInputDate = ({
	id,
	label,
	name,
	value,
	placeholder,
	editable,
	onChange,
	isEditing = true,
	required,
	errors,
	isOperations,
	labelColumn = {
		xs: '6',
		md: '2'
	},
	inputColumn = {
		xs: '6',
		md: '10'
	},
	type,
	alwaysEditableFields,
	relatedData
}) => {
	const [startDate, setStartDate] = useState('');

	useEffect(() => {
		if (required || value) {
			const initDate = formatDate.fromApiToPicker(value, true);
			onChange({ target: { value: value ? value : formatDate.fromPickerToApi(initDate), name } });
			setStartDate(initDate);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnChange = (date) => {
		setStartDate(date);
		onChange({ target: { name, value: date ? formatDate.fromPickerToApi(date) : null } });
	};

	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;

	return (
		<FormGroup tag="fieldset" row>
			<Col {...labelColumn}>
				<Label for={name} style={{ textAlign: 'inherit' }}>
					{required ? '*' : ''} {label}
				</Label>
			</Col>
			<Col {...inputColumn}>
				{isEditable && isEditing ? (
					<>
						<div className="Datepicker-wrapper">
							<DatePicker
								id={id || name}
								type="datetime-local"
								name={name}
								dateFormat="dd/MM/yyyy"
								value={startDate}
								selected={startDate}
								shouldCloseOnSelect
								onChange={handleOnChange}
								autocomplete="off"
								// locale={locale}
								todayButton="Hoy"
								monthsShown={2}
								className={getInputStatus(required, value, errors)}
								customInput={<DateMask type={type} />}
							/>
							<FontAwesomeIcon icon="calendar" title="magic" />
						</div>
						<PrintInputError errors={errors} />
						<InputDescription description={relatedData ? relatedData.description : ''} />
					</>
				) : value ? (
					moment(value).format('DD/MM/yyyy')
				) : (
					''
				)}
			</Col>
		</FormGroup>
	);
};

TypeFormInputDate.propTypes = Types.inputType;

export default TypeFormInputDate;
