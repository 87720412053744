import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import { Col, FormGroup, Label, FormFeedback } from 'reactstrap';

const TypeFormHidden = (props) => {
	const {
		id,
		label,
		name,
		value,
		style,
		editable = true,
		isEditing = true,
		labelColumn = {
			xs: '6',
			md: '2'
		},
		inputColumn = {
			xs: '6',
			md: '10'
		},
		onChange,
		required
	} = props;

	useEffect(() => {
		onChange({ target: { value, name } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<FormGroup tag="fieldset" className="d-none" row>
			{label !== false ? (
				<Col {...labelColumn}>
					<Label style={style} for="form-2-last-name">
						{required ? '*' : ''} {label}
					</Label>
				</Col>
			) : (
				''
			)}
			<Col {...inputColumn}>
				<Form.Control
					id={`form-2-${id}`}
					type="hidden"
					name={name}
					value={value}
					disabled={!editable || !isEditing}
				/>
				<FormFeedback>{value}</FormFeedback>
			</Col>
		</FormGroup>
	);
};

TypeFormHidden.propTypes = {
	id: PropTypes.number,
	label: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.any,
	style: PropTypes.object,
	editable: PropTypes.bool,
	isEditing: PropTypes.bool,
	onChange: PropTypes.func,
	required: PropTypes.bool
};

export default TypeFormHidden;
