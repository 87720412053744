const convertPythonDictToJSON = function (data) {
	let d = data.replace(new RegExp(`(?<=[a-zA-Z])'(?=[a-zA-Z ])`, 'g'), '%%%');
	d = d.replace(new RegExp("'", 'g'), '"');
	d = d.replace(new RegExp('%%%', 'g'), "'");
	d = d.replace(new RegExp('None', 'g'), 'null');
	d = d.replace(new RegExp('False', 'g'), 'false');
	d = d.replace(new RegExp('True', 'g'), 'true');
	return JSON.parse(d);
};

export default convertPythonDictToJSON;
