import React, { memo, useState, useEffect, useContext } from 'react';
import AppContext from '../context';
import { useTranslation } from 'react-i18next';
import { searchParams } from '../services/commonServices';
import { getMapConfig } from '../services/mapServices';

function WithMapConfig(MapComponent, isModal) {
	const Wrapper = (props) => {
		const [loading, setLoading] = useState(true);
		const [mapProps, setMapProps] = useState({});
		const [originConfig, setOriginConfig] = useState();
		const {
			config: { mainParams, globalParams: allGlobalParams, gParamsIsReady }
		} = useContext(AppContext);
		const globalParams = isModal ? allGlobalParams : mainParams;
		const [t] = useTranslation(['map', 'app']);

		useEffect(() => {
			const mapConfig = isModal ? props : getMapConfig();
			setOriginConfig(mapConfig);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		useEffect(() => {
			if (gParamsIsReady && originConfig) setProps();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [gParamsIsReady, originConfig]);

		const setProps = () => {
			const {
				endPointConfig,
				originData,
				redirectTo,
				popupStructure = [
					// { label: [t('popup.id')], value: 'pk' },
					{ label: [t('popup.codes')], value: 'name', itemStyle: { color: 'blue' } },
					{ label: [t('popup.contracte')], value: 'contract_name' },
					{ label: [t('popup.installation')], value: 'installation_name' },
					{ label: [t('popup.subinstallation')], value: 'installation_parent_name' },
					{ label: [t('popup.family')], value: 'family_parent_name' },
					{ label: [t('popup.subfamily')], value: 'family_name' },
					{ label: [t('popup.coorX')], value: 'coordinate_x' },
					{ label: [t('popup.coorY')], value: 'coordinate_y' }
				]
			} = originConfig ? originConfig : {};
			const params = searchParams(endPointConfig, originData);
			setMapProps({
				params: { ...params, filters: 1, ...globalParams },
				endPointConfig,
				redirectTo,
				popupStructure
			});
			setLoading(false);
		};

		if (loading) return t('app:loading');

		return <MapComponent {...props} {...mapProps} />;
	};

	return memo(Wrapper);
}

export default WithMapConfig;
