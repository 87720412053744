import React, { useContext, memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../context';
import getConfigByType from '../services/get-config-by-type';
import { UPDATE_LIST_RELATION } from '../reducers/config-reducer/types';
import { getMethods } from '../services/commonServices';
import { useTranslation } from 'react-i18next';

const LIST_TYPE = 'list';

function WithListConfig(ListComponent, options = {}) {
	const { loadingElement } = options;

	const Wrapper = (props) => {
		const { id, initialConfig, config: listConfigFromProps, configParams = {}, relatedID } = props;
		const {
			config: { globalParams, ...config },
			dispatch
		} = useContext(AppContext);
		const formCtxData = props.formContext ? props.formContext : {};
		const relationConfig = config.lists[id];
		const [t] = useTranslation('app');
		const [extraParams, setExtraParams] = useState(props.params || {});
		const [prevRefreshCount, setPrevResfrehCount] = useState();
		const [isMounted, setIsMounted] = useState();
		const [reloadValue, setReloadValue] = useState(1);
		const mountedRef = useRef(true);

		useEffect(() => {
			fetchData();
			return () => {
				mountedRef.current = false;
			};
			// eslint-disable-next-line
		}, []);

		useEffect(() => {
			if (formCtxData.allFormData && initialConfig && initialConfig.field_listen) {
				const newValues = Object.entries(initialConfig.field_listen || {}).reduce(
					(acc, [paramName, formField]) => {
						if (extraParams[paramName] !== formCtxData.allFormData[formField])
							return acc
								? { ...acc, [paramName]: formCtxData.allFormData[formField] }
								: { [paramName]: formCtxData.allFormData[formField] };
						return acc;
					},
					null
				);
				if (newValues) {
					setExtraParams((prev) => ({ ...prev, ...newValues }));
				}
			}
			// eslint-disable-next-line
		}, [formCtxData.allFormData]);

		useEffect(() => {
			if (formCtxData.refreshRelated && formCtxData.refreshRelated[relatedID]) {
				setPrevResfrehCount((prev) =>
					prev !== formCtxData.refreshRelated[relatedID] ? formCtxData.refreshRelated[relatedID] : prev
				);
			}
			// eslint-disable-next-line
		}, [formCtxData.refreshRelated]);

		useEffect(() => {
			if (isMounted) setReloadValue((prev) => prev + 1);
			else setIsMounted(true);

			// eslint-disable-next-line
		}, [extraParams, prevRefreshCount]);

		const fetchData = () => {
			if (!relationConfig && !listConfigFromProps) {
				let params = {};
				if (globalParams && typeof globalParams === 'object') params = globalParams;
				if (configParams) params = { ...params, ...configParams };
				getConfigByType({ id, urlParams: { type: LIST_TYPE, ...params } }).then((configResponse) => {
					if (!mountedRef.current) return null;
					const actions = getMethods(configResponse.actions, config.routes);
					return dispatch({
						type: UPDATE_LIST_RELATION,
						payload: {
							[id]: {
								toCreateModal:
									initialConfig && initialConfig.toCreateModal ? initialConfig.toCreateModal : null,
								...configResponse,
								actions
							}
						}
					});
				});
			}
		};

		const listConfig = listConfigFromProps ? listConfigFromProps : config.lists[id];

		if (!listConfig) return loadingElement ? loadingElement : t('fetchingConfig');

		return (
			<ListComponent key={`List-reloadId="${reloadValue}"`} config={listConfig} {...props} params={extraParams} formContext={formCtxData} />
		);
	};

	Wrapper.propTypes = {
		id: PropTypes.string.isRequired
	};

	return memo(Wrapper);
}

export default WithListConfig;
