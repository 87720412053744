import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import fetcher from '../../../services/fetcher';
import { Col, FormGroup, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSelectFormListener from '../../../hooks/useSelectFormListener';
import InputDescription from '../../InputUtils/InputDescription';
import Multiselect from './TypeMultiSelect';
import LoadingSpinner from '../../LoadingSpinner';
import { useTranslation } from 'react-i18next';

const InputTypeMultiSelectAsync = (props) => {
	const {
		label,
		name,
		options: inputConfig,
		onChange,
		defaultValue,
		isEditing = true,
		labelColumn = {
			xs: '6',
			md: '2'
		},
		inputColumn = {
			xs: '6',
			md: '10'
		},
		globalParams = {},
		alwaysEditableFields,
		editable,
		relatedData
	} = props;
	const selectsColumns = inputColumn
		? { md: { size: inputColumn.md, offset: 12 - inputColumn.md } }
		: { md: { size: 10, offset: 2 } };
	const [currentOptions, setOptions] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState();
	const [inputValue, setInputValue] = useState('');
	const [isMounted, setIsMounted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [customParams] = useSelectFormListener(props);
	const [t] = useTranslation('app');

	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;

	useEffect(() => {
		const setInitState = () => {
			const defaultSelected =
				defaultValue && Array.isArray(defaultValue)
					? defaultValue.map((item) => ({ [inputConfig.key]: item.key, [inputConfig.print]: item.value }))
					: [];
			setSelectedOptions(defaultSelected);
		};
		if (!isMounted) {
			setInitState();
			setIsMounted(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedOptions)
			onChange({ target: { name, value: selectedOptions.map((item) => item[inputConfig.key]) } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOptions]);

	useEffect(() => {
		if (Object.values(customParams).length) handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customParams]);

	const handleSearch = async () => {
		const initValues = selectedOptions.length ? selectedOptions : [];
		const extraParams = inputConfig.params ? inputConfig.params : {};
		const paramsFixed = inputConfig['params-fixed'] ? inputConfig['params-fixed'] : {};
		setLoading(true);
		const data = await fetcher({
			path: `${inputConfig.endpoint}`,
			urlParams: {
				q: inputValue,
				fields: inputConfig.print,
				...extraParams,
				...paramsFixed,
				...customParams,
				...globalParams,
				from_select: 1
			}
		});
		setLoading(false);
		const newOptions =
			data && Array.isArray(data)
				? data.filter(
						(item) =>
							!initValues.some((selectedItem) => selectedItem[inputConfig.key] === item[inputConfig.key])
				  )
				: [];
		if (newOptions) setOptions(newOptions);
	};

	const handleInputChange = ({ target: { value } }) => setInputValue(value);

	return (
		<FormGroup tag="fieldset" row>
			<Col {...labelColumn}>
				<Label for={name}>{label}</Label>
			</Col>
			{isEditing && isEditable ? (
				<>
					{' '}
					<Col {...inputColumn}>
						<div className="d-flex flex-start mb-2 align-items-center noCustom">
							<span className="input-select-async-wrapper">
								<input
									type="text"
									className="form-control"
									onChange={handleInputChange}
									disabled={!isEditing || !isEditable}
								/>
							</span>
							<button
								type="button"
								onClick={handleSearch}
								className="btn btn-dark btn-sm ml-1 d-flex"
								disabled={!isEditing || !isEditable}>
								<span className="btn-wrapper--icon">
									<FontAwesomeIcon icon={['fas', 'search']} className="text-light" />
								</span>
								<span className="btn-wrapper--label">{t('search')}</span>
							</button>
							<LoadingSpinner show={loading} />
						</div>
					</Col>
					<Col {...selectsColumns}>
						{currentOptions && selectedOptions && (
							<Multiselect
								basicMode
								items={currentOptions}
								options={{ key: inputConfig.key, print: inputConfig.print }}
								selectedItems={selectedOptions}
								onChange={onChange}
								editable
								isEditing={isEditing}
								name={name}
							/>
						)}
						<InputDescription description={relatedData ? relatedData.description : ''} />
					</Col>{' '}
				</>
			) : (
				<Col {...inputColumn}>
					<ul>
						{selectedOptions &&
							selectedOptions.map((option, i) => {
								return (
									<li key={`noEditable-selectedOption->${i}`} className="d-block">
										{option[inputConfig.print]}
									</li>
								);
							})}
					</ul>
				</Col>
			)}
		</FormGroup>
	);
};

// InputTypeMultiSelectAsync.propTypes = {};

export default InputTypeMultiSelectAsync;
