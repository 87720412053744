import { format } from 'date-fns';

const getUTCDate = (dateString = Date.now(), withTime = false) => {
	const changeDate = new Date(dateString);
	if (withTime)
		return new Date(
			changeDate.getFullYear(),
			changeDate.getMonth(),
			changeDate.getDate(),
			changeDate.getHours(),
			changeDate.getMinutes()
		);
	return new Date(changeDate.getFullYear(), changeDate.getMonth(), changeDate.getDate());
};

const TypeFormDate = {
	fromApiToPicker: (prop, deleteHyphens = undefined) => {
		if (!prop) return new Date();
		return Date.parse(deleteHyphens ? prop.replaceAll('-', '/') : prop);
	},
	fromPickerToApi: (prop, { withTime = false } = {}) => {
		// const aestTime = new Date(prop).toLocaleString('en-US', {
		// 	timeZone: 'Australia/Brisbane'
		// });
		// const currentVal = new Date(aestTime).toISOString();
		// const formattedDate = format(getUTCDate(currentVal), `yyyy-MM-dd hh:mm:ss`);
		if (!prop) return '';
		return format(getUTCDate(prop, withTime), `yyyy-MM-dd${withTime ? ' HH:mm' : ''}`);
	}
};
export default TypeFormDate;
