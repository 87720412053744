import React, { useState } from 'react';
import { searchFields, searchParams } from '../services/commonServices';
import ContentMenuActions from './contentMenuActions';
import PlusList from './TableComponents/PlusList';
import TableItemList from './TableItemList';

const TableRow = ({
	fieldData: field,
	id,
	name,
	read,
	relations,
	rowsConfig,
	primaryKey,
	openedItem,
	setOpenedItem,
	checkAllInputs,
	page,
	headerFromMenu,
	fieldsWhiteList,
	fetchData,
	plusConfig,
	handleLupaClicked,
	handleEditClicked,
	handleTrashClick,
	handleCopyClick = () => {},
	handleColumnCheck,
	handleChangeInput,
	fields,
	handleMapClick,
	globalConfig: config
}) => {
	const [plusState, setPlusState] = useState(false);
	const columns = fieldsWhiteList.map((fieldToDisplay) => {
		const allData = fields.find((f) => f.field === fieldToDisplay);
		return {
			content: field[fieldToDisplay],
			class: field[`${fieldToDisplay}__class`],
			style: field[`${fieldToDisplay}__style`],
			map: fieldToDisplay === 'map' && allData,
			allData
		};
	});
	const { edit: editMethod } = config.actions;
	const mainKey = field[primaryKey];
	const rowConfig = searchFields(['row__'], field);
	const detailPath = field.custom_path
		? field.custom_path
		: field.custom_page
		? `/${field.custom_page}/${mainKey}`
		: `${editMethod.uri || config.uri}${mainKey}`;

	const handlePlus = () => {
		setPlusState((prev) => !prev);
	};

	const isEditable = 'no_edit' in field ? !field.no_edit : headerFromMenu.edit;
	const isRemovable = 'no_delete' in field ? !field.no_delete : headerFromMenu.trash;
	const enablePlusBtn = 'no_plus' in field ? !field.no_plus : plusConfig;

	const handleOpenItem = () => setOpenedItem(mainKey);

	const renderRelatedLists = () =>
		enablePlusBtn
			? plusConfig.map((relatedItem) => {
					const params = searchParams(relatedItem, field);
					return (
						<PlusList
							key={`PlusList-${relatedItem.endpoints.id}`}
							id={relatedItem.endpoints.id}
							params={params}
							rowsConfig={rowsConfig}
							columns={columns}
						/>
					);
			  })
			: null;

	return (
		<>
			<TableItemList
				id={id}
				edit={isEditable}
				read={read}
				relations={relations}
				config={rowConfig}
				rowData={field}
				rowsConfig={rowsConfig}
				mainKey={mainKey}
				columns={columns}
				isOpen={openedItem === mainKey}
				onOpen={handleOpenItem}
				checkAllInputs={checkAllInputs}
				handleMapClick={handleMapClick(detailPath)}
				detailPath={detailPath}
				redirectToDetail={handleEditClicked(detailPath)}
				handleColumnCheck={handleColumnCheck}
				handleChangeInput={handleChangeInput}
				showActions={rowsConfig.actions || enablePlusBtn}
				page={page}>
				<ContentMenuActions
					{...headerFromMenu}
					edit={isEditable}
					trash={isRemovable}
					name={name}
					id={id}
					columns={columns}
					fetchData={fetchData}
					menuButton
					showActions={rowsConfig.actions}
					copy={rowsConfig.duplicate}
					mainKey={mainKey}
					handleCopyClick={handleCopyClick(mainKey)}
					handleLupaClick={handleLupaClicked(detailPath)}
					handleTrashClick={handleTrashClick(mainKey)}
					rowData={field}
					detailPath={detailPath}
					handlePlusClick={enablePlusBtn && Array.isArray(plusConfig) ? handlePlus : undefined}
				/>
			</TableItemList>
			{plusState && renderRelatedLists()}
		</>
	);
};

export default TableRow;
