import { divIcon } from 'leaflet';

const myCustomColour = '#583470'

const iconMarkerFromLeaflet = (classFromIcon = myCustomColour) => {
  const markerHtmlStyles = `
  background-color: ${classFromIcon};
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  left: -.7rem;
  top: -.3rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
  border: 1px solid #FFFFFF`

  return divIcon({
  className: "my-custom-pin",
  iconAnchor: [0, 24],
  labelAnchor: [-6, 0],
  popupAnchor: [0, -36],
  html: `<span style="${markerHtmlStyles}" />`
})
}

export default iconMarkerFromLeaflet;
