import CustomActiu from '../pages/CustomActiu';
import CustomElement from '../pages/CustomElement';
import CustomInstallacio from '../pages/CustomInstallacio';
import CustomTicketing from '../pages/CustomTicketing';
import CustomContractes from '../pages/CustomContractes';
import CustomPlanificador from '../pages/CustomPlanificador';
import CustomEmpresa from '../pages/CustomEmpresa';
import CustomTicketingTreball from '../pages/CustomTicketingTreball';
import CustomUsuari from '../pages/CustomUsuari';
import CustomModals from '../pages/CustomModals';
import CustomRecertification from '../pages/CustomRecertification';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import Searcher from '../pages/Searcher';
import CustomActiuTelegestio from '../pages/CustomActiuTelegestio';
import CustomInstallacioTelegestio from '../pages/CustomInstallacioTelegestio';
import CustomElementTelegestio from '../pages/CustomElementTelegestio';
import EfpaReport from '../pages/EfpaReport';
import CustomMassiveEmail from '../pages/CustomMassiveEmail';
import PlanificationsReportSearcher from "../pages/PlanificationsReportSearcher";
import PlanificationsReportSearcherCartera from "../pages/PlannificationsReportSearcherCartera";
import CustomPlanificationsCostCenters from "../pages/CustomPlanificationsCostCenters"
import CustomPlanificationsObjectives from "../pages/CustomPlanificationsObjectives";
import CustomPlanificationsObjectivesNewContract from "../pages/CustomPlanificationsObjectivesNewContract";
import CustomPlanificationsObjectivesAdditionals from "../pages/CustomPlanificationsObjectivesAdditionals";
import CustomPlanificationsTracingContract from "../pages/CustomPlanificationsTracingContract";
import CustomPlanificationsTracingAggregate from "../pages/CustomPlanificationsTracingAggregate";


const ROUTES_CONFIG = [
	{
		id: 'home',
		path: '/',
		exact: true,
		view: Home
	},
	{
		id: 'EfpaReport',
		path: '/efpa-reporte',
		exact: true,
		view: EfpaReport
	},
	{
		id: '404',
		path: '*',
		view: NotFound
	},
	{
		id: 'asset',
		path: '/custom-actiu/:id',
		view: CustomActiu
	},
	{
		id: 'asset_telegestio',
		path: '/custom-actiu-telegestio/:id',
		view: CustomActiuTelegestio
	},
	{
		id: 'installation',
		path: '/custom-installacio/:id',
		view: CustomInstallacio
	},
	{
		id: 'installation_telegestio',
		path: '/custom-installacio-telegestio/:id',
		view: CustomInstallacioTelegestio
	},
	{
		id: 'element',
		path: '/custom-element/:id',
		view: CustomElement
	},
	{
		id: 'element_telegestio',
		path: '/custom-element-telegestio/:id',
		view: CustomElementTelegestio
	},
	{
		id: 'ticketing',
		path: '/detalls-ticketing/:id',
		view: CustomTicketing
	},
	{
		id: 'contractes',
		path: '/custom-contracte/:id',
		view: CustomContractes
	},
	{
		id: 'empresa',
		path: '/companies/managers/:id',
		view: CustomEmpresa
	},
	{
		id: 'clients',
		path: '/companies/clients/:id',
		view: CustomEmpresa
	},
	{
		id: 'planificador',
		path: '/detalls-planificador/:id',
		view: CustomPlanificador
	},
	{
		id: 'ticketingTreball',
		path: '/detalls-ticketing-treball/:id',
		view: CustomTicketingTreball
	},
	{
		id: 'usuari',
		path: '/detalls-usuari/:id',
		view: CustomUsuari
	},
	{
		id: 'modals',
		path: '/custom-modals/:id',
		view: CustomModals
	},
	{
		id: 'recertification',
		path: '/custom-recertification/:id',
		view: CustomRecertification
	},
	{
		id: 'searcher',
		path: '/searcher',
		view: Searcher
	},
	{
		id: 'massive-email',
		path: '/custom-massive-email/:id',
		view: CustomMassiveEmail
	},
	{
		id: 'informes-planificacio',
		path: '/informes-planificacio',
		view: PlanificationsReportSearcher
	},
	{
		id: 'informes-planificacio-cartera',
		path: '/informes-planificacio-cartera',
		view: PlanificationsReportSearcherCartera
	},
	{
		id: 'informes-centres-cost',
		path: '/informes-centres-cost',
		view: CustomPlanificationsCostCenters
	},
	{
		id: 'planificacio-objectius',
		path: '/planificacio-objectius',
		view: CustomPlanificationsObjectives
	},
	{
		id: 'planificacio-objectius-nova-contractacio',
		path: '/planificacio-objectius-nova-contractacio',
		view: CustomPlanificationsObjectivesNewContract
	},
	{
		id: 'planificacio-objectius-adicionals',
		path: '/planificacio-objectius-adicionals',
		view: CustomPlanificationsObjectivesAdditionals
	},
	{
		id: 'planificacio-seguiment-contracte',
		path: '/planificacio-seguiment-contracte',
		view: CustomPlanificationsTracingContract
	},
	{
		id: 'planificacio-seguiment-agregat',
		path: '/planificacio-seguiment-agregat',
		view: CustomPlanificationsTracingAggregate
	},
];

export default ROUTES_CONFIG;
