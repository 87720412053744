import React from 'react';
import PropTypes from 'prop-types';
import ImatgesComponent from '../../CustomInstallacio/ImatgesComponent';

const TypeGallery = (props) => {
	const { editable = true, isEditing = true, options: inputConfig, isCreating, upload_param_name, label } = props;
	if (isCreating) return null;
	return (
		<ImatgesComponent
			{...props}
			postParams={inputConfig.params_upload}
			getParams={inputConfig.params}
			endpoint={inputConfig.endpoint}
			disabled={!editable || !isEditing}
			name={upload_param_name}
			title={label}
		/>
	);
};

TypeGallery.propTypes = {
	editable: PropTypes.bool,
	isEditing: PropTypes.bool,
	options: PropTypes.object,
	isCreating: PropTypes.bool
};

export default TypeGallery;
