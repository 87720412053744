import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import DynamicFormInput from '../DynamicForm/DynamicFormInput';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import authenticatedFetcher from '../../services/fetcher';
import LoadingSpinner from '../LoadingSpinner';

const CustomModalChangeIncidences = (props) => {
	const { open, toggle, selectedItems = [], fetchData } = props;

	const [formData, setFormData] = useState({});
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [t] = useTranslation(['modal', 'app']);

	const handleChange = ({ target: { value, name } }) => setFormData(() => ({ [name]: value }));

	const handleSubmit = async () => {
		if (!selectedItems || (Array.isArray(selectedItems) && !selectedItems.length)) return;
		setLoadingSubmit(true);
		let allRequest = [];
		selectedItems.forEach((itemId) => {
			allRequest.push(
				authenticatedFetcher({ path: `/ticketing/incidences/${itemId}/`, method: 'PATCH', body: formData })
			);
		});
		const allRes = await Promise.all(allRequest);
		setLoadingSubmit(false);
		if (allRes.some((res) => !!res.error)) return toastError(t('app:errors.inOneSelectedItem'));
		toastSuccess(t('app:changes_made'));
		fetchData();
		return toggle();
	};

	return (
		<Modal toggle={toggle} isOpen={open}>
			<Card className="card-box">
				<div className="card-header py-3">
					<div className="card-header--title font-size-lg">{t('modal:incidencesModal.title')}</div>
				</div>

				<div className="card-body dynamic-form">
					<DynamicFormInput
						type="select"
						name="status"
						label={t('modal:incidencesModal.label')}
						onChange={handleChange}
						options={{ endpoint: '/ticketing/incidences/status/massive/', key: 'pk', print: 'title' }}
						editable
						required
					/>
				</div>
				<div className="card-footer">
					<Button type="submit" className="submitButton mr-2" onClick={toggle}>
						{t('modal:cancel')}
					</Button>
					<Button type="submit" className="submitButton" onClick={handleSubmit}>
						{t('modal:incidencesModal.submitBtn')}
						<LoadingSpinner show={loadingSubmit} />
					</Button>
				</div>
			</Card>
		</Modal>
	);
};

CustomModalChangeIncidences.propTypes = {
	open: PropTypes.bool,
	toggle: PropTypes.func,
	fields: PropTypes.array,
	config: PropTypes.shape,
	selectedItems: PropTypes.array,
	fetchData: PropTypes.func
};

export default CustomModalChangeIncidences;
