import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Col } from 'react-bootstrap';
import { FormGroup, Label } from 'reactstrap';
import useSelectFormListener from '../../../hooks/useSelectFormListener';
import { getInputStatus, getItems } from '../../../services/commonServices';
import authenticatedFetcher from '../../../services/fetcher';
import BasicMultiSelect from '../../BasicMultiSelect';
import InputDescription from '../../InputUtils/InputDescription';
import PrintInputError from '../../InputUtils/PrintInputError';

const TypeMultiSelectInline = (props) => {
	const {
		label,
		value,
		name,
		options: inputConfig = { key: 'value', print: 'label' },
		onChange,
		required,
		errors,
		isEditing = true,
		globalParams = {},
		labelColumn = {
			xs: 6,
			md: 2
		},
		inputColumn = {
			xs: 6,
			md: 10
		},
		alwaysEditableFields,
		editable,
		relatedData,
		withoutFetch
	} = props;
	const [currentOptions, setCurrentOptions] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState({});
	const [isMounted, setIsMounted] = useState(false);
	const [customParams, isHearing] = useSelectFormListener(props);
	const hasChange = useRef(false);

	useEffect(() => {
		if (!isMounted && !isHearing && !withoutFetch) handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (Object.values(customParams).length && !withoutFetch) handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customParams]);

	useEffect(() => {
		if (hasChange.current && typeof onChange === 'function')
			onChange({
				target: { name, value: Object.values(selectedOptions).map((item) => item[inputConfig.key]) }
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOptions]);

	const handleSearch = async () => {
		const defaultValues = value && Array.isArray(value) ? value : [];
		const params = inputConfig.params ? inputConfig.params : {};
		const paramsFixed = inputConfig['params-fixed'] ? inputConfig['params-fixed'] : {};
		const data = await authenticatedFetcher({
			path: `${inputConfig.endpoint}`,
			urlParams: { ...params, ...paramsFixed, ...globalParams, ...customParams }
		});
		if (data.error || !data) return setCurrentOptions([]);
		const items = getItems(data);
		const newData = items
			? items.reduce(
					(acc, item, i) => {
						if (defaultValues.some((selectedItem) => selectedItem === item.pk)) {
							return {
								...acc,
								selected: { ...acc.selected, [i]: item },
								options: [...acc.options, item]
							};
						} else {
							return {
								...acc,
								options: [...acc.options, item]
							};
						}
					},
					{ options: [], selected: {} }
			  )
			: null;
		setIsMounted(true);
		if (newData) {
			setCurrentOptions(newData.options);
			setSelectedOptions(newData.selected);
		}
	};

	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable && isEditing;

	const getStatus = () => {
		const isValid = Array.isArray(value) && value.length;
		return getInputStatus(required, isValid, errors);
	};

	const handleChange = (newItems) => {
		if (!hasChange.current) hasChange.current = true;
		setSelectedOptions(newItems);
	};

	return (
		<FormGroup tag="fieldset" row>
			<Col {...labelColumn}>
				<Label for={name}>
					{required ? '*' : ''} {label}
				</Label>
			</Col>
			<Col {...inputColumn}>
				<BasicMultiSelect
					options={currentOptions}
					onChange={handleChange}
					keyField={inputConfig.key}
					labelField={inputConfig.print}
					disabled={!isEditable || !isEditing}
					className={getStatus()}
					variant="inline"
				/>
				<PrintInputError errors={errors} />
				<InputDescription description={relatedData ? relatedData.description : ''} />
			</Col>
		</FormGroup>
	);
};

export default TypeMultiSelectInline;
