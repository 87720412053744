import { SET_INIT, UPDATE_LIST_RELATION, UPDATE_FORM_RELATION, UPDATE_GLOBAL_PARAMS, SET_GPARAMS_READY } from './types';
import { writeSessionStorage } from '../../services/sessionStorageManagement';

export default function configReducer(state, action) {
	switch (action.type) {
		case SET_INIT:
			return { ...state, ...action.payload };

		case UPDATE_LIST_RELATION:
			return {
				...state,
				lists: {
					...state.lists,
					...action.payload
				}
			};

		case UPDATE_FORM_RELATION:
			return {
				...state,
				forms: {
					...state.forms,
					...action.payload
				}
			};
		case UPDATE_GLOBAL_PARAMS:
			let newGlobalParams = { ...state };
			if (action.mainParam) {
				if (action.payload) {
					const newMainParams = {
						...state.mainParams,
						...action.payload
					};
					newGlobalParams['mainParams'] = newMainParams;
					writeSessionStorage('mainParams', JSON.stringify(newMainParams), '{}');
				} else {
					newGlobalParams['mainParams'] = state.mainParams || {};
				}
			}
			return {
				...newGlobalParams,
				globalParams: action.replace
					? action.payload
					: action.payload
					? {
							...state.globalParams,
							...action.payload
					  }
					: {}
			};
		case SET_GPARAMS_READY:
			return {
				...state,
				gParamsIsReady: action.payload
			};
		default:
			throw new Error();
	}
}
