import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'reactstrap';

const ConfirmMarkerMove = ({ isOpen = false, toggleModal = () => {}, onConfirm = () => {}, onCancel = () => {} }) => {
	const [t] = useTranslation(['modal', 'app']);
	return (
		<Modal zIndex={2000} centered isOpen={isOpen} toggle={toggleModal} contentClassName="w-50">
			<div className="text-center p-5">
				<p className="font-weight-bold mt-4">{t('confirmChangePosition')}</p>
				<div className="pt-4">
					<Button onClick={onCancel} color="neutral-secondary" className="btn-pill mx-1">
						<span className="btn-wrapper--label">{t('cancel')}</span>
					</Button>
					<Button onClick={onConfirm} color="primary" className="btn-pill mx-1">
						<span className="btn-wrapper--label">{t('changePosition')}</span>
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default ConfirmMarkerMove;
