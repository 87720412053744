import React from 'react';
import TagComponent from './TagComponent';

const TagsGroup = ({items = [], onDelete = () => {}, labelField}) => {
	return (
		<div className="d-flex">
			{Array.isArray(items) &&
				items.map((item, i) => (
					<TagComponent key={`TagComponent-${i}`} data={item} labelField={labelField} onDelete={onDelete} />
				))}
		</div>
	);
};

export default TagsGroup;
