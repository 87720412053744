import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import ImgsWithLightBox from '../ImgsWithLightBox';
import authenticatedFetcher from '../../services/fetcher';
import ImageUpload from '../ImageUpload';
import DeleteModal from '../TableComponents/DeleteModal';
import { useTranslation } from 'react-i18next';
import BasicIcon from '../BasicIcon';
import BasicBtn from '../BasicBtn';
import { toastError } from '../../utils/ToastConfig';
import { fileUploader } from '../../services/commonServices';
import LoadingSpinner from '../LoadingSpinner';

// const dummyData = [
// 	{ path: 'https://picsum.photos/700/400?random' },
// 	{ path: 'https://picsum.photos/701/400?random' },
// 	{ path: 'https://picsum.photos/700/401?random' },
// 	{ path: 'https://picsum.photos/702/400?random' },
// 	{ path: 'https://picsum.photos/700/402?random' },
// 	{ path: 'https://picsum.photos/703/400?random' }
// ];

const ImatgesComponent = ({
	module,
	id,
	postParams,
	getParams,
	disabled,
	title,
	name = 'photo',
	endpoint = '/photos/',
	withInputs,
	options
}) => {
	const [images, setImages] = useState([]);
	const [imgToDelete, setImgToDelete] = useState();
	const [isDragHover, setIsDragHover] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [preloadImgs, setPreloadImgs] = useState([]);
	const [preloadImgsData, setPreloadImgsData] = useState({});
	const [t] = useTranslation('app');
	const dropSavingTimeout = useRef(null);

	useEffect(() => {
		if ((module && id) || typeof getParams === 'object') getImages();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!withInputs && preloadImgs.length) {
			clearTimeout(dropSavingTimeout.current);
			dropSavingTimeout.current = setTimeout(handleSavePreloads, 1500);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preloadImgs, preloadImgsData]);

	const getImages = async () => {
		setIsLoading(true);
		const imgs = await authenticatedFetcher({
			path: endpoint,
			urlParams: getParams || { [module]: id, "nitems": 1000 }
		});
		setIsLoading(false);
		if (Array.isArray(imgs))
			setImages(imgs.map((item) => ({ src: item[name], alt: item.title || item[name], ...item })));
	};

	const onAddImg = async (dataToSave, refresh = true) => {
		if (!((module && id) || typeof getParams === 'object') || disabled) return null;
		const res = await authenticatedFetcher({
			path: endpoint,
			body: postParams ? { ...postParams, ...dataToSave } : { ...dataToSave, [module]: id },
			method: 'POST'
		});
		if (res && refresh) getImages();
	};

	const handleDelete = async () => {
		if (!((module && id) || typeof getParams === 'object') || disabled) return null;
		const res = await authenticatedFetcher({
			path: `${endpoint}${imgToDelete.pk}/`,
			method: 'DELETE'
		});
		if (res) getImages();
		closeDeleteModal();
	};

	const onDelete = (imgData) => setImgToDelete(imgData);
	const closeDeleteModal = () => setImgToDelete(null);

	const handleCleanPreloads = () => {
		setPreloadImgs([]);
		setPreloadImgsData({});
	};

	const handleSavePreloads = async () => {
		if (withInputs && preloadImgs.some((item, idx) => item && !preloadImgsData[idx]?.title))
			return toastError(t('errors.requiredFields'));
		let request = [];
		preloadImgs.forEach((item, i) => {
			if (item)
				request.push(
					fileUploader({ target: { value: item.fileData, name } }, { fileType: 'img', getValue: false }).then(
						(res) => ({ ...res, id: i })
					)
				);
		});
		setIsLoading(true);
		const uploadedFiles = await Promise.all(request);
		request = [];
		uploadedFiles.forEach((item) =>
			request.push(
				onAddImg(
					withInputs
						? {
								...item,
								title: preloadImgsData[item.id].title,
								is_cover: !!preloadImgsData[item.id].isFrontPage
						  }
						: item,
					false
				)
			)
		);
		if (uploadedFiles) await Promise.all(request);
		setIsLoading(false);
		handleCleanPreloads();
		getImages();
	};

	const handleDeletePreloadItem = (index) => () => {
		setPreloadImgs((prev) => prev.map((item, i) => (i !== index ? item : null)));
		setPreloadImgsData((prev) => {
			let newData = { ...prev };
			delete newData[index];
			return newData;
		});
	};

	const handlePreloadImgDataChange =
		(idx, isCheck) =>
		({ target: { value, checked } }) =>
			setPreloadImgsData((prev) => ({
				...prev,
				[idx]: { ...prev[idx], [isCheck ? 'isFrontPage' : 'title']: isCheck ? checked : value }
			}));

	const removeDragData = (e) => {
		if (e.dataTransfer.items) e.dataTransfer.items.clear();
		else e.dataTransfer.clearData();
	};

	const handleAddDragFiles = (reader, file) => () =>
		setPreloadImgs((prev) => [...prev, { src: reader.result, fileData: file }]);

	const handleDragFiles = (e) => {
		if (disabled) return;
		e.preventDefault();

		if (e.dataTransfer.items) {
			for (let i = 0; i < e.dataTransfer.items.length; i++) {
				if (e.dataTransfer.items[i].kind === 'file') {
					const file = e.dataTransfer.items[i].getAsFile();
					const reader = new FileReader();
					reader.readAsDataURL(file);

					reader.onloadend = handleAddDragFiles(reader, file);
				}
			}
		} else {
			for (let i = 0; i < e.dataTransfer.files.length; i++) {
				const file = e.dataTransfer.files[i];
				const reader = new FileReader();
				reader.readAsDataURL(file);

				reader.onloadend = function (e) {
					setPreloadImgs((prev) => [...prev, { src: reader.result, fileData: file }]);
				};
			}
		}
		removeDragData(e);
		setIsDragHover(false);
	};

	const handleDragOver = (leave) => (e) => {
		e.preventDefault();
		if (leave && isDragHover && !disabled) return setIsDragHover(false);
		if (!isDragHover && !disabled) return setIsDragHover(true);
	};

	const renderHover = () => {
		if (isDragHover && !disabled)
			return (
				<div className="TypeGallery-dragHover">
					<span className="TypeGallery-dragHover-title">{t('imatgeComponent.dragFiles')}</span>
					<BasicIcon iconName="faDownload" />
				</div>
			);
	};

	const renderPreloadImg = () => {
		if (!preloadImgs.length) return renderHover();
		return (
			withInputs && (
				<div className="row TypeGallery-previewItems">
					{preloadImgs.map((item, i) => {
						if (!item) return null;
						const fileName = item.fileData.name;
						return (
							<div className="TypeGallery-previewItem col-12 col-md-6" key={fileName + i}>
								<img src={item.src} alt={item.fileData.name} className="TypeGallery-previewItem-img" />
								<div className="TypeGallery-previewItem-content">
									<div className="TypeGallery-previewItem-head">
										<span className="TypeGallery-previewItem-name">{fileName}</span>
										<span className="TypeGallery-previewItem-size">
											{(item.fileData.size / (1024 * 1024)).toFixed(2)}MB
										</span>
									</div>
									<div
										className={`TypeGallery-previewItem-body ${
											preloadImgsData[i] && preloadImgsData[i].title ? '' : 'error'
										}`}>
										<input
											type="text"
											placeholder={t('name')}
											onChange={handlePreloadImgDataChange(i)}
										/>{' '}
										(*)
										<br />
										<label for={fileName}>
											<input
												type="checkbox"
												id={fileName}
												onChange={handlePreloadImgDataChange(i, true)}
											/>{' '}
											¿Portada?
										</label>
									</div>
								</div>
								<button
									className="TypeGallery-previewItem-trashBtn"
									onClick={handleDeletePreloadItem(i)}>
									<BasicIcon iconName="faTrash" />
								</button>
							</div>
						);
					})}
					<div className="col-12 mb-1 d-flex justify-content-end">
						<BasicBtn text={t('cancel')} variant="mr-1" onClick={handleCleanPreloads} />
						<BasicBtn
							text={t('submit')}
							className="btn btn-sm btn-primary d-flex"
							onClick={handleSavePreloads}
						/>
					</div>
				</div>
			)
		);
	};

	const renderImgs = () => {
		if (!images.length) return t('imatgeComponent.noData');
		return <ImgsWithLightBox options={options} images={images} onDelete={onDelete} disabled={disabled} />;
	};

	return (
		<Card className="card-box mb-3">
			<div className="card-header py-3">
				<div className="card-header--title font-size-lg">
					{`${title || t('pictures')} (${images.length})`}
				</div>
				<LoadingSpinner show={isLoading} />
				<div className="card-header--actions">
					<ImageUpload onChange={onAddImg} name={name} noAutoSave disabled={disabled || isLoading} />
				</div>
			</div>
			<div className="card-body TypeGallery-body py-3" onDrop={handleDragFiles} onDragOver={handleDragOver()}>
				{renderPreloadImg()}
				{renderImgs()}
			</div>
			<DeleteModal isOpen={!!imgToDelete} toggle={closeDeleteModal} onDelete={handleDelete} />
		</Card>
	);
};

ImatgesComponent.propTypes = {
	onAddImage: PropTypes.func,
	module: PropTypes.string,
	id: PropTypes.any,
	title: PropTypes.string,
	name: PropTypes.string,
	endpoint: PropTypes.string,
	withInputs: PropTypes.bool
};

export default ImatgesComponent;
