import { useMap } from 'react-leaflet';
import L from 'leaflet';

const MapCenter = ({data}) => {
    const { geometry: { coordinates: [lat, lng] } } = data;
	const map = useMap();

	if (lat && lng)
		map.panTo(new L.LatLng(+lat, +lng));
	return null
}

export default MapCenter;