import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Proptypes from 'prop-types';
import { fileUploader } from '../../services/commonServices';

export default function ImageUpload(props) {
	const { id, name, className, onChange, config: { options = {} } = {}, value, noAutoSave, disabled } = props;
	const inputRef = useRef(null);

	useEffect(() => {
		if (onChange && typeof onChange === 'function' && name && !noAutoSave) onChange({ [name]: value });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const uploadImage = async (e) => {
		const fileUploaded = await fileUploader(e, { ...options, fileType: 'img' });
		if (!fileUploaded) return;
		inputRef.current.value = '';
		if (onChange && typeof onChange === 'function') onChange(fileUploaded);
	};

	return (
		<div className={`ImageUpload ${disabled ? 'disabled' : ''} ${className}`}>
			<input
				onChange={uploadImage}
				className="ImageUpload-input"
				type="file"
				id={`inputImage_${id}`}
				ref={inputRef}
				name={name}
				disabled={disabled}
			/>
			<FontAwesomeIcon icon={['fas', 'plus']} className="text-light" />
		</div>
	);
}

ImageUpload.propTypes = {
	className: Proptypes.string,
	id: Proptypes.string,
	name: Proptypes.string.isRequired,
	onChange: Proptypes.func
};

ImageUpload.defautlProps = {
	className: '',
	id: '',
	name: '',
	onChange: () => {}
};
