import React, { Fragment, useRef } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import { getInputStatus, searchFileType } from '../../../services/commonServices';
import PrintInputError from '../../InputUtils/PrintInputError';

import { Types } from '../../../utils/DynamicForm.config';
import InputDescription from '../../InputUtils/InputDescription';

const TypeFormInputFile = (props) => {
	const {
		id,
		label,
		name,
		value,
		editable,
		onChange,
		isEditing = true,
		required,
		options = {},
		errors,
		defaultValue,
		formData,
		useLabel = true,
		labelColumn = {
			xs: '6',
			md: '4'
		},
		inputColumn = {
			xs: '6',
			md: '8'
		},
		hideInputFunc,
		alwaysEditableFields,
		relatedData,
		onlyInput
	} = props;
	const inputRef = useRef(null);
	const hideInput = typeof hideInputFunc === 'function' ? hideInputFunc(formData) : false;
	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;

	const handleChange = async (inputEvent) => {
		const localRef = inputEvent.target;
		if (!(typeof onChange === 'function')) return;
		await onChange(inputEvent, { ...options, fileType: searchFileType(localRef.value) });
		if (inputRef.current) inputRef.current.value = '';
	};

	const handleDeleteUrl = ({ target: { checked } }) => {
		onChange({ target: { value: checked ? true : '', name: `${name}_delete` } });
	};

	const renderFile = () => {
		const fileType = searchFileType(value);
		switch (fileType) {
			case 'img':
				return (
					<a href={value} target="_blank" rel="noopener noreferrer">
						<img
							src={value}
							alt={`${label}`}
							className="pb-3"
							style={{
								maxHeight: '350px',
								maxWidth: '400px',
								height: 'fit-content',
								width: 'fit-content'
							}}
						/>
					</a>
				);
			case 'file':
				return (
					<div className="pb-3">
						<a href={value} target="_blank" rel="noopener noreferrer">{`Descargar: ${value}`}</a>
					</div>
				);
			default:
				return null;
		}
	};

	const renderLabel = () => {
		if (!useLabel) return null;

		return (
			<Col {...labelColumn}>
				<Label for={name}>
					{required ? '*' : ''} {label}
				</Label>
			</Col>
		);
	};

	const renderInput = () => (
		<Fragment>
			{renderFile()}
			{value === defaultValue && value ? (
				<div className="d-flex align-items-center">
					Eliminar este item
					<input type="checkbox" className="ml-1" onClick={handleDeleteUrl} />
				</div>
			) : null}
			<input
				id={id || name}
				ref={inputRef}
				type="file"
				name={name}
				disabled={!isEditable || !isEditing}
				onChange={handleChange}
				className={getInputStatus(required, value, errors)}
			/>
			<PrintInputError errors={errors} />
			<InputDescription description={relatedData ? relatedData.description : ''} />
		</Fragment>
	);

	if (hideInput) return null;

	if (onlyInput) return renderInput();

	return (
		<FormGroup tag="fieldset" row>
			{renderLabel()}
			<Col {...inputColumn}>{renderInput()}</Col>
		</FormGroup>
	);
};

TypeFormInputFile.propTypes = Types.inputType;

export default TypeFormInputFile;
