import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import PropTypes from 'prop-types';

export default function CustomLightBox(props) {
	const { images, isOpen, toggleLig, manual, activeIndex } = props;

	const [photoIndex, setPhotoIndex] = useState(0);

	useEffect(() => {
		if (manual) setPhotoIndex(activeIndex);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeIndex]);

	if (!isOpen) return null;

	return (
		<div className="LightboxCustom">
			<Lightbox
				wrapperClassName="LightboxCustom"
				imageTitle={images[photoIndex].title}
				mainSrc={images[photoIndex].src}
				nextSrc={images[(photoIndex + 1) % images.length].src}
				prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
				onCloseRequest={toggleLig}
				onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
				onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
			/>
		</div>
	);
}

CustomLightBox.propTypes = {
	manual: PropTypes.bool,
	activeIndex: PropTypes.number,
	images: PropTypes.arrayOf(
		PropTypes.shape({
			style: PropTypes.shape,
			className: PropTypes.string,
			alt: PropTypes.string,
			src: PropTypes.string,
			title: PropTypes.string
		})
	).isRequired
};
