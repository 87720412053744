import React, { useContext, useEffect, useRef, useState } from 'react';
import BasicIcon from '../BasicIcon';
import { ENDPOINT_NUM_ALARMS, PAGE_ALARMS, REFRESH_ALARMS } from '../../config';
import AppContext from '../../context';
import authenticatedFetcher from '../../services/fetcher';

const AlarmIcon = (props) => {
	const showAlarm = !!(ENDPOINT_NUM_ALARMS && PAGE_ALARMS);
	const [alarms, setAlarms] = useState(0);
	const refreshInterval = useRef(null);
	const {
		config: { mainParams, gParamsIsReady }
	} = useContext(AppContext);

	useEffect(() => {
		if (gParamsIsReady && showAlarm) getAlarms();
		if (REFRESH_ALARMS) refreshInterval.current = setInterval(getAlarms, 300000);
		return () => clearInterval(refreshInterval.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gParamsIsReady]);

	const getAlarms = () =>
		authenticatedFetcher({ path: ENDPOINT_NUM_ALARMS, method: 'GET', urlParams: mainParams })
			.then((res) => (typeof res === 'object' && res.num_alarms ? setAlarms(res.num_alarms) : null))
			.catch((e) => console.log('Error', e));

	return (
		showAlarm &&
		!!alarms && (
			<a className="AlarmIcon" href={PAGE_ALARMS}>
				<BasicIcon iconName="faBell" />
				<span>{alarms}</span>
			</a>
		)
	);
};

AlarmIcon.propTypes = {};

export default AlarmIcon;
