import React, { Fragment } from 'react';
import initSvg from './svg';
import './assets/base.scss';
import Layout from './Layout';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';

if (process.env.REACT_APP_FILE_CSS) require(process.env.REACT_APP_FILE_CSS);

initSvg();

const App = () => {
	return (
		<Fragment>
			<Helmet
				bodyAttributes={{
					class: `${process.env.REACT_APP_BODY_CLASS}`
				}}>
				<link id="favicon" rel="shortcut icon" href={process.env.REACT_APP_FAVICON || 'favicon.png'} />
			</Helmet>
			<ToastContainer />
			<Layout />
		</Fragment>
	);
};

export default App;
