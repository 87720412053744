import React, { useEffect, useRef, useState } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';

import formatDate from './TypeFormDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Types } from '../../../utils/DynamicForm.config';
import { getInputStatus } from '../../../services/commonServices';
import PrintInputError from '../../InputUtils/PrintInputError';
import DateMask from '../../MaskedInput/DateMask';
import InputDescription from '../../InputUtils/InputDescription';
import clsx from 'clsx';

const TypeFormDatetime = ({
	id,
	label,
	name,
	value,
	placeholder,
	editable = true,
	onChange,
	isEditing = true,
	required,
	errors,
	labelColumn = {
		xs: 6,
		md: 2
	},
	inputColumn = {
		xs: 6,
		md: 10
	},
	className,
	inputWrapperClassName,
	alwaysEditableFields,
	type,
	relatedData,
	onlyInput
}) => {
	const [startDate, setStartDate] = useState('');
	const datePickerRef = useRef();
	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;

	useEffect(() => {
		if (required || value) {
			const newDate = formatDate.fromApiToPicker(value);
			onChange({
				target: { name, value: value ? value : formatDate.fromPickerToApi(newDate, { withTime: true }) }
			});
			setStartDate(newDate);
		}
		if (document) {
			const dateMaskInput = document.getElementById(name);
			if (dateMaskInput)
				dateMaskInput.addEventListener('keydown', (e) => {
					if (e.isComposing || e.key === 'Tab' || e.key === 'Esc' || e.key === 'Escape')
						datePickerRef.current.setOpen(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnChange = (date) => {
		if (!isEditable || !isEditing) return;
		setStartDate(date);
		onChange({ target: { name, value: date ? formatDate.fromPickerToApi(date, { withTime: true }) : null } });
	};

	const renderInput = () => (
		<div className={clsx('Datetimepicker-wrapper', inputWrapperClassName)}>
			<DatePicker
				ref={datePickerRef}
				id={id || name}
				type="datetime-local"
				name={name}
				showTimeSelect
				dateFormat="dd/MM/yyyy HH:mm"
				timeFormat="HH:mm"
				timeCaption="Hora"
				value={startDate}
				selected={startDate}
				shouldCloseOnSelect
				onChange={handleOnChange}
				autocomplete="off"
				todayButton="Hoy"
				readOnly={!isEditable || !isEditing}
				className={`${getInputStatus(required, value, errors)}`}
				customInput={<DateMask type={type} />}
			/>
			<FontAwesomeIcon icon="calendar" title="magic" />
		</div>
	);

	if (onlyInput) return renderInput();

	return (
		<FormGroup tag="fieldset" className={className} row>
			<Col {...labelColumn}>
				<Label for={name}>
					{required ? '*' : ''}
					{label}
				</Label>
			</Col>
			<Col {...inputColumn}>
				{renderInput()}
				<PrintInputError errors={errors} />
				<InputDescription description={relatedData ? relatedData.description : ''} />
			</Col>
		</FormGroup>
	);
};

TypeFormDatetime.propTypes = Types.inputType;

export default TypeFormDatetime;
