import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import clsx from "clsx";

const buttonIconName = {
    CHECK: "check",
    EDIT: "pencil-alt",
};

const TypeFormDynamicButton = ({ onClick, iconName }) => {
    return (
        <Button
            className={clsx("dynamic-btn", {
                "dynamic-btn--check": iconName === buttonIconName.CHECK,
                "dynamic-btn--edit": iconName === buttonIconName.EDIT,
            })}
            size="sm"
            color="link"
            onClick={onClick}
        >
            <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={["fas", iconName]} />
            </span>
        </Button>
    );
};

TypeFormDynamicButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    iconName: PropTypes.string,
};

export default TypeFormDynamicButton;