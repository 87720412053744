import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import DynamicFormListLayout from './DynamicFormListLayout';
import DynamicFormTabLayout from './DynamicFormTabLayout';
import FormContext from '../../context/FormContext';

const DynamicFormLayout = ({
	config,
	onSubmit,
	data,
	withoutButtonsBar,
	withoutRelateds,
	formErrors,
	isCreating,
	filterMode,
	tabProcessingOptions = {},
	saveMassiveCreationData,
	isSearcher,
	loadingSubmit,
	resetAll = () => {}
}) => {
	const [allFormData, setAllFormData] = useState({});
	const [otherConfigs, setOtherConfigs] = useState({});
	const [refreshRelated, setRefreshRelated] = useState({});
	// const LayoutComponent = DynamicFormTabLayout;
	// TODO: uncomment to fix bug on list layout.
	// config.layout.type === 'tabs' ? DynamicFormTabLayout : DynamicFormListLayout;

	const saveRefreshRelated = (relatedName) => {
		setRefreshRelated((prev) => {
			if (prev[relatedName]) return { ...prev, [relatedName]: prev[relatedName] + 1 };
			return { ...prev, [relatedName]: 1 };
		});
	};

	const updateAllData = (newValues) => setAllFormData((prev) => ({ ...prev, ...newValues }));
	
	return (
		<FormContext.Provider
			value={{
				allFormData,
				updateAllData,
				formErrors,
				data,
				otherConfigs,
				setOtherConfigs,
				saveMassiveCreationData,
				isSearcher,
				refreshRelated,
				saveRefreshRelated,
				loadingSubmit
			}}>
			<DynamicFormTabLayout
				config={config}
				onSubmit={onSubmit}
				data={data}
				resetAll={resetAll}
				withoutButtonsBar={withoutButtonsBar}
				withoutRelateds={withoutRelateds}
				tabProcessingOptions={tabProcessingOptions}
				isCreating={isCreating}
				filterMode={filterMode}
			/>
		</FormContext.Provider>
	);
};

DynamicFormLayout.propTypes = {
	config: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	data: PropTypes.object
};

DynamicFormLayout.defaultProps = {
	data: {}
};

export default DynamicFormLayout;
