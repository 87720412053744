import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import parse from 'html-react-parser';

const TypeStatic = ({
	label,
	value,
	labelColumn = {
		xs: 6,
		md: 2
	},
	inputColumn = {
		xs: 6,
		md: 10
	}
}) => {
	return (
		<FormGroup tag="fieldset" row>
			<Col {...labelColumn}>
				<Label>{label}</Label>
			</Col>
			<Col {...inputColumn}>{value && parse(value)}</Col>
		</FormGroup>
	);
};

TypeStatic.propTypes = {};

export default TypeStatic;
