import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppProvider from './components/AppProvider';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import Login from './pages/Login/Login';
import { isThereUserInformation } from './services/fetcher';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import datePickerLang from './utils/languageDatePickerConfig';
import { Helmet } from 'react-helmet';
import { LANG, WITH_LANGUAGES } from './config';
import { readSessionStorage } from './services/sessionStorageManagement';

const Layout = (props) => {
	const [isLogged, setIsLogged] = useState(isThereUserInformation());

	const checkUserData = () => {
		setIsLogged((prev) => {
			const haveInfo = isThereUserInformation();
			return prev === haveInfo ? prev : haveInfo;
		});
	};

	useEffect(() => {
		//dynamic language change for DatePickers
		const lang = WITH_LANGUAGES ? readSessionStorage('i18nextLng') : LANG;
		registerLocale(lang, datePickerLang[lang] || datePickerLang.es);
		setDefaultLocale(lang);
	}, []);

	useEffect(() => {
		window.addEventListener('userInfoEvent', checkUserData);
		window.addEventListener('storage', checkUserData);
		return () => {
			window.removeEventListener('storage', checkUserData);
			window.removeEventListener('userInfoEvent', checkUserData);
		};
	}, []);

	if (!isLogged) return <Login />;
	return (
		<BrowserRouter>
			<AppProvider>
				<ScrollToTop>
					<Routes />
				</ScrollToTop>
			</AppProvider>
			<Helmet>
				<script src="https://kit.fontawesome.com/87131fda1b.js" crossorigin="anonymous"></script>
			</Helmet>
		</BrowserRouter>
	);
};

export default Layout;
