import React, { useEffect, useState } from 'react';
import L, { marker } from 'leaflet';
import { useMapEvents } from 'react-leaflet';
import DraggableMarker from './../DraggableMarker';
import closestIndexTo from 'date-fns/closestIndexTo/index.js';
import WithMarkerList from '../../hocs/WithMarkerList';

const MarkerLists = ({ markers = [], confirmMarkerChange, markerRadiusValue, loadingMarkers, ...props }) => {
	const [markerToOpen, setMarkerToOpen] = useState(null);
	const [currentMarkers, setCurrentMarkers] = useState([]);
	const [renderPath, setRenderPath] = useState(null);
	const [bounds, setBounds] = useState(null);

	const mapEventRef = useMapEvents({
		zoomend: ($event) => {
			const map = $event.target;
			//setBounds(map.getBounds()); // provoca que al hacer zoom desaparezcan algunos puntos
		}
	});
	const canvasRender = L.canvas({ padding: 0.5 });

	useEffect(() => {
		if (markers && markers?.length) setCurrentMarkers(markers);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [markers]);

	useEffect(() => {
		setMarkerToOpen(null);
		if (renderPath) renderPath.remove();
		loadMarkers(currentMarkers);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentMarkers, markerRadiusValue, bounds]);

	const setVolatileMarker = (markerData, renderPath) => async (markerRef) => {
		//await mapEventRef.removeLayer(markerRef.target); // provoca que se borren todos los puntos menos el marcado
		renderPath.remove();
		setMarkerToOpen(markerData);
		loadMarkers(currentMarkers.filter((item) => markerData.pk !== item.pk));
	};

	const inBounds = (_point, _bounds) => {
		if (_bounds) {
			const lng = (_point.lon - _bounds._northEast.lng) * (_point.lon - _bounds._southWest.lng) < 0;
			const lat = (_point.lat - _bounds._northEast.lat) * (_point.lat - _bounds._southWest.lat) < 0;
			return lng && lat;
		}
		return true;
	};

	const loadMarkers = (markers) => {
		for (let index = 0; index < markers.length; index++) {
			const item = markers[index];
			const [lon = null, lat = null] = item?.coordinates || [];

			if (lat && lon && inBounds({ lat, lon }, bounds)) {
				if (closestIndexTo === 0) mapEventRef.setView(new L.LatLng(lat, lon));

				if (item.isCluster)
					L.circleMarker([lat, lon], {
						renderer: canvasRender,
						radius: markerRadiusValue,
						color: item.point_color,
						fillColor: 'white',
						fill: true,
						fillOpacity: 1
					})
						.addTo(mapEventRef)
						.bindTooltip(`Clusters ${item.totalPoints} Actius ${item.points[0].name}`)
						.on('click', setVolatileMarker(item, canvasRender));
				else
					L.circleMarker([lat, lon], {
						renderer: canvasRender,
						radius: markerRadiusValue,
						color: item.point_color
					})
						.addTo(mapEventRef)
						.bindTooltip(item?.name || `item ${index}`)
						.on('click', setVolatileMarker(item, canvasRender));
			}
		}
		setRenderPath(canvasRender);
	};

	if (markerToOpen) {
		const [lon, lat] = markerToOpen.coordinates;
		return (
			<DraggableMarker
				position={[lat, lon]}
				markerData={markerToOpen}
				onChange={confirmMarkerChange}
				atLoadOpenPopup={true}
				isCluster={markerToOpen.isCluster}
				{...props}
			/>
		);
	}

	return (
		<>
			{loadingMarkers && loadingMarkers === true ? (
				<p
					style={{
						color: '#266447',
						fontSize: '20px',
						position: 'absolute',
						zIndex: 9999,
						bottom: '20px',
						right: '50%',
						textShadow: '3px 3px 3px #fff',
						background: '#ffffff',
						borderRadius: '44px',
						padding: '5px 20px'
					}}>
					{'Carregant actius...'}
				</p>
			) : (
				''
			)}
		</>
	);
};

export default WithMarkerList(MarkerLists);
