import React, { Suspense, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import RouteWithSubRoutes from './components/RouteWithSubRoutes';
import SuspenseLoading from './components/SuspenseLoading';
import ROUTES_CONFIG from './config/routes';
import AppContext from './context';
import { LeftSidebar } from './layout-blueprints';
import CustomMap from './pages/CustomMap/CustomMap';

const [homeRoute, EfpaReport, notFoundRoute, ...otherRoutes] = ROUTES_CONFIG;

const Routes = () => {
	const {
		config: { routes, dashboard }
	} = useContext(AppContext);

	return (
		<div>
			<AnimatePresence>
				<Suspense fallback={<SuspenseLoading />}>
					{/* <Switch> */}
					{/* <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} /> */}
					<LeftSidebar>
						<Switch>
							<Route
								{...homeRoute}
								render={(props) => <homeRoute.view {...props} widgets={dashboard} />}
							/>
							<Route {...EfpaReport} render={(props) => <EfpaReport.view {...props} />} />
							{otherRoutes?.map((route, index) => [
								<Route
									key={index}
									path={`${route.path}/Map/`}
									exact={route.exact}
									render={(props) => <CustomMap {...props} {...route} />}
								/>,
								<Route {...route} render={(props) => <route.view {...props} widgets={dashboard} />} />
							])}
							{routes.map((route, index) => RouteWithSubRoutes(route, index))}
							<Route
								{...notFoundRoute}
								render={(props) => <notFoundRoute.view {...props} widgets={dashboard} />}
							/>
						</Switch>
					</LeftSidebar>
					{/* </Switch> */}
				</Suspense>
			</AnimatePresence>
		</div>
	);
};

export default Routes;
