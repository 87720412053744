/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, Fragment} from 'react';
import convertPythonDictToJSON from '../../utils/convertPythonDictToJSON';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col} from "reactstrap";

const DadesTecniques = ({ dadesTecniques, isEdit, setFormData, setSuplementary }) => {
	const [inputValue, setinputValue] = useState({});
	const [objectData, setObjectData] = useState({});
	const [dataEntries, setDataEntries] = useState([]);
	useEffect(() => {
		if (dadesTecniques) {
			const data = convertPythonDictToJSON(dadesTecniques);
			if (data) {
				const allData = Object.entries(data);
				// Filter to get only the data without slash because this is for the config
				const dataFiltered = allData.filter(([key]) => !key.includes('__'));
				setDataEntries(dataFiltered);
				setObjectData(data);
			}
		}
	}, []);
	useEffect(() => {
		if (objectData) {
			setFormData((prev) => ({ ...prev, supplementary_data: JSON.stringify(objectData) }));
		}
	}, [objectData]);

	const handleToInputChange = (key, value) => {
		inputValue && setSuplementary(true);
		setinputValue({ key: key, value });
		setObjectData((prev) => ({ ...prev, [key]: value }));
	};

	return (
		<Fragment>
		<div className="row">
					{dataEntries.length > 0 &&
						dataEntries.map(([key, value]) => {
							const tdValue = value === 'None' ? '' : value;
							if (!key) return null;
							return (
								<div className="col-md-6" key={key}>
									<fieldset className="row form-group">
										<div className="w-100 row">
											<div className="text-sm-left text-md-right col-6 col-md-5">
												<label htmlFor="form-2-last-name"
													   className="w-100 font-weight-bold"> {objectData[`${key}__name`]}:
												</label>
											</div>
											<div className="col-6 col-md-7">
												<span>
									{!isEdit ? (
										<input name="name" disabled="disabled" type="text" id="form-2-name"
											   className="pt-0 form-control" value={tdValue} />
									) : (
										<input
											onChange={(e) => handleToInputChange(key, e.target.value)}
											className="input-value form-control"
											value={objectData[key]}
										/>
									)}
												</span>
											</div>
										</div>
									</fieldset>
								</div>
							);
						})}
		</div>
			</Fragment>
	);
	/*return (
		<div className="wrapper-tecniques">
			<table className="Feature-tableContent -tecniques dynamic-form">
				<tbody>
				<tr><td width="200px"><b>VENC. EPIS:</b></td><td>06/02/2024</td></tr><tr><td width="200px"><b>AUT MAQ. 1:</b></td><td></td></tr><tr><td width="200px"><b>AUT MAQ. 2:</b></td><td></td></tr><tr><td width="200px"><b>AUT MAQ. 3:</b></td><td></td></tr><tr><td width="200px"><b>AUT MAQ. 4:</b></td><td></td></tr><tr><td width="200px"><b>AUT .RIES ELECT:</b></td><td>OK</td></tr><tr><td width="200px"><b>APTO MED:</b></td><td>19/01/2023</td></tr><tr><td width="200px"><b>Art.18 (INF. Del puesto de trabajo):</b></td><td>26/03/2018</td></tr><tr><td width="200px"><b>Art.19 (Formación):</b></td><td>11/08/2010</td></tr><tr><td width="200px"><b>Formación    AT Y BT:</b></td><td>OK</td></tr><tr><td width="200px"><b>Formacion    Trab altura  2h  o 6 h o 8 H:</b></td><td>OK</td></tr><tr><td width="200px"><b>PRL_  INST   Reciclaje 4h:</b></td><td>OK</td></tr><tr><td width="200px"><b>Formacion Apart Elev. 6 h:</b></td><td>ok</td></tr><tr><td width="200px"><b>Formación  Electridad 20h:</b></td><td></td></tr><tr><td width="200px"><b>PRL 50 H o 60 H:</b></td><td></td></tr><tr><td width="200px"><b>IDC:</b></td><td>x</td></tr>
					{dataEntries.length > 0 &&
						dataEntries.map(([key, value]) => {
							const tdValue = value === 'None' ? '' : value;
							if (!key) return null;
							return (
								<tr key={key}>
									<td width="200px">
										<b>{objectData[`${key}__name`]}:</b>
									</td>
									{!isEdit ? (
										<td>{tdValue}</td>
									) : (
										<input
											onChange={(e) => handleToInputChange(key, e.target.value)}
											className="input-value form-control"
											value={objectData[key]}
										/>
									)}
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);*/
};

export default DadesTecniques;
