import React from 'react';
import PropTypes from 'prop-types';
import List from '../List/List';
import FormContext from '../../context/FormContext';

const ListBlock = (props) => {
	if (!props.withContext) return <List {...props} />;
	return <FormContext.Consumer>{(ctxProps) => <List {...props} formContext={ctxProps} />}</FormContext.Consumer>;
};

ListBlock.propTypes = {
	withContext: PropTypes.bool
};

export default ListBlock;
