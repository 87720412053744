import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import { getInputStatus } from '../../../services/commonServices';
import PrintInputError from '../../InputUtils/PrintInputError';

import { Types } from '../../../utils/DynamicForm.config';
import InputDescription from '../../InputUtils/InputDescription';

const TypeFormInputNumeric = ({
	label,
	name,
	value,
	editable,
	onChange,
	isEditing = true,
	required,
	errors,
	isOperations,
	labelColumn = {
		xs: 6,
		md: 2
	},
	inputColumn = {
		xs: 6,
		md: 10
	},
	alwaysEditableFields,
	relatedData
}) => {
	const [localValue, setLocalValue] = useState(value);

	useEffect(() => {
		onChange({ target: { value, name } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		onChange({ target: { value: localValue, name } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localValue]);
	useEffect(() => {
		setLocalValue((prev) => (prev === value ? prev : value));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const handleChange = (event) => {
		const {
			target: { value }
		} = event;
		setLocalValue(value);
	};

	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;

	return (
		<FormGroup tag="fieldset" row>
			<Col {...labelColumn}>
				<Label className="form-2-last-name">
					{required ? '*' : ''} {label}
				</Label>
			</Col>
			<Col {...inputColumn}>
				{isEditable && isEditing ? (
					<input
						name={name}
						value={localValue || ""}
						type="number"
						step="any"
						onChange={handleChange}
						className={`form-control input-text-wrapper validation ${getInputStatus(
							required,
							localValue,
							errors
						)}`}
					/>
				) : (
					<span>{localValue}</span>
				)}
				<PrintInputError errors={errors} />
				<InputDescription description={relatedData ? relatedData.description : ''} />
			</Col>
		</FormGroup>
	);
};

TypeFormInputNumeric.propTypes = Types.inputType;

export default TypeFormInputNumeric;
