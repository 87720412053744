import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const BasicToggle = (props) => {
	const { label = "", value, variant, onChange, name, withDefaultLabel } = props;

	return (
		<label class="BasicToggle">
			<input type="checkbox" checked={!!value} onChange={onChange} name={name} />
			<span className={clsx('slider', variant)}></span>
			<span className="BasicToggle-text">{withDefaultLabel ? `${label} ${(!!value ? 'ON' : 'OFF')}`.trim() : label}</span>
		</label>
	);
};

BasicToggle.propTypes = {
	label: PropTypes.string,
	value: PropTypes.bool,
	variant: PropTypes.string,
	onChange: PropTypes.func,
	withDefaultLabel: PropTypes.bool
};

export default BasicToggle;
