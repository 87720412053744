import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomLightBox from './CustomLightBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

const ImgsWithLightBox = (props) => {
	const {
		onClick,
		isOne,
		btnClassName,
		src = '',
		alt = '',
		images,
		manual,
		onDelete,
		disabled,
		noDelete,
		variant = '',
		options,
		...otherProps
	} = props;
	const [isOpen, setIsOpen] = useState(false);
	const [currentImg, setCurrentImg] = useState(0);
	const [imagesPreview, setImagesPreview] = useState([]);
	const [imagesLigthBox, setImagesLigthBox] = useState([]);

	useEffect(() => {
		setImagesPreview(options?.max_images ? images.slice(0, options.max_images) : images);
		setImagesLigthBox(images);
		return () => {};
	}, [images, options]);

	const toggle = () => setIsOpen((prev) => !prev);

	const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (onClick && typeof onClick === 'function') onClick(e);
		toggle();
	};

	const handleDelete = (data) => () => {
		if (onDelete && !disabled) onDelete(data);
	};

	const handleSelectImg = (imgIdx) => (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (onClick && typeof onClick === 'function') onClick(e);
		setCurrentImg(imgIdx);
		toggle();
	};

	return (
		<Fragment>
			<div className="Imatges-imagesContainer">
				{isOne ? (
					<div className="Imatges-imgWrapper">
						<a onClick={handleClick} href="#img-preview" className={btnClassName} role="button">
							<img {...otherProps} className={clsx('Imatges-image', variant)} alt={alt} src={src} />
						</a>
						{!noDelete && (
							<button
								type="button"
								className="Imatges-deleteBtn"
								onClick={handleDelete()}
								disabled={disabled}>
								<FontAwesomeIcon icon={['fas', 'trash']} />
							</button>
						)}
					</div>
				) : (
					Array.isArray(imagesPreview) &&
					imagesPreview.map((item, i) => (
						<div key={`ImgsWithLightBox-${i}-thumb`} className="Imatges-imgWrapper">
							<a
								onClick={handleSelectImg(i)}
								href="#img-preview"
								className={`Imatges-image ${btnClassName}`}
								role="button">
								<img
									className={clsx('Imatges-image', variant)}
									alt={item.alt}
									src={item.thumb || item.src}
								/>
							</a>
							{!noDelete && (
								<button
									type="button"
									className="Imatges-deleteBtn"
									onClick={handleDelete(item)}
									disabled={disabled}>
									<FontAwesomeIcon icon={['fas', 'trash']} />
								</button>
							)}
							<div className={`gallery-img-title`}>{item.title}</div>
						</div>
					))
				)}
			</div>
			<CustomLightBox
				toggleLig={toggle}
				isOpen={isOpen}
				images={!isOne ? imagesLigthBox : [props, props]}
				manual={!isOne}
				activeIndex={currentImg}
			/>
		</Fragment>
	);
};

ImgsWithLightBox.propTypes = {
	style: PropTypes.object,
	className: PropTypes.string,
	alt: PropTypes.string,
	src: PropTypes.string,
	title: PropTypes.string,
	variant: PropTypes.string,
	isOne: PropTypes.bool,
	disabled: PropTypes.bool,
	noDelete: PropTypes.bool,
	onDelete: PropTypes.func,
	images: PropTypes.arrayOf(
		PropTypes.shape({
			style: PropTypes.shape,
			className: PropTypes.string,
			alt: PropTypes.string,
			src: PropTypes.string
		})
	)
};

export default ImgsWithLightBox;
