import htmlParse from 'html-react-parser';
import React, { useState } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { searchParams } from '../../services/commonServices';
import authenticatedFetcher from '../../services/fetcher';
import { validateConditions } from '../../utils';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import CustomModals from '../CustomModals';
import LoadingSpinner from '../LoadingSpinner';
import BarButton from './BarButton';
import defaultConfig from './defaultConfig.json';
import { stringToStyleObj } from '../../services/commonServices';
import AlarmCreation from './AlarmCreation';
import BasicModal from '../BasicModal';
import CustomTestApis from '../CustomBlocks/CustomTestApis';
import CustomApiCommands from '../CustomBlocks/CustomApiCommands';
import CustomApiTablesConsole from '../CustomBlocks/CustomApiTablesConsole';

const ButtonsBar = (props) => {
	const {
		config: { buttons: configInfo = props.useDefaultConfig ? defaultConfig.buttons : {}, actions },
		id,
		title,
		formData = null,
		selectedItems = [],
		handleUnblockField = () => {},
		fetchData = () => {},
		saveRefreshRelated,
		formContext
	} = props;
	const history = useHistory();
	const [loadingBtn, setLoadingBtn] = useState(null);
	const [modalToOpen, setModalToOpen] = useState(null);
	const [confirmModalData, setConfirmModalData] = useState();
	const [alarmCreationData, setAlarmCreationData] = useState(null);
	const [consoleConfig, setConsoleConfig] = useState(null);
	const [apiCommandsConfig, setApiCommandsConfig] = useState(null);
	const [apiTablesConsoleConfig, setApiTablesConsoleConfig] = useState(null);
	const [t] = useTranslation(['app', 'map']);

	const handlePost = async (config) => {
		const { send_field_name, action, reload_related_tab } = config;
		const params = config.params ? config.params : {};
		const paramsFixed = config['params-fixed'] ? config['params-fixed'] : {};
		let apiRequest = {
			path: action,
			method: config.send_type,
			urlParams: { ...paramsFixed, ...params }
		};
		switch (config.type) {
			case 'send-selected':
				if (!(Array.isArray(selectedItems) && selectedItems.length))
					return toastError(t('app:buttonsBar.sendFieldNameError'));
				apiRequest['body'] = { [send_field_name]: selectedItems.join(',') };
				break;
			case 'send-actual':
				apiRequest['body'] = { [send_field_name]: formData ? formData[config.send_field] || id : id };
				break;
			case 'wait-response':
			default:
		}
		setLoadingBtn(config.title);
		const res = await authenticatedFetcher(apiRequest);
		setLoadingBtn(null);
		const { response, reload, reload_all_page, print_fixed_message_response, error_data, error } = res ? res : {};
		if (reload && typeof fetchData === 'function') fetchData();
		if (reload_all_page) document.location.reload();
		if (reload_related_tab && saveRefreshRelated) saveRefreshRelated(reload_related_tab);
		if (!error && response)
			return toastSuccess(() => htmlParse(response), { autoClose: !print_fixed_message_response });
		if (error_data.response) return toastError(error_data.response[0]);

		return toastError(
			error_data.selected ? t('app:buttonsBar.sendFieldNameError') : error_data.detail || t('app:saveError')
		);
	};

	const handleOpenModal = (config) => {
		if (config.send_custom_call) {
			setModalToOpen(config);
		}
	};

	const handleRedirect = (config) => {
		if (!Object.keys(config.params).length) return history.push(config.action);
		const params = formData ? searchParams(config, formData) : config.params;
		const newPath = Object.entries(params).reduce((acc, [field, value]) => {
			return `${acc}${acc === config.action ? '?' : '&'}${field}=${formData ? value : id}`;
		}, config.action);
		history.push(newPath);
	};

	const handleClick = (config = {}, fromModal) => {
		if (config.with_confirmation && !fromModal) return setConfirmModalData(config);
		switch (config.type) {
			case 'console':
				return setConsoleConfig(config);
			case 'api-commands':
				return setApiCommandsConfig(config);
			case 'api-tables-console':
				return setApiTablesConsoleConfig(config);
			case 'unblock-fields':
				return 'unblock-fields' in config ? handleUnblockField(config['unblock-fields']) : null;
			case 'custom-modal':
				return handleOpenModal(config);
			case 'custom-modal-selected':
				if (Array.isArray(selectedItems) && selectedItems.length)
					return handleOpenModal({
						...config,
						props: {
							titleText: t('modal:assignModal.title'),
							buttonText: t('modal:assignModal.submitBtn'),
							id: actions?.write?.configUri || id,
							fields: ['team'],
							selectedItems
						}
					});
				return toastError(t('app:buttonsBar.sendFieldNameError'));
			case 'custom-modal-selected-init-inspection':
				if (Array.isArray(selectedItems) && selectedItems.length)
					return handleOpenModal({
						...config,
						props: {
							titleText: t('modal:assignModal.titleDate'),
							buttonText:  t('modal:assignModal.submitBtnDate'),
							id: actions?.write?.configUri || id,
							fields: ['start_inspection'],
							selectedItems
						}
					});
				return toastError(t('app:buttonsBar.sendFieldNameError'));
			case 'send-selected':
			case 'send-actual':
			case 'wait-response':
				return handlePost(config);
			case 'custom-button-creation-alarm':
				return setAlarmCreationData(config);
			default:
				return handleRedirect(config);
		}
	};

	const handleCloseModal = () => {
		setAlarmCreationData(null);
		setModalToOpen(null);
		setConfirmModalData(null);
	};

	const handleCloseConsole = () => setConsoleConfig(null);

	const handleCloseApiCommands = () => setApiCommandsConfig(null);
	const handleCloseApiTablesConsole = () => setApiTablesConsoleConfig(null);

	const handleConfirmModal = () => {
		handleClick(confirmModalData, true);
		setConfirmModalData(null);
	};

	const renderDynamicModals = () => {
		if (!modalToOpen) return;
		const ModalToRender = CustomModals[modalToOpen.send_custom_call];
		const modalProps = modalToOpen.props ? modalToOpen.props : {};
		return ModalToRender ? (
			<ModalToRender
				{...modalToOpen}
				open
				toggle={handleCloseModal}
				fetchData={fetchData}
				formContext={formContext}
				{...modalProps}
			/>
		) : null;
	};

	const validateBtn = (config) => {
		if ('conditions' in config) return validateConditions(config.conditions, formData ? formData : {});
		return true;
	};
	const renderButtons = () =>
		configInfo.elements.map((buttonConfig, index) => (
			<Fragment key={`ButtonsBar-${index}`}>
				<LoadingSpinner show={buttonConfig.title === loadingBtn} />
				<BarButton
					config={buttonConfig}
					parseStyles={stringToStyleObj}
					onClick={handleClick}
					validateBtn={validateBtn}
				/>
			</Fragment>
		));

	return configInfo && Array.isArray(configInfo.elements) ? (
		<Fragment>
			<div className="mb-2 pt-2 pr-0 pb-2 pl-2 d-flex flex-md-row flex-column justify-content-between ButtonsBar-options">
				<span className="aling-middle ButtonsBar-title">{title || configInfo.title}</span>
				<div className="ButtonsBar-buttons">{renderButtons()}</div>
			</div>
			{renderDynamicModals()}
			{alarmCreationData && (
				<AlarmCreation
					{...alarmCreationData}
					setLoading={setLoadingBtn}
					formData={formData}
					toggle={handleCloseModal}
				/>
			)}
			{consoleConfig && (
				<CustomTestApis
					formContext={{ data: formData }}
					initialConfig={consoleConfig}
					toggle={handleCloseConsole}
					withoutSelect
				/>
			)}
			{apiCommandsConfig && (
				<CustomApiCommands
					formContext={{ data: formData }}
					initialConfig={apiCommandsConfig}
					toggle={handleCloseApiCommands}
					withoutSelect
				/>
			)}
			{apiTablesConsoleConfig && (
				<CustomApiTablesConsole
					formContext={{ data: formData }}
					initialConfig={apiTablesConsoleConfig}
					toggle={handleCloseApiTablesConsole}
					withoutSelect
				/>
			)}
			{confirmModalData && (
				<BasicModal
					onCancel={handleCloseModal}
					onConfirm={handleConfirmModal}
					title={confirmModalData.confirmation_text}
					description={confirmModalData.confirmation_subtext}
					toggle={handleCloseModal}
					confirmText={t('map:buttonNames.confirm')}
					isOpen
				/>
			)}
		</Fragment>
	) : null;
};

ButtonsBar.defaultProps = {
	config: {},
	id: 0,
	formData: null
};

export default ButtonsBar;
