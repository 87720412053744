import React, { useEffect, useState, memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'reactstrap';
import LoadingSpinner from '../LoadingSpinner';
import authenticatedFetcher from '../../services/fetcher';
import { useTranslation } from 'react-i18next';
import BasicIcon from '../BasicIcon';
import BasicToggle from '../BasicToggle';
import BasicToggleActivado from "../BasicToggle/indexActivado";
import DynamicFormInput from '../DynamicForm/DynamicFormInput';
import Message from '../Message';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import CustomTestApis from './CustomTestApis';

const CustomApiTablesConsole = (props) => {
	const {
		toggle,
		initialConfig: { title = {} },
		formContext: { data = {} }
	} = props;
	const [isLoading, setIsLoading] = useState(true);
	const [tablesData, setTablesData] = useState({});
	const [isChecked, setIsChecked] = useState({});
	const [itemsNewData, setItemsNewData] = useState({});
	const [errorText, setErrorText] = useState('');
	const [t] = useTranslation('app');

	const getTablesData = async () => {
		setIsLoading(true);
		authenticatedFetcher({ path: `/tele-assets/${data?.id || data?.pk}/get-api-data/?only_digital=1` }).then(
			(res) => {
				setIsLoading(false);
				if (res && !res.error) {
					setTablesData(res);
					let newChecks = { [res.telemando_var]: res.telemando };
					if (Array.isArray(res.data_digital))
						res.data_digital.forEach((item) => {
							newChecks[item.col2_var] = item.col2;
							newChecks[item.col2_activate_var] = item.col2_activate;
						});
					setIsChecked(newChecks);
				}
			}
		);
	};

	useEffect(() => {
		getTablesData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const handleChecksChange = (name_var, checked) => {
		setIsChecked((prev) => ({ ...prev, [name_var]: checked }));
	};


	const toggleCheck = ({ target: { name, checked } }) => {
		handleChecksChange(name, checked);
		onChangeItemData(name);
	};

	const saveAll = async (arrayOfReq = []) => {
		if (!Array.isArray(arrayOfReq) || !arrayOfReq?.length) return null;
		const all_data = {};
		arrayOfReq.forEach((item) => (all_data[item[0]] = item[1]));
		await authenticatedFetcher({
			path: `/tele-assets/${data.id || data.pk}/update-api-digital-data/`,
			method: 'POST',
			body: {
				all_dates: itemsNewData,
				all_data: all_data
			}
		}).then((res) => {
			if (res && res.error && res.status === 400) {
				toastError(res.error || res.error_data);
			} else {
				toastSuccess(Array.isArray(res?.response) && res.response.length && res.response[0]);
			}
		});
		// saveAll(allReq);
	};

	const handleCancelTableData = () => {
		if (typeof window !== 'undefined') window.location.reload();
	};
	const handleSaveTableData = () => {
		if (
			Object.entries(isChecked).some(
				([key, value]) =>
					!value && itemsNewData[key]
					&& (!itemsNewData[key].init_date || !itemsNewData[key].finish_date)
					&& isChecked[tablesData.telemando_var]
			)
		) {
			return setErrorText(t('customApiCommands.error'));
		}
		setErrorText('');
		saveAll(Object.entries(isChecked));
	};

	const onChangeItemData =
		(name_var) =>
		({ target: { value, name } }) =>
			setItemsNewData((prev) => ({
				...prev,
				[name_var]: prev[name_var] ? { ...prev[name_var], [name]: value } : { [name]: value }
			}));

	return (
		<Card className="card-box mb-3 mt-4 CustomApiCommands">
			{title && (
				<div className="card-header py-3">
					<div className="card-header--title font-size-lg">
						TEMPS REAL
						<LoadingSpinner show={isLoading} />
					</div>
					{!isLoading && tablesData.last_update && (
						<Fragment>
							Última lectura del PLC:
							<strong style={{'padding': '0 10px 0 5px'}}>
								{tablesData.last_update}</strong>
						</Fragment>
					)}
					{!isLoading && (
						<Button size="sm" className="mr-1" onClick={getTablesData}>
							<BasicIcon iconName="faRedo" />
						</Button>
					)}
					{toggle && (
						<Button size="sm" onClick={toggle}>
							{t('close')}
						</Button>
					)}
				</div>
			)}
			{tablesData.message_error && (
				<div className="card-body py-3 ">
					<div className="customApiCommands message message-error">{tablesData.message_error}</div>
				</div>
			)}

			{!tablesData.message_error && tablesData.data_digital && (
				<div className="card-body py-3 customApiCommands">
					<table>
						<thead>
							<tr>
								<th colSpan="5">
									<strong>SORTIDES DIGITALS</strong>
								</th>
							</tr>
							<tr>
								<th>
									{tablesData.telemando_print_buttons && (
										<BasicToggle
											label="Telecomandament"
											value={isChecked[tablesData.telemando_var]}
											name={tablesData.telemando_var}
											onChange={toggleCheck}
											withDefaultLabel
										/>
									)}
								</th>
								<th className="text-center">
									<BasicIcon iconName="faInfoCircle" />
								</th>
								<th className="text-center">
									<BasicIcon iconName="faUndo" />
								</th>
								<th className="text-center">
									<BasicIcon iconName="faRedo" />
								</th>
								<th className="text-center">
									<BasicIcon iconName="faTachometerAlt" />
								</th>
							</tr>
						</thead>
						<tbody>
							{tablesData.data_digital.map((item, i) => (
								<tr key={'row-' + i}>
									<td>
										{item.col1}
									</td>
									<td className="text-center">
										{(i === 0 && !tablesData.message_error && tablesData.telemando === 1) && (
											<Fragment>
												<BasicToggleActivado
													onChange={toggleCheck}
													name={item.col2_activate_var}
													value={isChecked[item.col2_activate_var]}
													withDefaultLabel
												/>{' '}{' '}
												<BasicToggle
													onChange={toggleCheck}
													name={item.col2_var}
													value={isChecked[item.col2_var]}
													withDefaultLabel
												/>
											</Fragment>
										)}
										{(i > 0 || (!tablesData.message_error && tablesData.telemando === 0)) && (
											<Fragment>
												{isChecked[item.col2_var] === 1 && (
													<Fragment>
														<label style={{'background-color':'#1E8449','width': '15px','height': '15px','margin': '0 auto'}}>
														</label>
													</Fragment>
												)}
												{isChecked[item.col2_var] === 0 && (
													<Fragment>
														<label style={{'background-color':'#797D7F','width': '15px','height': '15px','margin': '0 auto'}}>
														</label>
													</Fragment>
												)}
											</Fragment>
										)}
									</td>
									<td className="text-center">
										{(i === 0 && !tablesData.message_error && tablesData.telemando === 1) && (
											<Fragment>
												{isChecked[tablesData.telemando_var] ? (
													<DynamicFormInput
														type="time"
														editable={isChecked[tablesData.telemando_var]}
														name="init_date"
														onChange={onChangeItemData(item.col2_var)}
														value={item.col3}
														onlyInput
													/>
												) : (
													item.col3_label
												)}
											</Fragment>
										)}
										{(i > 0 || (!tablesData.message_error && tablesData.telemando === 0)) && (
											<Fragment>
												{item.col3_label}
											</Fragment>
										)}
									</td>
									<td className="text-center">
										{(i === 0 && !tablesData.message_error && tablesData.telemando === 1) && (
											<Fragment>
												{isChecked[tablesData.telemando_var] ? (
													<DynamicFormInput
														type="time"
														editable={isChecked[tablesData.telemando_var]}
														name="finish_date"
														onChange={onChangeItemData(item.col2_var)}
														value={item.col4}
														onlyInput
													/>
												) : (
													item.col4_label
												)}
											</Fragment>
										)}
										{(i > 0 || (!tablesData.message_error && tablesData.telemando === 0)) && (
											<Fragment>
												{item.col4_label}
											</Fragment>
										)}
									</td>
									<td className="text-center">{item.col5}</td>
								</tr>
							))}
						</tbody>
					</table>

					<div className="mt-3">
						<>
							<button type="button" className="submitButton mt-2 mr-2" onClick={handleSaveTableData}>
								{t('submit')}
							</button>
							<button type="button" className="submitButton mt-2" onClick={handleCancelTableData}>
								{t('cancel')}
							</button>
						</>
					</div>

					{errorText && <Message type="error">{errorText}</Message>}
				</div>
			)}

			{!tablesData.message_error && tablesData.data_network && (
				<div className="card-body py-3 customApiCommands">
					<table>
						<thead>
							<tr>
								<td colSpan="8">
									<strong>ANALITZADOR DE XARXES</strong>
								</td>
							</tr>
							<tr>
								<th></th>
								<th className="text-center">Tensió</th>
								<th className="text-center">Intensitat</th>
								<th className="text-center">P.Activa</th>
								<th className="text-center">P.Reactiva</th>
								<th className="text-center">FP</th>
								<th className="text-center">THDi</th>
								<th className="text-center">THDv</th>
							</tr>
						</thead>
						<tbody>
							{tablesData.data_network.map((item) => (
								<tr>
									<td>{item.col1}</td>
									<td className="text-center">{item.col2}</td>
									<td className="text-center">{item.col3}</td>
									<td className="text-center">{item.col4}</td>
									<td className="text-center">{item.col5}</td>
									<td className="text-center">{item.col6}</td>
									<td className="text-center">{item.col7}</td>
									<td className="text-center">{item.col8}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</Card>
	);
};

CustomApiTablesConsole.propTypes = {
	withoutSelect: PropTypes.bool,
	formContext: PropTypes.object,
	initialConfig: PropTypes.object,
	toggle: PropTypes.func
};

export default memo(CustomApiTablesConsole);
