import React, { forwardRef, useEffect, useState } from 'react';
import MaskedInput from 'react-maskedinput';
// import moment from 'moment';

const maskOptionsByType = {
	date: { mask: '11/11/1111', placeholder: 'dd/MM/yyyy', maskformat: 'dd/MM/yyyy' },
	datetime: { mask: '11/11/1111 11:11', placeholder: 'dd/MM/yyyy HH:mm', maskformat: 'dd/MM/yyyy HH:mm' },
	time: { mask: '11:11', placeholder: 'hh:mm', maskformat: 'hh:mm' }
};

const DateMask = forwardRef(({ value, onClick, onChange, name, type = 'date', readOnly, maskOptions }, ref) => {
	const [errorState, setError] = useState(false);
	const [localValue, setLocalValue] = useState(value);

	useEffect(() => {
		setLocalValue(value);
	}, [value]);

	const handleBlur = () => {
		if (errorState) setLocalValue(value);
		setError(false);
	};

	if (!type) return null;
	const maskedInputProps = maskOptions ? maskOptions : maskOptionsByType[type];

	const handleChange = (e) => {
		const {
			target: { value = '' }
		} = e;
		setLocalValue(value);
		if (value?.includes('_')) return setError(true);
		// if (value && moment(value, maskedInputProps.maskFormat).isValid()) {
		// }
		setError(false);
		onChange(e || "");
	};

	if(readOnly) return localValue

	return (
		<MaskedInput
			{...maskedInputProps}
			onChange={handleChange}
			autoComplete="off"
			onBlur={handleBlur}
			value={localValue}
			onClick={onClick}
			name={name}
			id={name}
			ref={ref}
		/>
	);
});

export default DateMask;
