import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import BasicIcon from '../BasicIcon';
import clsx from 'clsx';

const BasicBtn = ({
	className = 'btn btn-sm btn-dark d-flex',
	variant,
	type = 'button',
	onClick,
	disabled,
	icon,
	text,
	reverse,
	children
}) => {
	const renderContent = () => {
		if (children) return children;
		return (
			<Fragment>
				{icon && (
					<span className="btn-wrapper--icon">
						<BasicIcon iconName={icon} className="text-light"/>
					</span>
				)}
				{text && <span className="btn-wrapper--label text-light">{text}</span>}
			</Fragment>
		);
	};

	return (
		<button
			type={type}
			onClick={onClick}
			className={clsx(className,reverse && 'flex-row-reverse', variant)}
			disabled={disabled}>
			{renderContent()}
		</button>
	);
};

BasicBtn.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	text: PropTypes.string,
	reverse: PropTypes.bool,
	variant: PropTypes.string
};

export default BasicBtn;
