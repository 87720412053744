import TypeFormInputRadio from './TypeFormInputRadio';

import { Types } from '../../../utils/DynamicForm.config';

const TypeFormInputBoolean = (props) => {
	const items = [
		{
			text: 'Sí',
			value: 1
		},
		{
			text: 'No',
			value: 0
		}
	];
	return TypeFormInputRadio({
		...props,
		items
	});
};

TypeFormInputBoolean.propTypes = {
	...Types.inputType,
	items: Types.inputOptionsType.isRequired
};

export default TypeFormInputBoolean;
