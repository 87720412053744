import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const BasicSelect = (props) => {
	const {
		className,
		id,
		hasError,
		required,
		name = '',
		options = [],
		size = 'sm',
		printField = '',
		onChange,
		initialValue='',
		withoutBlankOption,
		stopListening,
		placeholder,
		keyField,
		selectTheFirst,
		saveInitialValue,
		searchInitialValueFunc,
		otherLabel,
		children,
		...inputProps
	} = props;
	const [selectedIdx, setSelectedIdx] = useState();
	const [heardInitialValue, setHeardInitialValue] = useState();

	useEffect(() => {
		if (Array.isArray(options) && options.length && stopListening !== 'all') {
			if (initialValue && (stopListening !== 'initialValue' || !heardInitialValue)) {
				setHeardInitialValue(true);
				setSelectedIdx((prev) => {
					const index = options.findIndex((item) =>
						searchInitialValueFunc
							? searchInitialValueFunc(item, initialValue)
							: keyField
							? item[keyField] === initialValue
							: item === initialValue
					);
					if (saveInitialValue) {
						handleChange({ target: { value: index } });
						return prev;
					}
					return index;
				});
			} else if (selectTheFirst) handleChange({ target: { value: 0 } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options, initialValue]);

	const handleChange = ({ target: { value: strValue } }) => {
		const value = parseInt(strValue);
		setSelectedIdx(() => value);
		if (!onChange || typeof onChange !== 'function') return null;
		if (value >= 0) {
			if (keyField) {
				const selectedItem = options[value];
				return onChange({ target: { value: selectedItem[keyField], name } }, props);
			}
			return onChange({ target: { value: options[value], name } }, props);
		}
		return onChange({ target: { value: null, name } }, props);
	};

	return (
		<select
			{...inputProps}
			onBlur={null}
			onChange={handleChange}
			id={id || name}
			value={selectedIdx}
			name={name}
			className={clsx(`form-control form-control-${size} BasicSelect`, hasError && 'error', className)}>
			{placeholder && !selectTheFirst && (
				<option id="placeholder" value="novalid">
					{placeholder}
				</option>
			)}
			{!withoutBlankOption && <option value={-1}>{otherLabel || ''}</option>}
			{options &&
				options.map((item, i) => (
					<option
						value={
							keyField ? (item[keyField] || typeof item[keyField] === 'boolean' ? i : (i + 2) * -1) : i
						}
						key={`select-${name}-option-${i}`}>
						{printField ? item[printField] : item}
					</option>
				))}
		</select>
	);
};

BasicSelect.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	size: PropTypes.string,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	required: PropTypes.bool,
	hasError: PropTypes.bool,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	options: PropTypes.array,
	printField: PropTypes.string,
	keyField: PropTypes.string,
	withoutBlankOption: PropTypes.bool,
	initialValue: PropTypes.any,
	otherLabel: PropTypes.string,
	selectTheFirst: PropTypes.bool,
	saveInitialValue: PropTypes.bool,
	searchInitialValueFunc: PropTypes.func,
	stopListening: PropTypes.oneOf(['initialValue', 'all', false])
};

export default memo(BasicSelect);
