import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const TagComponent = ({ data = {}, labelField = 'label_tag', onDelete = () => {} }) => {
	return (
		<div className="TagComponent">
			<div className="TagComponent-label">{data[labelField]}</div>
			<button className="TagComponent-deleteBtn" onClick={() => onDelete(data)}>
				<FontAwesomeIcon icon={['fas', 'times']} />
			</button>
		</div>
	);
};

export default TagComponent;
