import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from '@fortawesome/fontawesome-free';
import * as SolidIcons from '@fortawesome/free-solid-svg-icons';
import * as BrandsIcons from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BasicIcon = ({ iconName, className}) => {
  return (
    <FontAwesomeIcon
      icon={Icons[iconName] || SolidIcons[iconName] || BrandsIcons[iconName]}
      className={className}
    />
  );
};

BasicIcon.propTypes = {
  iconName: PropTypes.string,
  className: PropTypes.string,
};

export default BasicIcon;
