import React from 'react';
import TableHeader from './DocumentsTableHead';

export default function DocumentsTable(props) {
	return (
		<div className={`table-responsive`} style={props.wrapperStyle}>
			<table className={`DocumentsTable ${props.className}`}>
				<TableHeader classNameHead={props.classNameHead} heads={props.heads} />
				<tbody className={`DocumentsTable-body ${props.classNameBody}`}>{props.children}</tbody>
			</table>
		</div>
	);
}

DocumentsTable.defaultProps = {
	className: ''
};
