import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { Types } from '../../../utils/DynamicForm.config';
import InputDescription from '../../InputUtils/InputDescription';

const TypeFormCheckbox = ({
	id,
	label,
	name,
	value,
	editable = true,
	onChange,
	isEditing = true,
	alwaysEditableFields,
	relatedData
}) => {
	//const [state, setstate] = useState(initialState)
	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;
	return (
		<FormGroup className="d-block mb-3" check>
			<Label for={name} check>
				<Input
					id={id || name}
					name={name}
					type="checkbox"
					checked={value}
					onChange={onChange}
					disabled={!isEditable || !isEditing}
					className="mt-0"
				/>
				{label}
			</Label>
			<InputDescription description={relatedData ? relatedData.description : ''} />
		</FormGroup>
	);
};

TypeFormCheckbox.propTypes = Types.inputType;

export default TypeFormCheckbox;
