import { inputTypesMap } from './DynamicForm.config';

const defaultFormDataFromInputs = (inputs, isCreating) => {
	return inputs.reduce((formData, input) => {
		if (!input) return {};
		if (isCreating && !input.value) return formData;
		if (input.type !== 'custom' || input.title)
			return {
				...formData,
				[input.name]: input.value
			};
		return formData;
	}, {});
};

const getDefaultDataFromTabsContent = (tabsContent, isCreating) => {
	let defaultData = {};

	for (let i = 0; i < tabsContent.length; i += 1) {
		const { allFields } = tabsContent[i];
		defaultData = {
			...defaultData,
			...defaultFormDataFromInputs(allFields, isCreating)
		};
	}

	return defaultData;
};

const getInputValue = (target) => {
	switch (target.type) {
		case inputTypesMap.CHECKBOX:
			return target.checked;
		case inputTypesMap.FILE:
			return target.files[0];
		default:
			return target.value;
	}
};

export { defaultFormDataFromInputs, getDefaultDataFromTabsContent, getInputValue };
