import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Row } from 'reactstrap';
import useSelectFormListener from '../../../hooks/useSelectFormListener';
import { getInputStatus, getItems } from '../../../services/commonServices';
import authenticatedFetcher from '../../../services/fetcher';
import BasicBtn from '../../BasicBtn';
import BasicMultiSelect from '../../BasicMultiSelect';
import InputDescription from '../../InputUtils/InputDescription';
import PrintInputError from '../../InputUtils/PrintInputError';

const Multiselect = (props) => {
	const {
		label,
		value,
		name,
		options: inputConfig = { key: 'value', print: 'label' },
		onChange,
		required,
		errors,
		isEditing = true,
		globalParams = {},
		labelColumn = {
			xs: 6,
			md: 2
		},
		inputColumn = {
			xs: 6,
			md: 10
		},
		alwaysEditableFields,
		editable,
		multiselect = true,
		relatedData,
		basicMode,
		items,
		selectedItems,
		withoutFetch
	} = props;
	const [currentOptions, setCurrentOptions] = useState(items || []);
	const [selectedOptions, setSelectedOptions] = useState(selectedItems || []);
	const [shortlisted, setShortlisted] = useState([]);
	const [selectedList, setSelectedList] = useState();
	const [isMounted, setIsMounted] = useState(false);
	const [customParams, isHearing] = useSelectFormListener(props);
	const [t] = useTranslation("app")
	const hasChange = useRef(false);

	useEffect(() => {
		if (!isMounted && !isHearing && !basicMode && !withoutFetch) handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (items && basicMode) setCurrentOptions((prev) => (prev !== items ? items : prev));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	useEffect(() => {
		if (Object.values(customParams).length && !basicMode && !withoutFetch) handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customParams]);

	useEffect(() => {
		if (Array.isArray(selectedOptions) && hasChange.current && typeof onChange === 'function')
			onChange({ target: { name, value: selectedOptions.map((item) => item[inputConfig.key]) } });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOptions]);

	const handleSearch = async () => {
		const defaultValues = value && Array.isArray(value) ? value : [];
		const params = inputConfig.params ? inputConfig.params : {};
		const paramsFixed = inputConfig['params-fixed'] ? inputConfig['params-fixed'] : {};
		const data = await authenticatedFetcher({
			path: `${inputConfig.endpoint}`,
			urlParams: { ...params, ...paramsFixed, ...globalParams, ...customParams, from_select: 1 }
		});
		if (data.error || !data) return setCurrentOptions([]);
		const items = getItems(data);
		const newData = items
			? items.reduce(
					(acc, item) => {
						if (defaultValues.some((selectedItem) => selectedItem === item.pk)) {
							return {
								...acc,
								selected: [...acc.selected, item],
								options: multiselect ? acc.options : [...acc.options, item]
							};
						} else {
							return {
								...acc,
								options: [...acc.options, item]
							};
						}
					},
					{ options: [], selected: [] }
			  )
			: null;
		setIsMounted(true);
		if (newData) {
			setCurrentOptions(newData.options);
			setSelectedOptions(newData.selected);
		}
	};

	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;

	const resetShortedList = () => {
		setSelectedList(null);
		setShortlisted([]);
	};

	const handleChange = () => {
		if (!isEditing || !isEditable) return;
		if (!hasChange.current) hasChange.current = true;
		setSelectedOptions((prevState) => {
			const newState = [...prevState, ...shortlisted];
			return newState;
		});
		setCurrentOptions((prevState) =>
			prevState.filter((opt) => !shortlisted.some((sel) => opt[inputConfig.key] === sel[inputConfig.key]))
		);
		resetShortedList();
	};

	const getStatus = () => {
		const isValid = Array.isArray(value) && value.length;
		return getInputStatus(required, isValid, errors);
	};

	const handleChangeReturn = () => {
		if (!isEditing || !isEditable) return;
		if (!hasChange.current) hasChange.current = true;
		setSelectedOptions((prevState) => {
			const newState = prevState.filter(
				(opt) => !shortlisted.some((sel) => opt[inputConfig.key] === sel[inputConfig.key])
			);
			return newState;
		});
		setCurrentOptions((prevState) => [...prevState, ...shortlisted]);
		resetShortedList();
	};

	const handleSelect = (dir) => (newItems) => {
		if (!newItems.length) setSelectedList(null);
		else setSelectedList((prev) => (prev === dir ? prev : dir));
		setShortlisted(newItems);
	};

	const handleNormalSelect = ({ target: { value } }) => {
		const newValue = parseInt(value);
		setSelectedOptions([newValue ? currentOptions[newValue - 1] : { [inputConfig.key]: 0 }]);
	};

	const renderInput = () =>
		isEditing && isEditable ? (
			<Row>
				<Col xs="5" md="5">
					<BasicMultiSelect
						options={currentOptions}
						disabled={selectedList === 'right'}
						onChange={handleSelect('left')}
						keyField={inputConfig.key}
						labelField={inputConfig.print}
						title={`${currentOptions.length} ${t("options")}`}
					/>
				</Col>
				<Col xs="2" md="2">
					<div className="d-flex flex-column align-items-center h-100">
						<BasicBtn
							icon="faArrowRight"
							variant="mt-auto"
							onClick={handleChange}
							disabled={selectedList === 'right'}
						/>
						<BasicBtn
							icon="faArrowLeft"
							variant="my-auto"
							onClick={handleChangeReturn}
							disabled={selectedList === 'left'}
						/>
					</div>
				</Col>
				<Col xs="5" md="5">
					<BasicMultiSelect
						options={selectedOptions}
						disabled={selectedList === 'left'}
						onChange={handleSelect('right')}
						keyField={inputConfig.key}
						labelField={inputConfig.print}
						className={getStatus()}
						title={`${selectedOptions.length} ${t("selected")}`}
					/>
					<PrintInputError errors={errors} />
					<InputDescription description={relatedData ? relatedData.description : ''} />
				</Col>
			</Row>
		) : (
			<ul className="text-uppercase">
				{selectedOptions &&
					selectedOptions.map((option, i) => {
						return (
							<li key={`noEditable-selectedOption->${i}`} className="d-block">
								{option[inputConfig.print]}
							</li>
						);
					})}
			</ul>
		);

	if (basicMode) return renderInput();

	return (
		<FormGroup tag="fieldset" row>
			<Col {...labelColumn}>
				<Label for={name}>
					{required ? '*' : ''} {label}
				</Label>
			</Col>
			<Col {...inputColumn}>
				{multiselect ? (
					renderInput()
				) : (
					<select
						name="options"
						disabled={!isEditable || !editable}
						className={`form-control ${getInputStatus(required, value, errors)}`}
						onChange={handleNormalSelect}>
						<option value={0}></option>
						{currentOptions &&
							currentOptions.map((option, i) => {
								return (
									<option
										key={`${name}-options-${i}`}
										value={i + 1}
										selected={selectedOptions.some(
											(item) => item[inputConfig.key] === option[inputConfig.key]
										)}>
										{option[inputConfig.print]}
									</option>
								);
							})}
					</select>
				)}
			</Col>
		</FormGroup>
	);
};

export default Multiselect;
