import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import WithListConfig from '../../hocs/WithListConfig';
import fetcher from '../../services/fetcher';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import ContentMenuActions from '../contentMenuActions';
import cx from 'classnames';

const PlusList = ({ rowsConfig = {}, columns = [], config = {}, ...otherProps }) => {
	const { params } = otherProps;
	const {
		actions: { read: readConfig, delete: deleteConfig },
		fields = [],
		rows: rowInfo = {},
		primary_key_field: primaryKey
	} = config;
	const [t] = useTranslation('app');
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const history = useHistory();

	const initColumn = rowsConfig.checkbox ? 1 : 0;
	const availableColumns = columns.length;
	const fieldsLength = fields ? fields.length : 0;

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchDataFromEdit = (pathTo) => {
		history.push(pathTo);
	};

	const handleDoubleClick = (path) => () => fetchDataFromEdit(path);

	const handleTrashClick = (itemId) => () => {
		if (!deleteConfig) return;
		return fetcher({ path: `${deleteConfig.uri}${itemId}`, method: deleteConfig.method }).then((res) => {
			if ('error' in res && res.error !== 'no-content') {
				toastError(res.error);
				return false;
			}
			fetchData();
			toastSuccess(t('save'));
			return true;
		});
	};

	const handleLupaClicked = (pathTo) => () => {
		fetchDataFromEdit(pathTo);
		localStorage.setItem('isLupaClicked', true);
	};

	const fetchData = () => {
		if (!readConfig) return;
		fetcher({ path: readConfig.uri, urlParams: params })
			.then((res) => {
				return setData(res);
			})
			.then(() => setLoading(false));
	};

	const renderColumn = (rowData, itemToShow) => {
		const classes = rowData[`${itemToShow.field}__class`] ? rowData[`${itemToShow.field}__class`] : '';

		if (typeof rowData[itemToShow.field] === 'boolean') {
			return (
				<span
					className={cx('status-from-buttons-in-table-item-list', classes)}
					dangerouslySetInnerHTML={{
						__html: rowData[itemToShow.field] === true ? 'Sí' : 'No'
					}}
				/>
			);
		}

		return (
			<span
				className={cx('status-from-buttons-in-table-item-list ', classes)}
				dangerouslySetInnerHTML={{
					__html: rowData[itemToShow.field]
				}}
			/>
		)
	}

	const renderTds = (rowData) => {
		const mainKey = rowData[primaryKey];
		const detailPath = rowData.custom_page ? `/${rowData.custom_page}/${mainKey}` : `${config.uri}${mainKey}`;
		return fields && Array.isArray(fields) ? (
			<>
				{fields.map((itemToShow, i) => {
					return (
						<td onDoubleClick={handleDoubleClick(detailPath)}>
							{renderColumn(rowData, itemToShow)}
						</td>
					);
				})}
				{availableColumns - fieldsLength > 0 && <td colSpan={availableColumns - fieldsLength}></td>}
				{rowInfo.actions ? (
					<td className="text-center col-outer-tools button-menu-content">
						<div className="menu-actions-container">
							<ContentMenuActions
								add
								trash
								lapiz
								edit
								editItem
								handleLupaClick={handleLupaClicked(detailPath)}
								detailPath={detailPath}
								handleTrashClick={handleTrashClick(mainKey)}
							/>
						</div>
					</td>
				) : null}
			</>
		) : null;
	};

	return !loading && Array.isArray(data) && data.length ? (
		data.map((row) => (
			<tr>
				{initColumn && <td colSpan={initColumn} />}
				{renderTds(row)}
			</tr>
		))
	) : (
		<tr>
			<td colSpan={availableColumns + initColumn + 1} className="text-center">
				{loading ? 'loading...' : 'Sin resultados.'}
			</td>
		</tr>
	);
};

export default WithListConfig(PlusList, { loadingElement: <tr>...</tr> });
