import React from 'react';
import PropTypes from 'prop-types';
import CustomElement from '../../pages/CustomElement/CustomElement';
import CustomElementTelegestio from '../../pages/CustomElementTelegestio/CustomElementTelegestio';
import FormContext from '../../context/FormContext';
import CustomTestApis from '../CustomBlocks/CustomTestApis';

const pages = {
	'custom-element': CustomElement,
	'custom-element-telegestio': CustomElementTelegestio,
	'custom-test-apis': CustomTestApis
};
const CustomPagesBlock = (props) => {
	const CustomPage = pages[props.id];
	if (!CustomPage) return null;
	return props.field_listen || props.id === 'custom-test-apis' ? (
		<FormContext.Consumer>
			{(ctxProps) => <CustomPage initialConfig={props} formContext={ctxProps} className={props.className} />}
		</FormContext.Consumer>
	) : (
		<CustomPage initialConfig={props} className={props.className} />
	);
};

CustomPagesBlock.propTypes = {
	endpoints: PropTypes.shape({ list: PropTypes.string, data: PropTypes.string, id: PropTypes.string }),
	data: PropTypes.string,
	id: PropTypes.string.isRequired,
	list: PropTypes.string,
	field_listen: PropTypes.string,
	in_creation_form: PropTypes.bool,
	withFormContext: PropTypes.bool
};

export default CustomPagesBlock;
