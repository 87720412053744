import React, { useEffect } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { getInputStatus } from '../../../services/commonServices';
import PrintInputError from '../../InputUtils/PrintInputError';
import PropTypes from 'prop-types';
import { Types } from '../../../utils/DynamicForm.config';
import InputDescription from '../../InputUtils/InputDescription';
import clsx from 'clsx';

const TypeFormInputRadio = ({
	label,
	name,
	items: options,
	value = 0,
	editable,
	onChange,
	isEditing = true,
	required,
	errors,
	labelColumn = {
		xs: '6',
		md: '4'
	},
	inputColumn = {
		xs: '6',
		md: '8'
	},
	alwaysEditableFields,
	relatedData,
	onlyInput,
	inputClass
}) => {
	useEffect(() => {
		onChange({ target: { name, value } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;

	const handleChange = (e) => typeof onChange === 'function' && onChange(e, { type: 'bool' });

	const renderOptions = () =>
		options.map((option) => {
			return (
				<FormGroup key={option.text} check>
					<Label check className={clsx(inputClass, getInputStatus(required, value, errors))}>
						<Input
							name={name}
							checked={Number(value) === option.value}
							value={option.value}
							type="radio"
							onChange={handleChange}
							disabled={!isEditable || !isEditing}
							className="mt-0"
						/>
						{option.text}
					</Label>
				</FormGroup>
			);
		});

	if (onlyInput) return renderOptions();

	return (
		<FormGroup tag="fieldset" row>
			<Col {...labelColumn}>
				<label className="col-form-label">
					{required ? '*' : ''} {label}
				</label>
			</Col>
			<Col {...inputColumn}>
				{renderOptions()}
				<PrintInputError errors={errors} />
				<InputDescription description={relatedData ? relatedData.description : ''} />
			</Col>
		</FormGroup>
	);
};

TypeFormInputRadio.propTypes = {
	...Types.inputType,
	items: Types.inputOptionsType.isRequired,
	onlyInput: PropTypes.bool,
	inputClass: PropTypes.string
};

export default TypeFormInputRadio;
