import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Message = (props) => {
	const { type = 'default', className = '', text = '', strong } = props;
	return (
		<div className={clsx('Message', `Message-${type}`, strong && 'strong', 'EfpaLabel-font', className)}>
			{text ? text : props.children}
		</div>
	);
};

Message.propTypes = {
	type: PropTypes.oneOf(['error', 'success', 'default', 'info']),
	className: PropTypes.string,
	text: PropTypes.string,
	strong: PropTypes.bool
};

export default Message;
