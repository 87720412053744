import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ImageUpload from '../../ImageUpload';
import ImgsWithLightBox from '../../ImgsWithLightBox';

let saveChangeTimeout = null;

const ImatgesTab = ({ title, saveChange, disabled, config, id, field = 'photo_url' }) => {
	const [images, setImages] = useState([]);
	const [t] = useTranslation('app');

	useEffect(() => {
		clearTimeout(saveChangeTimeout);
		saveChangeTimeout = setTimeout(() => {
			const [relatedField] = Object.keys(config.params_upload);
			if (typeof saveChange === 'function')
				saveChange(
					images.map((item) => item.data),
					{
						actions: [{ key: 'write', uri: config.endpoint }],
						extraParams: config.params_upload_fixed
					},
					relatedField,
					id
				);
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [images]);

	const onAddImg = async (dataToSave) => {
		if (disabled) return null;
		setImages((prev) => [
			...prev,
			{ src: dataToSave[field], alt: dataToSave[field], data: dataToSave }
		]);
	};

	const onDelete = (imgData) => setImages((prev) => prev.filter((img) => img.src !== imgData.src));

	const renderImgs = () => {
		if (!images.length) return t('imatgeComponent.noData');
		return <ImgsWithLightBox key={images.length} images={images} onDelete={onDelete} disabled={disabled} />;
	};

	return (
		<Fragment>
			<div className="card-header py-3">
				<div className="card-header--title font-size-lg d-flex justify-content-between">{`${
					title || t('pictures')
				} (${images.length} ${t('pictures')})`}</div>
				<div className="card-header--actions">
					<ImageUpload onChange={onAddImg} name={field} noAutoSave disabled={disabled} />
				</div>
			</div>
			<div className="card-body py-3">{renderImgs()}</div>
		</Fragment>
	);
};

ImatgesTab.propTypes = {
	title: PropTypes.string,
	saveChange: PropTypes.func,
	disabled: PropTypes.bool,
	config: PropTypes.shape
};

export default ImatgesTab;
