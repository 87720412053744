import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';
import { stringToStyleObj } from '../services/commonServices';
import ListContext from './List/ListContext';
import TableItemTd from './TableComponents/TableItemTd';

const TableItemList = (props) => {
	const {
		id,
		config = {},
		columns = [],
		children,
		rowsConfig,
		mainKey,
		rowData,
		handleMapClick,
		detailPath,
		redirectToDetail,
		handleColumnCheck,
		handleChangeInput,
		edit = true,
		showActions
	} = props;
	const { checkbox } = rowsConfig;
	const { row__class, row__style } = config;
	const [isChecked, setIsChecked] = useState(false);

	const { handleToSaveAllCheckInputs, checkFromInput } = useContext(ListContext);

	useEffect(() => {
		setIsChecked(checkFromInput.includes(mainKey));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkFromInput]);

	const handleOpenMapModal = (map) => () => {
		handleMapClick({
			endPointConfig: map,
			originData: rowData,
			redirectTo: detailPath
		});
	};

	const handleCheck = () => {
		handleToSaveAllCheckInputs(mainKey);
	};

	return (
		<tr className={row__class} style={stringToStyleObj(row__style)}>
			{checkbox && (
				<td className="font-weight-bold centra-check col-outer">
					<FormGroup check>
						<Input type={"checkbox"} name={id} checked={isChecked} onChange={handleCheck} />
					</FormGroup>
				</td>
			)}
			{columns.map((column, index) => (
				<TableItemTd
					key={`$columnList-${index}-${mainKey}`}
					columnInfo={column}
					mainKey={mainKey}
					handleColumnCheck={handleColumnCheck}
					rowData={rowData}
					handleOpenMapModal={handleOpenMapModal}
					handleChangeInput={handleChangeInput}
					redirectToDetail={redirectToDetail}
					editable={edit}
				/>
			))}
			{showActions && (
				<td className="text-center col-outer-tools button-menu-content">
					<div className="menu-actions-container">{children}</div>
				</td>
			)}
		</tr>
	);
};
TableItemList.propTypes = {
	id: PropTypes.string.isRequired,
	config: PropTypes.object,
	rowsConfig: PropTypes.object,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			class: PropTypes.string,
			style: PropTypes.string,
			content: PropTypes.any
		})
	)
};

TableItemList.defaultProps = {
	config: {},
	rowsConfig: {},
	columns: []
};

export default TableItemList;
