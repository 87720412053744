import list from './list.json'
import header from './header.json'
import modal from './modal.json'
import app from './app.json'
import map from './map.json'
import calendar from './calendar.json';

export default {
  list,
  header,
  modal,
  app,
  map,
  calendar
}