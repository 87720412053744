import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'reactstrap';
import LoadingSpinner from '../LoadingSpinner';
import authenticatedFetcher from '../../services/fetcher';
import { searchParams } from '../../services/commonServices';
import { useTranslation } from 'react-i18next';
import BasicIcon from '../BasicIcon';
import clsx from 'clsx';

const CustomTestApis = (props) => {
	const { withoutSelect, toggle, withoutCard } = props;
	const { title, ...selectConfig } = props.initialConfig || {};
	const { data } = props.formContext || {};
	const [isLoading, setIsLoading] = useState();
	const [selectItems, setSelectItems] = useState([]);
	const [selectRes, setSelectRes] = useState();
	const [selectedItem, setSelectedItem] = useState(null);
	const [refreshCount, setRefreshCount] = useState(1);
	const [t] = useTranslation('app');

	const getSelectItems = async () => {
		if (!selectConfig['select-options-endpoint']) return null;
		setIsLoading(true);
		const params = searchParams(selectConfig, data, 'select-options-params', 'select-options-params-fixed');
		authenticatedFetcher({
			path: selectConfig['select-options-endpoint'],
			method: selectConfig['select-options-method'],
			urlParams: params
		}).then((res) => {
			setIsLoading(false);
			if (Array.isArray(res)) setSelectItems(res);
		});
	};

	useEffect(() => {
		if (!withoutSelect) getSelectItems();
		else if (selectConfig?.action && selectConfig.params) handleChange({ target: { value: selectConfig } });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = async ({ target: { value } }) => {
		if (value === '-1') return setSelectRes(null);
		const reqConfig = typeof value === 'object' ? value : selectItems[value];
		setSelectedItem(reqConfig);
		setIsLoading(true);
		const params = searchParams(reqConfig, data);
		authenticatedFetcher({
			path: reqConfig.endpoint || reqConfig.action,
			method: reqConfig.method,
			urlParams: params
		}).then((res) => {
			setIsLoading(false);
			setSelectRes(res);
		});
	};

	const handleReload = () => {
		setRefreshCount((prev) => prev + 1);
		handleChange({ target: { value: selectedItem } });
	};

	const renderContent = () => (
		<div className={clsx("card-body py-3", withoutCard && "CustomTestApis")}>
			{!withoutSelect && (
				<div className="d-flex">
					<label htmlFor="TestApis-select">{selectConfig['select-label']}:</label>
					<select
						className="form-control form-control-sm BasicSelect w-auto mx-2"
						id="TestApis-select"
						onChange={handleChange}>
						<option value={-1}>-----</option>
						{selectItems.map((item, i) => (
							<option value={i}>{item.title}</option>
						))}
					</select>
				</div>
			)}
			<div className="CustomTestApis-terminal">
				<div className="CustomTestApis-terminalBar">
					<span className="CustomTestApis-dot red"></span>
					<span className="CustomTestApis-dot yellow"></span>
					<span className="CustomTestApis-dot green"></span>
				</div>
				<div className="CustomTestApis-terminalContent" key={refreshCount}>
					{withoutCard && <LoadingSpinner show={isLoading} />}
					{selectRes ? (
						<p className="font">
							<pre>{JSON.stringify(selectRes, null, 2)}</pre>
							<span className="CustomTestApis-terminalCursor">|</span>
						</p>
					) : (
						<p class="font">
							<span className="CustomTestApis-terminalCursor">|</span>
						</p>
					)}
				</div>
			</div>
		</div>
	);

	if(withoutCard) return renderContent()

	return (
		<Card className="card-box mb-3 mt-4 CustomTestApis">
			{title && (
				<div className="card-header py-3">
					<div className="card-header--title font-size-lg">
						{title}
						<LoadingSpinner show={isLoading} />
					</div>
					{toggle && (
						<Button size="sm" className="mr-1" onClick={toggle}>
							{t('close')}
						</Button>
					)}
					{!isLoading && (
						<Button size="sm" onClick={handleReload}>
							<BasicIcon iconName="faRedo" />
						</Button>
					)}
				</div>
			)}
			{renderContent()}
		</Card>
	);
};

CustomTestApis.propTypes = {
	withoutSelect: PropTypes.bool,
	withoutCard: PropTypes.bool,
	formContext: PropTypes.object,
	initialConfig: PropTypes.object,
	toggle: PropTypes.func
};

export default CustomTestApis;
