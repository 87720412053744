import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getDefaultDataFromTabsContent } from '../../utils/DynamicForm.utils';
import { validLayoutTypes } from '../../utils';
import FormContext from '../../context/FormContext';
import clsx from 'clsx';

const DynamicLayoutByType = (props) => {
	const {
		config: { layout },
		tabsContent,
		onSubmit,
		formTitle,
		isCreating,
		alwaysEditableFields,
		showSaveBtn = true
	} = props;
	const [formData, setFormData] = useState(getDefaultDataFromTabsContent(tabsContent, isCreating));
	const [isLupaClicked, setIsLupaClicked] = useState(false);
	const [formsState, setFormsState] = useState({});
	const [showValidations, setShowValidations] = useState(false);

	const { formErrors, updateAllData, data, isSearcher } = useContext(FormContext);

	useEffect(() => {
		setIsLupaClicked(() => JSON.parse(localStorage.getItem('isLupaClicked')));
	}, []);

	useEffect(() => {
		setShowValidations(() => !!Object.keys(formErrors || {}).length);
	}, [formErrors]);

	const submitHandler = (event) => {
		try {
			event.preventDefault();
			const isValid = !Object.entries(formsState).find(([, value]) => value === false);
			if (isValid) {
				onSubmit(formData);
			} else {
				setShowValidations(true);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const updateLocalFormData = (data, formInfo) => {
		if (formInfo)
			setFormsState((prev) => ({
				...prev,
				[formInfo.formName]: formInfo.formState
			}));
		setFormData((prev) => ({ ...prev, ...data }));
		updateAllData(data);
	};

	const renderForm = () => {
		const NewForm = validLayoutTypes.find((layoutTypes) => layoutTypes.name === layout.type)?.component || null;
		return NewForm ? (
			<div className={clsx(showValidations && 'ShowValidations')}>
				<NewForm
					{...props}
					submitHandler={submitHandler}
					updateFormData={updateLocalFormData}
					isCreating={isCreating}
					formErrors={formErrors}
					formData={formData}
					isLupaClicked={isLupaClicked}
					formsState={formsState}
					formTitle={formTitle}
					alwaysEditableFields={alwaysEditableFields}
					showSaveBtn={showSaveBtn}
					originalData={data}
					isSearcher={isSearcher}
				/>
			</div>
		) : null;
	};

	return renderForm();
};

DynamicLayoutByType.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		})
	),
	tabsContent: PropTypes.arrayOf(PropTypes.any),
	defaultTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onSubmit: PropTypes.func.isRequired
};

export default DynamicLayoutByType;
