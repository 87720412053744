import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import BasicIcon from '../BasicIcon';
import { FormGroup, Input, Label } from 'reactstrap';
import useEventListener from '../../hooks/useEventListener';

const BasicMultiSelect = ({
	options,
	onChange,
	disabled,
	labelField,
	keyField,
	error,
	className,
	variant = 'default',
	title = '',
	placeholder = '-------'
}) => {
	const [selecteds, setSelecteds] = useState({});
	const [selectCount, setSelectCount] = useState(0);
	const [inlineSelectTitle, setInlineSelectTitle] = useState('');
	const [clickAll, setClickAll] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const hasChange = useRef(false);
	const selectRef = useRef(null);
	const [t] = useTranslation('app');

	const isInline = variant === 'inline';

	useEffect(() => {
		setSelecteds({});
		setSelectCount(0);
		setClickAll(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options]);

	useEffect(() => {
		if (hasChange.current && typeof onChange === 'function') {
			const newStateArray = Object.values(selecteds).filter((item) => !!item === true);
			setSelectCount(selecteds.length);
			if (isInline) setInlineSelectTitle(newStateArray.map((item) => item[labelField]).join(', '));
			onChange(newStateArray);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selecteds]);

	const handleClick = (item) => () => {
		setClickAll(false);
		setSelecteds((prev) => {
			const newState = { ...prev, [item[keyField]]: prev[item[keyField]] ? null : item };
			if (!hasChange.current) hasChange.current = true;
			return newState;
		});
	};

	const handleOpen = () => setIsOpen((prev) => !prev);

	const onSelectAll = () => {
		if (!clickAll) {
			setSelecteds(() => {
				const newState =
					Array.isArray(options) &&
					options.reduce(
						(acc, item) => ({
							...acc,
							[item[keyField]]: item
						}),
						{}
					);
				const newStateArray = Object.values(newState).filter((item) => !!item === true);
				setSelectCount(newStateArray.length);
				if (typeof onChange === 'function') onChange(newStateArray);
				return newState;
			});
		} else {
			setSelecteds({});
			setSelectCount(0);
			if (typeof onChange === 'function') onChange([]);
		}
		return setClickAll((prev) => !prev);
	};
	const handleClickOutSide = (e) => {
		if (selectRef.current && !selectRef.current.contains(e.target)) handleOpen();
	};

	useEventListener(isOpen, typeof window !== 'undefined' ? window : null, 'click', handleClickOutSide);

	return (
		<div className="BasicMultiSelect" ref={selectRef}>
			{isInline && (
				<button className="BasicMultiSelect-inlineBtn form-control" onClick={handleOpen}>
					<span className="BasicMultiSelect-inlineBtn-title">{inlineSelectTitle || placeholder}</span>
					<BasicIcon iconName={isOpen ? 'faCaretUp' : 'faCaretDown'} />
				</button>
			)}
			{!isInline && title && <span className="BasicMultiSelect-title">{title}</span>}
			<div
				className={clsx(
					'BasicMultiSelect-options',
					variant,
					disabled && 'BasicMultiSelect-disabled',
					error && 'BasicMultiSelect-error',
					isOpen && 'open',
					className
				)}>
				{Array.isArray(options) &&
					options.map((item) => {
						if (isInline)
							return (
								<FormGroup className="d-block mb-2" check>
									<Label check>
										<Input
											name={item[keyField]}
											type="checkbox"
											checked={selecteds[item[keyField]]}
											onChange={handleClick(item)}
											className="BasicMultiSelect-checkOption mt-0"
										/>
										{item[labelField]}
									</Label>
								</FormGroup>
							);
						return (
							<button
								key={item[keyField]}
								type="button"
								className={`BasicMultiSelect-option ${selecteds[item[keyField]] ? 'active' : ''}`}
								onClick={handleClick(item)}>
								{item[labelField]}
							</button>
						);
					})}
			</div>
			<span className={clsx(`BasicMultiSelect-count`, !!selectCount && 'show', isInline && 'd-none')}>{`${t(
				'selected'
			)} (${selectCount})`}</span>
			<label className={isInline ? 'd-none' : 'd-block'}>
				<input type="checkbox" className="mr-1" checked={clickAll} onChange={onSelectAll} disabled={disabled} />
				{t('selectAll')}
			</label>
		</div>
	);
};

BasicMultiSelect.propTypes = {
	options: PropTypes.array,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	labelField: PropTypes.string.isRequired,
	keyField: PropTypes.string.isRequired,
	title: PropTypes.string
};

export default BasicMultiSelect;
