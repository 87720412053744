import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import WithFormConfig from '../../../hocs/WithFormConfig';
import { Table } from 'reactstrap';
import authenticatedFetcher from '../../../services/fetcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TypeFormInputFile from '../TypeForm/TypeFormInputFile';
import { fileUploader } from '../../../services/commonServices';

const notAllowedInputs = ['gallery', 'hidden'];
let saveChangeTimeout = null;

const DynamicGrid = (props) => {
	const [gridData, setGridData] = useState([]);
	const [fields, setFields] = useState([]);
	const [selectsData, setSelectsData] = useState({});
	const { field_related, config, saveChange, id } = props;

	useEffect(() => {
		getSelectsData();
		if (Array.isArray(config.fields)) setFields(config.fields.filter((item) => isAllowed(item)));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		clearTimeout(saveChangeTimeout);
		saveChangeTimeout = setTimeout(
			() =>
				typeof saveChange === 'function' &&
				saveChange(
					gridData.filter((item) => !!item),
					config,
					field_related,
					id
				),
			1000
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gridData]);

	const getSelectsData = async () => {
		if (!config.fields.some((item) => item.type === 'select')) return null;
		let requests = [];
		const allReq = config.fields.filter((item) => item.type === 'select');
		if (!allReq.length) return null;
		allReq.forEach((item) =>
			requests.push(
				authenticatedFetcher({
					path: item.options.endpoint
				}).then((res) => ({ [item.name]: res }))
			)
		);
		const allRes = await Promise.all(requests);
		setSelectsData(allRes.reduce((acc, res) => ({ ...acc, ...res }), {}));
	};

	const onChange = (name, index) => ({ target: { value } }) =>
		setGridData((prev) => prev.map((data, idx) => (idx === index ? { ...data, [name]: value } : data)));
	const onChangeFile = (__, index) => async (inputEvent, options) => {
		const fileUploaded = await fileUploader(inputEvent, options);
		if (!fileUploaded) return;
		setGridData((prev) => prev.map((data, idx) => (idx === index ? { ...data, ...fileUploaded } : data)));
	}
	const onChangeBool = (name, index, value) => () =>
		setGridData((prev) => prev.map((data, idx) => (idx === index ? { ...data, [name]: value } : data)));

	const addRow = () => setGridData((prev) => [...prev, {}]);
	const onDeleteRow = (index) => () => setGridData((prev) => prev.map((item, i) => (index !== i ? item : null)));

	const isAllowed = (item) =>
		item.name !== field_related && !notAllowedInputs.some((inptType) => inptType === item.type);

	const renderInput = (item, id) => {
		switch (item.type) {
			case 'number':
				return (
					<input
						type="number"
						className="form-control form-control-sm"
						defaultValue={item.value}
						onChange={onChange(item.name, id)}
					/>
				);
			case 'boolean':
				return (
					<div className="d-flex">
						<label className="d-flex align-center mr-2">
							<input
								type="radio"
								name={`${item.name}-${id}`}
								className="mr-1"
								defaultChecked={item.field_value === '1' ? true : null}
								onClick={onChangeBool(item.name, id, 1)}
							/>
							Correcte
						</label>
						<label className="d-flex align-center mr-2">
							<input
								type="radio"
								name={`${item.name}-${id}`}
								className="mr-1"
								defaultChecked={item.field_value === '0' ? true : null}
								onClick={onChangeBool(item.name, id, 0)}
							/>
							Incorrecte
						</label>
					</div>
				);
			case 'radio':
				return (
					<div className="d-flex">
						<label className="d-flex align-center mr-2">
							<input
								type="radio"
								name={`${item.name}-${id}`}
								className="mr-1"
								defaultChecked={item.field_value === '1' ? true : null}
								onClick={onChangeBool(item.name, id, 1)}
							/>
							Realitzat
						</label>
						<label className="d-flex align-center mr-2">
							<input
								type="radio"
								name={`${item.name}-${id}`}
								className="mr-1"
								defaultChecked={item.field_value === '0' ? true : null}
								onClick={onChangeBool(item.name, id, 0)}
							/>
							Pendent
						</label>
					</div>
				);
			case 'file':
				const data = gridData[id];
				return <TypeFormInputFile onlyInput name={item.name}
					onChange={onChangeFile(item.name, id)} value={data[item.name]} editable />
			case 'select':
				return (
					<select
						className="form-control form-control-sm"
						name={item.name}
						onChange={onChange(item.name, id)}>
						<option value={null}>Seleccioni una opció</option>
						{item.name in selectsData &&
							selectsData[item.name].map((option, i) => (
								<option key={`Select: ${item.name} - option: ${i}`} value={option[item.options.key]}>
									{option[item.options.print]}
								</option>
							))}
					</select>
				);
			default:
				return (
					<input
						type="text"
						className="form-control form-control-sm m-0"
						defaultValue={item.value}
						onChange={onChange(item.name, id)}
					/>
				);
		}
	};

	const renderBody = () =>
		gridData &&
		gridData.map(
			(item, i) =>
				item && (
					<tr key={`Row-${i}`} className="DynamicGrid-tr">
						{fields && fields.map((item) => <td key={item.name}>{renderInput(item, i)}</td>)}
						<td>
							<button className="DynamicGrid-deleteRowBtn" type="button" onClick={onDeleteRow(i)}>
								<FontAwesomeIcon icon={['fas', 'trash']} size="sm" />
							</button>
						</td>
					</tr>
				)
		);

	return (
		<Table className="DynamicGrid dynamic-form">
			<thead>
				{fields && fields.map((item) => <th key={item.name}>{`${item.label}${item.required ? ' *' : ''}`}</th>)}
				<th></th>
			</thead>
			<tbody>
				{renderBody()}
				<tr>
					<td colSpan={fields.length + 1} className="p-0 pt-2">
						<button type="button" className="btn bg-light w-100" onClick={addRow}>
							<i class="fas fa-plus-circle"></i>
						</button>
					</td>
				</tr>
			</tbody>
		</Table>
	);
};

DynamicGrid.propTypes = {};

export default WithFormConfig(DynamicGrid, true);
