import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { getInputStatus } from '../../../services/commonServices';
import PrintInputError from '../../InputUtils/PrintInputError';

import { Types } from '../../../utils/DynamicForm.config';
import TypeFormDynamicButton from './TypeFormDynamicButton';
import InputDescription from '../../InputUtils/InputDescription';

const buttonIconName = {
	CHECK: 'check',
	EDIT: 'pencil-alt'
};

const TypeFormInputPassword = ({
	id,
	label,
	name,
	value,
	placeholder,
	editable,
	onChange,
	isEditing = true,
	required,
	errors,
	labelColumn = {
		xs: 6,
		md: 2
	},
	inputColumn = {
		xs: 6,
		md: 10
	},
	alwaysEditableFields,
	relatedData
}) => {
	const [editing, setEditing] = useState(true);
	const [password, setPassword] = useState('');

	useEffect(() => {
		onChange({ target: { name, value: password } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [password]);

	const handleChange = ({ target: { value } }) => {
		setPassword(value);
		// setPassword((prev) => {
		// 	if (which === 8 || keyCode === 8) return prev ? prev.substring(0, prev.length - 1) : '';
		// 	if (value) return `${prev || ''}${value}`;
		// });
	};

	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;

	if (editing) {
		return (
			<FormGroup tag="fieldset" row>
				<Col {...labelColumn}>
					<Label for={name}>
						{required ? '*' : ''} {label}
					</Label>
				</Col>
				<Col {...inputColumn}>
					<Input
						id={id || name}
						type="password"
						disabled={!isEditable || !isEditing}
						name={name}
						value={password}
						placeholder={placeholder}
						onChange={handleChange}
						className={getInputStatus(required, value, errors)}
						style={{textTransformation: "none"}}
					/>
					<TypeFormDynamicButton onClick={() => setEditing(false)} />
				</Col>
				<PrintInputError errors={errors} />
				<InputDescription description={relatedData ? relatedData.description : ''} />
			</FormGroup>
		);
	}

	return (
		<FormGroup tag="fieldset" row>
			<Col md="2" className="  ">
				<Label for={name}>{label}</Label>
			</Col>
			<Col md="10">
				{value && String(value).replace(/./g, '*')}
				{isEditable && (
					<TypeFormDynamicButton onClick={() => setEditing(true)} iconName={buttonIconName.EDIT} />
				)}
			</Col>
		</FormGroup>
	);
};

TypeFormInputPassword.propTypes = Types.inputType;

export default TypeFormInputPassword;
