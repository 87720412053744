import React, { useEffect, useContext, useState } from 'react';
import { Button, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListContext from './List/ListContext';
import { formatQuantity } from '../services/commonServices';
import { useTranslation } from 'react-i18next';

const TableFooterPag = (props) => {
	const { pagination, exportlist, page, lastPage, dropdown, visiblePages = 5, totalItems, setPage } = props;
	const { display, options } = dropdown || {};
	const listContext = useContext(ListContext);
	const [limitPages, setLimitPages] = useState({ pageStart: 1, pageEnd: visiblePages });
	const [t] = useTranslation(['app']);

	const handleChangeShowing = (e) => {
		if (!setPage) listContext.setQuantityRecord(e.target.value);
	};

	const setPaginationUrl = (newPage) => () => {
		let newPagination = {
			pageEnd: 0,
			pageStart: 0
		};
		switch (newPage) {
			case 1:
				newPagination.pageStart = 1;
				newPagination.pageEnd = 1 + visiblePages > lastPage ? lastPage : visiblePages;
				break;
			case lastPage:
				newPagination.pageStart = lastPage - visiblePages + 1;
				newPagination.pageEnd = lastPage;
				break;
			default:
				if (newPage > page) {
					newPagination.pageStart =
						limitPages.pageStart + 1 + visiblePages > lastPage
							? limitPages.pageStart
							: limitPages.pageStart + 1;
					newPagination.pageEnd = limitPages.pageEnd + 1 > lastPage ? lastPage : limitPages.pageEnd + 1;
				}
				if (newPage < page) {
					newPagination.pageStart = limitPages.pageStart - 1 > 0 ? limitPages.pageStart - 1 : 1;
					newPagination.pageEnd =
						limitPages.pageEnd - 1 - visiblePages > 1 ? limitPages.pageEnd - 1 : visiblePages;
				}
		}
		setLimitPages({ ...newPagination });
		if (setPage) setPage(newPage);
		else listContext.setPage(newPage);
	};

	const printPagination = () => {
		const items = [];
		const pageStart = limitPages.pageStart;
		for (let index = pageStart; index <= limitPages.pageEnd; index++) {
			if (index > 0)
				items.push(
					// eslint-disable-next-line eqeqeq
					<PaginationItem active={index == page} key={'Page number ' + index}>
						<PaginationLink onClick={setPaginationUrl(index)}>{index}</PaginationLink>
					</PaginationItem>
				);
		}
		return items;
	};

	useEffect(() => {
		setPaginationUrl(page)();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastPage]);

	return (
		<>
			<div className="card-footer py-3 d-flex justify-content-between flex-wrap">
				{display && totalItems > 10 && (
					<div className="d-flex align-items-center text-nowrap">
						<span>{t('showing')}</span>
						<select
							className="mx-1 form-control form-control-sm inputValue"
							id=""
							name=""
							onChange={handleChangeShowing}>
							{options.map((option) => (
								<option key={option} value={`${option}`}>
									{option}
								</option>
							))}
						</select>
						<span className="font-weight-bold">{`de ${formatQuantity(totalItems)} ${t('register')}`}</span>
					</div>
				)}
				{pagination && lastPage && lastPage > 1 ? (
					<Pagination className="pagination-second py-2 mx-auto mx-md-0 py-md-0">
						<PaginationItem>
							<PaginationLink first onClick={setPaginationUrl(1)}>
								<FontAwesomeIcon icon={['fas', 'angle-double-left']} />
							</PaginationLink>
						</PaginationItem>
						<PaginationItem disabled={page < 2}>
							<PaginationLink previous onClick={setPaginationUrl(page - 1)}>
								<FontAwesomeIcon icon={['fas', 'chevron-left']} />
							</PaginationLink>
						</PaginationItem>
						{
							// eslint-disable-next-line eqeqeq
							lastPage == page && limitPages.pageStart > 1 ? (
								<PaginationItem>
									<PaginationLink>...</PaginationLink>
								</PaginationItem>
							) : null
						}
						{printPagination()}
						{
							// eslint-disable-next-line eqeqeq
							lastPage > limitPages.pageEnd ? (
								<>
									<PaginationItem>
										<PaginationLink>...</PaginationLink>
									</PaginationItem>
									<PaginationItem
										active={
											// eslint-disable-next-line eqeqeq
											lastPage == page
										}>
										<PaginationLink onClick={setPaginationUrl(lastPage)}>{lastPage}</PaginationLink>
									</PaginationItem>
								</>
							) : null
						}
						<PaginationItem disabled={lastPage === page}>
							<PaginationLink next onClick={setPaginationUrl(page + 1)}>
								<FontAwesomeIcon icon={['fas', 'chevron-right']} />
							</PaginationLink>
						</PaginationItem>
						<PaginationItem disabled={lastPage === page}>
							<PaginationLink last onClick={setPaginationUrl(lastPage)}>
								<FontAwesomeIcon icon={['fas', 'angle-double-right']} />
							</PaginationLink>
						</PaginationItem>
					</Pagination>
				) : null}
				{exportlist && (
					<div className="d-flex align-items-center">
						<Button size="sm" color="neutral-primary">
							<span className="btn-wrapper--icon">
								<FontAwesomeIcon icon={['fas', 'arrow-circle-down']} />
							</span>
							<span className="btn-wrapper--label">Exportar listado actual</span>
						</Button>
					</div>
				)}
			</div>
		</>
	);
};

export default TableFooterPag;
