import React, { useEffect, useState } from 'react';

const FormFieldsSelector = ({
	items = [],
	title = '',
	onChange,
	selectedItems: selectedOptions = [],
	fieldId = 'name',
	fieldLabel = 'label'
}) => {
	const [selectedItems, setSelectedItems] = useState([]);

	useEffect(() => {
		if (Array.isArray(selectedOptions)) setSelectedItems(selectedOptions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOptions]);

	const handleChange =
		(toDelete = false) =>
		({ target: { value } }) => {
			const selectedOptions = toDelete
				? selectedItems.filter((item) => item !== value)
				: [...selectedItems, value];
			setSelectedItems(selectedOptions);
			if (typeof onChange === 'function') onChange(selectedOptions);
		};

	return (
		<div className="FormFieldsSelector">
			<div className="FormFieldsSelector-title">{title}</div>
			<div className="FormFieldsSelector-container">
				{Array.isArray(items) && items.length ? (
					items.map((item, i) => {
						return (
							<div className="FormFieldsSelector-row" key={`option-${i}`}>
								<div className="FormFieldsSelector-label">{item[fieldLabel]}</div>
								<div className="FormFieldsSelector-action">
									<label>
										<input
											type="radio"
											value={item[fieldId]}
											checked={selectedItems.includes(item[fieldId])}
											onChange={handleChange()}
										/>
										Si
									</label>
									<label>
										<input
											type="radio"
											value={item[fieldId]}
											checked={!selectedItems.includes(item[fieldId])}
											onChange={handleChange(true)}
										/>
										No
									</label>
								</div>
							</div>
						);
					})
				) : (
					<p>No data found</p>
				)}
			</div>
		</div>
	);
};

export default FormFieldsSelector;
