import { BASE_URL, MEDIA_BASE_URL, REACT_APP_LOGOUT_URL, REACT_APP_LOGIN_URL, LANG, WITH_LANGUAGES } from '../config';
import { clearLocalStorage, readLocalStorage, writeLocalStorage } from './localStorageManagement';
import { readSessionStorage, writeSessionStorage } from './sessionStorageManagement';

// const username = process.env.REACT_APP_USER;
// const password = process.env.REACT_APP_PASSWORD;
let refreshTime = null;

export const deleteUserInfo = async (returnToHome) => {
	const userInfo = JSON.parse(getUserInfo());
	if (userInfo?.refresh)
		await fetch(`${BASE_URL}${REACT_APP_LOGOUT_URL}`, {
			method: 'POST',
			headers: { Authorization: `Bearer ${userInfo.access}`, 'Content-Type': 'application/json;charset=utf-8' },
			body: JSON.stringify({ refresh: userInfo.refresh })
		});
	window.dispatchEvent(new Event('userInfoEvent'));
	clearLocalStorage();
	// clearSessionStorage();
	window.location = '/';
};

export const getUserInfo = () => readLocalStorage('userInfo', '{}');

export const isThereUserInformation = () => !!readLocalStorage('userInfo');

export const setUserData = (userInfo) => {
	const currentInfo = JSON.parse(getUserInfo());
	writeLocalStorage('userInfo', JSON.stringify({ ...currentInfo, ...userInfo }));
	window.dispatchEvent(new Event('userInfoEvent'));
};

export const loginMethod = async ({ username, password }) => {
	const params = {
		method: 'POST',
		body: JSON.stringify({ username, password, "erp": 1 }),
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		}
	};
	try {
		const res = await fetch(`${BASE_URL}/token/`, params);
		const response = await res.json();
		if (!response.access) return null;
		const res2 = await fetch(`${BASE_URL}${REACT_APP_LOGIN_URL}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${response.access}`,
				'Content-Type': 'application/json;charset=utf-8'
			}
		});
		const { user_full_name, login } = await res2.json();
		if (login) {
			setUserData({ ...response, userName: user_full_name, login });
			return { ...response, userName: user_full_name, login };
		} else {
			return { login };
		}
	} catch (e) {
		throw new Error(e);
	}
};

const refreshSession = async ({ path, urlParams, method, body, file }, refresh, retry) => {
	const res = await fetch(`${BASE_URL}/token/refresh/`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json;charset=utf-8' },
		body: JSON.stringify({ refresh })
	});
	const newTokens = await res.json();
	if (!newTokens.access) deleteUserInfo();
	else {
		setUserData(newTokens);
		writeLocalStorage('hasRefreshTime', new Date().toString());
	}
	return retry ? authenticatedFetcher({ path, urlParams, method, body, file }) : null;
};

const authenticatedFetcher = async ({
	path,
	urlParams = {},
	body = {},
	method = 'GET',
	file = false,
	noRefresh = false,
	abortSignal = null,
	langReq = false
}) => {
	try {
		const userInfo = JSON.parse(getUserInfo());
		const { access: token = '', refresh = '' } = userInfo || {};
		let localLang = WITH_LANGUAGES ? readSessionStorage('i18nextLng') : LANG;
		if (!localLang && !langReq && WITH_LANGUAGES) {
			const langs = await authenticatedFetcher({ path: '/config/languages/', langReq: true });
			if (langs && Array.isArray(langs)) {
				writeSessionStorage('i18nextLng', langs[0].pk);
				localLang = langs[0].pk;
			} else {
				writeSessionStorage('i18nextLng', LANG);
				localLang = LANG;
			}
		}
		clearTimeout(refreshTime);
		if (!token) throw new Error('TOKEN ERROR');

		const params = {
			headers: {
				Authorization: `Bearer ${token}`,
				//'Content-Type': 'multipart/form-data'
				'Content-Type': 'application/json;charset=utf-8'
			},
			method,
			signal: abortSignal
		};

		if (file) {
			delete params.headers['Content-Type'];
			params.body = body;
			console.log(params);
		} else {
			if (method === 'POST' || method === 'PUT' || method === 'PATCH') params.body = JSON.stringify(body);
		}
		const reqParams = typeof urlParams === 'object' ? { lang: localLang, ...urlParams } : { lang: localLang };
		const url = new URL(`${BASE_URL}${path}`);
		Object.keys(reqParams).forEach((key) => reqParams[key] && url.searchParams.append(key, reqParams[key]));

		const response = await fetch(url, params);

		if (response.status === 204) {
			return { error: 'no-content' };
			// throw new Error('El request se ha procesado correctamente, pero no devuelve ningún contenido.');
		}

		const data = await response.json();

		if (data && data.detail === 'Login failed.') {
			window.localStorage.removeItem('user_token');
			throw new Error('Login failed.');
		}

		if (refresh && !noRefresh) {
			clearTimeout(refreshTime);
			refreshTime = setTimeout(
				() => {
					const hasRefreshTime = localStorage.getItem('hasRefreshTime');
					if (hasRefreshTime) {
						const lastRefreshTime = new Date(hasRefreshTime);
						const actuallyTime = new Date();
						const dif = Math.abs((lastRefreshTime.getTime() - actuallyTime.getTime()) / 1000);
						if (dif > 30)
							refreshSession(
								{ path, urlParams: reqParams, method, body, file },
								refresh,
								response.status === 401
							);
					} else
						refreshSession(
							{ path, urlParams: reqParams, method, body, file },
							refresh,
							response.status === 401
						);
				},
				response.status === 401 ? 100 : 30000
			);
		}

		if (response.status === 403) {
			window.location = '/';
			return { error: 'No autorizado' };
		}

		if (response.status === 200 || response.status === 201 || response.status === 206)
			return file ? { ...data, mediaBaseUrl: MEDIA_BASE_URL } : data;

		if (response.status === 400 && data && data.email) {
			throw new Error(data.email[0]);
		}

		if (response.status === 400 && data)
			return {
				error: 'response' in data ? data.response[0] : 'Algo salio mal',
				error_data: data,
				status: response.status
			};

		throw new Error('Fetch error.');
	} catch (e) {
		//throw new Error(e);
		return { error: 'Algo salio mal' };
	}
};

export default authenticatedFetcher;
