import React, { useState, useReducer, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../context';
import getAppConfig from '../services/get-app-config';
import configReducer from '../reducers/config-reducer';
import { SET_INIT } from '../reducers/config-reducer/types';
import { getUserInfo } from '../services/fetcher';
import { useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';
import { APP_TITLE } from '../config';
import { readSessionStorage } from '../services/sessionStorageManagement';

const AppProvider = ({ children }) => {
	const [ready, setReady] = useState(false);
	const [config, dispatch] = useReducer(configReducer, {
		mainParams: JSON.parse(readSessionStorage('mainParams', '{}')),
		globalParams: JSON.parse(readSessionStorage('mainParams', '{}'))
	});

	const [user, setUserData] = useState({});
	const router = useHistory();
	const getPageData = () => {
		const pathname = router.location.pathname;
		const isForm = parseInt(pathname.split('/').pop(), 10);
		return (
			config.routes &&
			config.routes.find((item) =>
				isForm
					? item.path + isForm === pathname || item.path + isForm === pathname + '/'
					: item.path === pathname || item.path === pathname + '/'
			)
		);
	};
	const [pageData, setPageData] = useState(getPageData());

	useEffect(() => {
		const userInfo = JSON.parse(getUserInfo());
		setUserData(userInfo ? userInfo : {});
		getAppConfig(config.mainParams).then((fetchedConfig) => {
			if ('error' in fetchedConfig) return;
			dispatch({ type: SET_INIT, payload: fetchedConfig });
			setReady(true);
		}); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (Array.isArray(config.dashboard)) setPageData(getPageData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.location, config]);

	return (
		<Fragment>
			<Helmet>
				<title id="appTitle">{pageData?.title || APP_TITLE}</title>
			</Helmet>
			<AppContext.Provider value={{ config, dispatch, user }}>{ready && children}</AppContext.Provider>
		</Fragment>
	);
};

AppProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default AppProvider;
