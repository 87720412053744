import clsx from 'clsx';
import React from 'react';
import { ReactComponent as Spinner } from '../../assets/images/Spinner-0.7s-277px.svg';

const LoadingSpinner = ({ show, style, className }) => {
	if (!show) return null;
	return <Spinner className={clsx('LoadingSpinner', className)} style={style} />;
};

export default LoadingSpinner;
