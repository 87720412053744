import React, { Fragment, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import Input from '../EditElement/Input';
import fetcher from '../../services/fetcher';
import Dropdown from '../EditElement/Dropdown';
import InputDate from '../EditElement/InputDate';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DadesTecniques from '../EditElement/DadesTecniques';
import CustomModalSetting from '../EditElement/CustomModalSetting';
import { useTranslation } from 'react-i18next';
import ImatgesComponent from '../CustomInstallacioTelegestio/ImatgesComponent';
import { fetchRelatedCards } from '../../utils';
import AppContext from '../../context';
import DynamicFormGroups from '../DynamicForm/RelatedCards/DynamicFormGroups';
import { CUSTOM_PAGES_INFO } from '../../config';

// const activeTab = 2;
const tabs = [
	{
		id: 1,
		name: 'Informació General'
	},
	{
		id: 3,
		name: 'Documentació'
	},
	{
		id: 4,
		name: 'Imatges'
	}
];

const EditElement = (props) => {
	const { id, config, onSubmit, dadesTecniques, basicInfo, noEditable } = props;
	const [inputs, setInputs] = useState(false);
	const [allOptions, setAlloptions] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	const [isSettingOpen, setIsSettingOpen] = useState(false);
	const [formData, setFormData] = useState();
	const [documentationConfig, setDocumentationConfig] = useState();
	const { config: { routes } = {} } = useContext(AppContext);
	const [suplementary, setSuplementary] = useState(false);

	const [activeTab, setActiveTab] = useState(1);
	const history = useHistory();
	const [t] = useTranslation('app');

	const toggleTab = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	useEffect(() => {
		handleSetOptions();
		handleFetchData();
		handleRelatedData();
		// tecniquesFields();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleRelatedData = async () => {
		const groupedData = await fetchRelatedCards(config, basicInfo, routes);
		const [firstGroup] = groupedData;
		const docConfig = firstGroup.tabs.find((item) => item.originConfig.endpoints.id === 'tele-wiki-elements');
		if (docConfig) setDocumentationConfig([{ ...firstGroup, tabs: [docConfig] }]);
	};

	const handleInputchange = ({ currentTarget }) => {
		const { name, value } = currentTarget;
		updateInputState(name, value);
	};

	const getFields = () => {
		if (!config) return [];
		return config.fields || [];
	};

	const handleFetchData = async () => {
		const response = await fetcher({
			path: `${CUSTOM_PAGES_INFO.element_telegestio.endpoint}${id}/`
		});
		if (!response) return;
		const fields = getFields();
		let values = {};
		for (const f of fields) {
			values = {
				...values,
				[f.name]: response[f.name]
			};
		}
		setInputs(values);
		setFormData(response);
	};

	const handleSetOptions = async () => {
		const fields = getFields();
		const selectFields = fields.filter((f) => f.type === 'select');
		let opt = {};
		for (const f of selectFields) {
			const { endpoint } = f.options;
			const optData = await fetcher({ path: endpoint });
			if (Array.isArray(optData)) {
				const optionsFormated = optData.map((o) => ({
					label: o.name,
					value: o.pk
				}));
				opt = {
					...opt,
					[f.name]: optionsFormated
				};
			}
		}
		setAlloptions(opt);
	};

	const updateInputState = (name, value) => {
		setInputs((prev) => {
			return {
				...prev,
				[name]: value
			};
		});
	};

	const handleDropdownChange = ({ currentTarget }) => {
		const { name, value } = currentTarget;
		updateInputState(name, parseInt(value));
	};

	const handleSubmit = () => {
		suplementary ? onSubmit(formData) : onSubmit(inputs);
	};

	const handleCancel = () => {
		onSubmit({});
	};

	const renderFields = () => {
		const fields = getFields();
		return fields.map((f) => {
			const value = inputs[f.name];
			const options = allOptions[f.name];
			switch (f.type) {
				case 'text':
					return (
						<Col md="6">
							<Input
								isEdit={isEdit}
								id={f.name}
								label={f.label}
								name={f.name}
								value={value}
								onChange={handleInputchange}
								required={f.required}
								disabled={!f.editable || !isEdit}
							/>
						</Col>
					);
				case 'select':
					return (
						<Col md="6">
							<Dropdown
								classNameSelect="FitxaElement-select"
								isEdit={isEdit}
								label={f.label}
								name={f.name}
								value={value}
								onChange={handleDropdownChange}
								options={options}
								required={f.required}
								disabled={!f.editable || !isEdit}
							/>
						</Col>
					);
				case 'textarea':
					return (
						<Col md="6">
							<Input
								textAreaType
								id={f.name}
								label={f.label}
								name={f.name}
								value={value}
								onChange={handleInputchange}
								required={f.required}
								disabled={!f.editable || !isEdit}
							/>
						</Col>
					);
				case 'date':
					return (
						<Col md="6">
							<InputDate
								id={f.name}
								label={f.label}
								name={f.name}
								value={value}
								onChange={handleInputchange}
								required={f.required}
								disabled={!f.editable || !isEdit}
								type="date"
							/>
						</Col>
					);
				default:
					return <Fragment />;
			}
		});
	};

	// const updateData = (data) => {};

	const toggleMap = () => {
		history.push(`${history.location.pathname}/Map/`, {
			endPointConfig: config.map,
			originData: basicInfo
		});
	};

	return (
		<Card className="card-box">
			<Row>
				<Col md="12">
					<div className="card-header py-3">
						<div className="card-header--title font-size-lg">
							{basicInfo.form_title || config.name}
							{!noEditable && (
								<Button size="sm" color="dark" className="ml-3" onClick={() => setIsEdit(!isEdit)}>
									<small>
										<FontAwesomeIcon icon={['fas', 'pencil-alt']} />
									</small>
									{/* <span className="btn-wrapper--label">Editar</span> */}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col md="3" className="pr-0">
					<Nav pills className="nav-neutral-primary flex-column p-3 text-black-50" tabs>
						{tabs.map(({ id, name }) => (
							<NavItem key={id}>
								<NavLink
									style={{ justifyContent: 'space-between' }}
									onClick={() => toggleTab(id)}
									className={clsx({ active: activeTab === id }, 'd-flex')}>
									<span>{name}</span>
									{id === 2 && (
										<span onClick={() => setIsSettingOpen((prev) => !prev)}>
											<FontAwesomeIcon icon={faCog} />
										</span>
									)}
								</NavLink>
							</NavItem>
						))}
					</Nav>
				</Col>
				<Col md="9">
					<div className="p-4-5">
						<TabContent className="FitxaElement-info" activeTab={activeTab}>
							<TabPane key={tabs.id} tabId={tabs[0].id}>
								<div className="dynamic-form">
									<Row>
										{inputs && renderFields()}
										<Col md="6">
											<fieldset class="row form-group">
												<div class="w-100 row">
													<div class="text-sm-left text-md-right p-0 col-6 col-md-5">
														<label for="form-2-last-name" class="w-100 font-weight-bold">
															Mapa
														</label>
													</div>
													<div class="col-6 col-md-7">
														<button class="btn btn-link" onClick={toggleMap}>
															<FontAwesomeIcon
																icon={['fas', 'map-marked']}
																color="#4293CC"
																style={{ fontSize: '24px' }}
															/>
														</button>
													</div>
												</div>
											</fieldset>
										</Col>

										<div className="card-header--title font-size-lg mb-3" style={{'margin-left': '-20px'}}>
											<u>{t('technical_data')}</u>
										</div>
										<DadesTecniques
											setSuplementary={setSuplementary}
											dadesTecniques={dadesTecniques}
											isEdit={isEdit}
											setFormData={setFormData}
										/>
									</Row>
								</div>
							</TabPane>
							{isSettingOpen && (
								<CustomModalSetting
									handleSubmit={onSubmit}
									setFormData={setFormData}
									formData={formData}
								/>
							)}
							<TabPane key={tabs.id} tabId={tabs[1].id}>
								<div className="row">
									<div className="col-12">
										{documentationConfig && <DynamicFormGroups groupedData={documentationConfig} />}
									</div>
								</div>
							</TabPane>
							<TabPane key={tabs.id} tabId={tabs[2].id}>
								<ImatgesComponent
									options={{ max_images: 3 }}
									module="element"
									id={id}
									disabled={noEditable}
								/>
							</TabPane>
						</TabContent>
					</div>
					{isEdit && !basicInfo?.block_form && (
						<>
							<hr />
							<div style={{ display: 'flex' }}>
								<div style={{ marginRight: '15px' }} className="mb-4">
									<Button type="submit" onClick={handleSubmit} className="submitButton">
										{t('submit')}
									</Button>
								</div>
								<div className="mb-4">
									<Button type="submit" onClick={handleCancel} className="submitButton">
										Cancelar
									</Button>
								</div>
							</div>
						</>
					)}
				</Col>
			</Row>
		</Card>
	);
};

EditElement.defaultProps = {
	onSubmit: () => {}
};

EditElement.propTypes = {
	id: PropTypes.string,
	config: PropTypes.any,
	onSubmit: PropTypes.func
};

export default EditElement;
