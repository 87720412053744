import React from 'react'
import { TileLayer, WMSTileLayer } from 'react-leaflet';
import PropTypes from 'prop-types'

const DynamicRaster = ({
    type = "wms",
    title,
    attribution,
    url,
    params = {},
    maxZoom = 20,
    maxNativeZoom = 18
}) => {
    if (type === "tile") return <TileLayer
        maxZoom={maxZoom}
        maxNativeZoom={maxNativeZoom}
        attribution={attribution}
        url={url}
    />
    return (
        <WMSTileLayer
            key={title}
            maxZoom={maxZoom}
            maxNativeZoom={maxNativeZoom}
            url={url}
            params={params}
        />
    )
}

DynamicRaster.propTypes = {
    type: PropTypes.oneOf(["wms", "tile"]),
    title: PropTypes.string,
    attribution: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.any,
    maxZoom: PropTypes.number,
    maxNativeZoom: PropTypes.number,
}

export default DynamicRaster
