import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

const InputDescription = ({ description }) => {
	if (!description) return null;
	return <span className="InputDescription">{parse(description)}</span>
};

InputDescription.propTypes = {
	description: PropTypes.string
};

export default InputDescription;
