import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import DeleteModal from './TableComponents/DeleteModal';

const ContentMenuActions = ({
	copy = false,
	lupa = true,
	trash,
	arrow = false,
	edit,
	handleTrashClick,
	handleCopyClick = () => {},
	handleLupaClick,
	handlePlusClick,
	handleEditClick,
	detailPath,
	rowData,
	showActions = true
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [trashModal, setTrashModal] = useState(false);
	const [plusState, setPlusState] = useState(false);

	const toggle = () => setTrashModal(!trashModal);

	const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

	const onDelete = async (body) => {
		const res = await handleTrashClick(body);
		if (res) toggle();
	};

	const handlePlus = () => {
		setPlusState((prevState) => !prevState);
		handlePlusClick();
	};

	const handleToDetail = () => {
		localStorage.setItem('isLupaClicked', false);
	};

	return (
		<div className="card-header--actions ContextualMenu d-flex justify-content-between">
			{showActions && (
				<Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
					<DropdownToggle caret>
						<FontAwesomeIcon className="ActionsBtn" icon={['fas', 'ellipsis-v']} />
					</DropdownToggle>
					<DropdownMenu>
						{lupa && (
							<DropdownItem>
								<div className="btn btn-neutral-primary btn-sm" onClick={handleLupaClick}>
									<span className="btn-wrapper--icon">
										<FontAwesomeIcon icon={['fas', 'search']} />
									</span>
								</div>
							</DropdownItem>
						)}
						{edit && (
							<DropdownItem>
								{detailPath ? (
									<Link
										className="btn btn-sm btn-neutral-primary"
										to={detailPath}
										onClick={handleToDetail}>
										<span className="btn-wrapper--icon">
											<FontAwesomeIcon icon={['fas', 'pencil-alt']} />
										</span>
									</Link>
								) : (
									<div className="btn btn-neutral-primary btn-sm" onClick={handleEditClick}>
										<span className="btn-wrapper--icon">
											<FontAwesomeIcon icon={['fas', 'pencil-alt']} />
										</span>
									</div>
								)}
							</DropdownItem>
						)}
						{copy && (
							<DropdownItem>
								<div className="btn btn-neutral-primary btn-sm" onClick={handleCopyClick}>
									<span className="btn-wrapper--icon">
										<FontAwesomeIcon icon={['fas', 'copy']} />
									</span>
								</div>
							</DropdownItem>
						)}
						{/* {arrow && (
						<DropdownItem>
							<Button size="sm" color="neutral-primary">
								<span className="btn-wrapper--icon">
									<FontAwesomeIcon icon={['fas', 'arrow-circle-down']} />
								</span>
							</Button>
						</DropdownItem>
					)} */}
						{trash && (
							<DropdownItem>
								<>
									<div className="btn btn-neutral-primary btn-sm" onClick={toggle}>
										<span className="btn-wrapper--icon">
											<FontAwesomeIcon icon={['fas', 'trash']} />
										</span>
									</div>
									<DeleteModal
										isOpen={trashModal}
										toggle={toggle}
										onDelete={onDelete}
										rowData={rowData}></DeleteModal>
								</>
							</DropdownItem>
						)}
					</DropdownMenu>
				</Dropdown>
			)}
			{handlePlusClick && (
				<Button onClick={handlePlus} size="sm" color="neutral-primary">
					{plusState ? (
						<FontAwesomeIcon icon={['fas', 'minus']} />
					) : (
						<FontAwesomeIcon icon={['fas', 'plus']} />
					)}
				</Button>
			)}
		</div>
	);
};

ContentMenuActions.propTypes = {
	id: PropTypes.string.isRequired,
	lupa: PropTypes.bool,
	toggle: PropTypes.func,
	trashModal: PropTypes.bool,
	columns: PropTypes.array,
	handleTrashClick: PropTypes.func,
	handleLupaClick: PropTypes.func,
	fetchDataFromEdit: PropTypes.func,
	edit: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	copy: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	trash: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	// eslint-disable-next-line react/require-default-props
	arrow: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
};
export default ContentMenuActions;
