import React, { Fragment, useEffect } from 'react';
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';
import { useMap } from 'react-leaflet';

const MapPrinter = (props) => {
	const mapRef = useMap()

	useEffect(()=>{
		new SimpleMapScreenshoter().addTo(mapRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	return <Fragment></Fragment>
};

MapPrinter.propTypes = {};

export default MapPrinter;
