import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const MapFilters = ({ filters = {}, onChange, selectedRaster, setSelectedRaster, defaultValues, rasters }) => {
	const [openedOption, setOpenedOption] = useState('');
	const [isMounted, setIsMounted] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState({});
	const [t] = useTranslation('map');

	useEffect(() => {
		if (typeof onChange === 'function' && isMounted) {
			onChange(selectedOptions);
		} else {
			if (typeof defaultValues === 'object') {
				if (Object.keys(selectedOptions).length) setIsMounted(true);
				else {
					let newSelectedOption = {};
					Object.entries(defaultValues).forEach(([key, value]) => {
						if (filters[key]) {
							newSelectedOption[key] = [value];
						}
					});
					if (Object.keys(newSelectedOption).length) setSelectedOptions(newSelectedOption);
					else setIsMounted(true);
				}
			} else setIsMounted(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOptions]);

	const handleOptions = (title) => () =>
		setOpenedOption((prevTitle) => {
			if (prevTitle === title) return '';
			return title;
		});

	const isChecked = (filterType, pk) =>
		// eslint-disable-next-line
		filterType in selectedOptions ? selectedOptions[filterType].some((item) => item == pk) : false;

	const handleCheck = (filterType, pk) => () => {
		if (filterType in selectedOptions) {
			const filterItem = selectedOptions[filterType].some((item) => item === pk);
			if (filterItem) {
				return setSelectedOptions((prev) => {
					return {
						...prev,
						[filterType]: prev[filterType].filter((item) => item !== pk)
					};
				});
			} else {
				if (pk === 0) return setSelectedOptions((prev) => ({ ...prev, [filterType]: [] }));
				return setSelectedOptions((prev) => {
					return {
						...prev,
						[filterType]: [...prev[filterType], pk]
					};
				});
			}
		} else {
			return setSelectedOptions((prev) => {
				return {
					...prev,
					[filterType]: [pk]
				};
			});
		}
	};

	return (
		<div className="CustomMap-filtersContainer">
			<div className="CustomMap-mapOptions">
				{Array.isArray(rasters) && rasters.map(({ title }, i) => {
					return (
						<div key={i} onClick={setSelectedRaster(i)} className="CustomMap-mapOption">
							<input readOnly checked={i === selectedRaster} type="radio" name={title} />
							<label htmlFor={title}>{title}</label>
						</div>
					);
				})}
			</div>
			<div className="CustomMap-filters">
				{Object.entries(filters).map(([key, options], index) => (
					<div key={index} className="CustomMap-filter">
						<div onClick={handleOptions(key)} className="CustomMap-filterTitle">
							<span className="title">
								{Array.isArray(options) && options.length ? options[0].label : key}
							</span>
							{openedOption === key ? (
								<FontAwesomeIcon icon={['fas', 'angle-up']} className="CustomMap-filterTitleIcon" />
							) : (
								<FontAwesomeIcon icon={['fas', 'angle-down']} className="CustomMap-filterTitleIcon" />
							)}
						</div>
						<div className={`CustomMap-filterOptions ${openedOption === key ? '' : 'd-none'}`}>
							<div className="CustomMap-filterOption" onClick={handleCheck(key, 0)}>
								<input readOnly checked={!selectedOptions[key]?.length} type="checkbox" name="selectALL" />
								<label htmlFor="selectALL" className="label-from-list">
									{t('all')}
								</label>
							</div>
							{Array.isArray(options)
								? options.map(({ name = '', pk, color = '' }, index) => {
									return (
										<div
											key={index}
											className="CustomMap-filterOption"
											onClick={handleCheck(key, pk)}>
											<input readOnly checked={isChecked(key, pk)} type="checkbox" name={pk} />
											{color && (
												<span
													style={{
														height: 13,
														backgroundColor: color,
														width: 13,
														marginRight: 5,
														borderRadius: 8
													}}
												/>
											)}
											<label htmlFor={pk} className="label-from-list">
												{name}
											</label>
										</div>
									);
								})
								: null}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default MapFilters;
