import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const blankConfig = {
	target: '_blank',
	rel: 'noopener noreferrer'
};

const BasicLink = ({ text, link, blank, variant, children }) => {
	const aStyle = blank ? blankConfig : {};
	return (
		<a {...aStyle} href={link} className={clsx('BasicLink', variant)}>
			{text || children}
		</a>
	);
};

BasicLink.propTypes = {
	text: PropTypes.string,
	link: PropTypes.string,
	blank: PropTypes.bool,
	variant: PropTypes.string
};

export default BasicLink;
