import React from 'react';

const PrintInputError = ({ errors = [''] }) => {
	return errors && Array.isArray(errors) && errors.length ? (
		<div className="InputErrors mt-3">
			{errors.map((error, i) => (
				<p key={'error ' + i}>{error}</p>
			))}
		</div>
	) : null;
};

export default PrintInputError;
