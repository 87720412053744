export const readLocalStorage = (key, defaultValue = "") => {
  return localStorage.getItem(key) || defaultValue
}

export const writeLocalStorage = (key, value, defaultValue = "") => {
  localStorage.setItem(key, value || defaultValue)
}

export const clearLocalStorage = () => localStorage.clear()

export const deleteItemToLocalStorage = key => localStorage.removeItem(key)
