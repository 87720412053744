import React, { useEffect, useRef, useState } from 'react';
import formatDate from '../DynamicForm/TypeForm/TypeFormDate';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateMask from '../MaskedInput/DateMask';
import moment from 'moment';
import { Col, FormGroup, Label, Row } from 'reactstrap';

const InputDate = (props) => {
	const { name, value, onChange, disabled, label, required, type, id } = props;
	const [startDate, setStartDate] = useState('');
	const datePickerRef = useRef();

	useEffect(() => {
		if (value) {
			const newDate = formatDate.fromApiToPicker(value);
			if (required)
				onChange({
					currentTarget: {
						name,
						value: value ? value : formatDate.fromPickerToApi(newDate, { withTime: false })
					}
				});
			setStartDate(newDate);
		}
		if (document) {
			const dateMaskInput = document.getElementById(name);
			if (dateMaskInput)
				dateMaskInput.addEventListener('keydown', (e) => {
					if (e.isComposing || e.key === 'Tab' || e.key === 'Esc' || e.key === 'Escape')
						datePickerRef.current.setOpen(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnChange = (date) => {
		if (disabled) return;
		setStartDate(date);
		onChange({
			currentTarget: { name, value: date ? formatDate.fromPickerToApi(date, { withTime: false }) : null }
		});
	};

	return (
		<FormGroup tag="fieldset" row>
			<Row className="w-100">
				<Col xs="6" xl="5" className="text-sm-left text-md-right p-0">
					<Label for="form-2-last-name" className="w-100 font-weight-bold">
						{required ? '*' : ''} {label}
					</Label>
				</Col>
				<Col xs="6" xl="7">
					{!disabled ? (
						<div className="Datetimepicker-wrapper">
							<DatePicker
								ref={datePickerRef}
								id={id || name}
								type="datetime-local"
								name={name}
								showTimeSelect={false}
								dateFormat="dd/MM/yyyy HH:mm"
								timeFormat="HH:mm"
								timeCaption="Hora"
								value={startDate}
								selected={startDate}
								shouldCloseOnSelect
								onChange={handleOnChange}
								autocomplete="off"
								todayButton="Avui"
								readOnly={disabled}
								className="w-100"
								customInput={<DateMask type={type} />}
							/>
							<FontAwesomeIcon icon="calendar" title="magic" />
						</div>
					) : value ? (
						moment(value).format('DD/MM/yyyy')
					) : (
						''
					)}
				</Col>
			</Row>
		</FormGroup>
	);
};

InputDate.defaultProps = {
	required: false,
	disabled: false,
	onChange: () => {},
	options: []
};

InputDate.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.any,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.number
		})
	)
};

export default InputDate;
