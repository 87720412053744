import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import useSelectFormListener from '../../../hooks/useSelectFormListener';
import { getInputStatus, getItems } from '../../../services/commonServices';
import authenticatedFetcher from '../../../services/fetcher';
import PrintInputError from '../../InputUtils/PrintInputError';
import { Types } from '../../../utils/DynamicForm.config';
import InputDescription from '../../InputUtils/InputDescription';
import ToMapBtn from '../../MapComponents/ToMapBtn';
import LoadingSpinner from '../../LoadingSpinner';
import { useTranslation } from 'react-i18next';

export default function TypeSelectAsync(props) {
	const {
		label,
		value,
		defaultValue,
		name,
		options: inputConfig,
		onChange,
		required,
		editable,
		errors,
		isEditing = true,
		labelColumn = {
			xs: 6,
			md: 2
		},
		inputColumn = {
			xs: 6,
			md: 10
		},
		globalParams = {},
		alwaysEditableFields,
		relatedData,
		toggleMap
	} = props;

	const [options, setOptions] = useState([]);
	const [inputValue, setInputValue] = useState('');
	const [selectedItem, setSelectedItem] = useState({});
	const [isMounted, setIsMounted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [customParams] = useSelectFormListener(props);
	const [t] = useTranslation('app');
	const initOption = { [inputConfig.key]: -1, [inputConfig.print]: '' };

	const withOptionContent = props.map_config ? true : false;
	const inputC = withOptionContent
		? {
				xs: inputColumn.xs > 4 ? inputColumn.xs - 4 : undefined,
				md: inputColumn.md > 4 ? inputColumn.md - 4 : undefined
		  }
		: inputColumn;

	const selectColumns = inputC
		? { md: { size: inputC.md, offset: 12 - inputC.md } }
		: { md: { size: 10, offset: 2 } };

	useEffect(() => {
		if (!isMounted) setAllOptions();
		else setIsMounted(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (Object.values(customParams).length) handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customParams]);

	const onInputChange = (event) => {
		const { value } = event.target;
		setInputValue(value);
	};

	const getDefaultValueInfo = async () => {
		if (Array.isArray(defaultValue) && defaultValue.length) {
			const [newValue] = defaultValue;
			setSelectedItem({ [inputConfig.key]: newValue.key, [inputConfig.print]: newValue.value });
			return { [inputConfig.key]: newValue.key, [inputConfig.print]: newValue.value };
		}
		return {};
	};

	const setAllOptions = async (newOptions) => {
		const itemSelected = await getDefaultValueInfo();
		const newOpt = [initOption, inputConfig.key in itemSelected ? itemSelected : null];
		if (Array.isArray(newOptions)) newOpt.push(...newOptions);
		setOptions(newOpt);
	};

	const handleChange = (event) => {
		const { value: newValuePk, name } = event.target;
		const newItem = options.find((item) => item && item[inputConfig.key] === +newValuePk);
		setSelectedItem(newItem);
		onChange({ target: { name, value: +newValuePk === -1 ? '' : newItem[inputConfig.key] } });
	};

	const handleSearch = async () => {
		const query = inputValue;
		const extraParams = inputConfig.params ? inputConfig.params : {};
		const paramsFixed = inputConfig['params-fixed'] ? inputConfig['params-fixed'] : {};
		setLoading(true);
		const data = await authenticatedFetcher({
			path: `${inputConfig.endpoint}`,
			urlParams: {
				q: query,
				fields: inputConfig.print,
				...globalParams,
				...extraParams,
				...paramsFixed,
				...customParams,
				from_select: 1
			}
		});
		setLoading(false);
		if (data && !data.error) {
			const items = getItems(data).filter((item) => item[inputConfig.key] !== selectedItem[inputConfig.key]);
			setAllOptions(items);
		} else setAllOptions([]);
	};

	const isEditable = Array.isArray(alwaysEditableFields)
		? alwaysEditableFields.some((item) => item === name) || editable
		: editable;

	return (
		<FormGroup tag="fieldset" row>
			<Col {...labelColumn}>
				<Label for={name}>
					{required ? '*' : ''} {label}
				</Label>
			</Col>
			{isEditing && isEditable ? (
				<>
					<Col {...inputC}>
						<div className="d-flex flex-start mb-2 align-items-center noCustom">
							<span className="input-select-async-wrapper">
								<input
									type="text"
									className="form-control"
									onChange={onInputChange}
									disabled={!isEditing}
								/>
							</span>
							<button
								type="button"
								onClick={handleSearch}
								className="btn btn-dark btn-sm ml-1 d-flex"
								disabled={!isEditing || !isEditable}>
								<span className="btn-wrapper--icon">
									<FontAwesomeIcon icon={['fas', 'search']} className="text-light" />
								</span>
								<span className="btn-wrapper--label">{t('search')}</span>
							</button>
							<LoadingSpinner show={loading} />
						</div>
					</Col>
					{withOptionContent && <ToMapBtn onClick={toggleMap(selectedItem, props.map_config)} />}
					<Col {...selectColumns}>
						<select
							className={`form-control ${getInputStatus(required, value, errors)}`}
							name={name}
							onChange={handleChange}
							value={selectedItem[inputConfig.key] || ''}
							style={{ padding: 0 }}>
							{Array.isArray(options) && options.length
								? options.map((option, index) => {
										if (!option) return null;
										return (
											<option key={`option-${index}`} value={option[inputConfig.key]}>
												{option[inputConfig.print]}
											</option>
										);
								  })
								: null}
						</select>
						<PrintInputError errors={errors} />
						<InputDescription description={relatedData ? relatedData.description : ''} />
					</Col>
				</>
			) : (
				<Col {...inputColumn}>{typeof selectedItem === 'object' ? selectedItem[inputConfig.print] : null}</Col>
			)}
		</FormGroup>
	);
}

TypeSelectAsync.propTypes = Types.inputType;
