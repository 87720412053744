import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState, useEffect } from 'react';
import { NavLink } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Create from './Create';
import ListContext from './List/ListContext';

const HeadListTitles = ({
	id,
	name,
	fetchData,
	fields,
	page,
	withSpaces = true,
	setCheckAllInputs,
	addBtn,
	extraConfig
}) => {
	const [checked, setCheked] = useState(false);
	const [toggle, setToggle] = useState(false);
	const { checkbox = true } = extraConfig ? extraConfig : {};

	const listContext = useContext(ListContext);
	const { checkAll, selectedPage, orderFields } = listContext;
	useEffect(() => {
		if (selectedPage?.includes(page)) {
			setCheked(true);
		} else {
			setCheked(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const toggleModal = () => {
		setToggle(!toggle);
		localStorage.setItem('isLupaClicked', false);
	};
	const handelChangeOrder = (field) => () => {
		if (!field) return null;
		listContext.setOrderFields(field);
		listContext.setOrderType(listContext.orderType === 'asc' ? 'desc' : 'asc');
	};

	const handleCheckChange = () => {
		setCheked((prev) => !prev);
		checkAll(!checked);
	};

	const renderAddBtn = () => {
		if (addBtn) {
			return (
				<th className="card-header py-3">
					<div className="card-header--actions">
						<Button
							className="d-flex CustomInstallacioDocuments-addBtn"
							size="sm"
							color="success"
							onClick={toggleModal}>
							<span className="btn-wrapper--icon">
								<FontAwesomeIcon icon={['fas', 'plus']} className="text-light" />
							</span>
							{/* <span className="btn-wrapper--label"></span> */}
						</Button>
					</div>
					{toggle && <Create toggle={toggleModal} id={id} fetchData={fetchData} />}
				</th>
			);
		}
		return null;
	};

	return (
		<thead stylew={{ display: 'block', width: '10px' }}>
			<tr>
				{checkbox && (
					<th>
						<div className="select-all-checkbox">
							<input checked={checked} onChange={handleCheckChange} type="checkbox" />
						</div>
					</th>
				)}
				{fields.map((f) => {
					const { label, field, width, order_field, no_order } = f;
					const withIcon = !!(orderFields === order_field || orderFields === field);
					return (
						<th width={width} key={field} className="text-left">
							<NavLink
								onClick={handelChangeOrder(no_order ? null : order_field || field)}
								className={`${no_order ? 'linkWithoutPointer' : ''} ${withIcon ? 'linkWithIcon' : ''}`}>
								<span>{label}</span>
								{withIcon ? (
									<FontAwesomeIcon
										icon={['fas', listContext.orderType === 'asc' ? 'arrow-up' : 'arrow-down']}
									/>
								) : null}
							</NavLink>
						</th>
					);
				})}
				{renderAddBtn()}
				{withSpaces && <th className="text-left" style={{ width: '100px' }} />}
			</tr>
		</thead>
	);
};

export default HeadListTitles;
