import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ToMapBtn({ onClick = () => {} }) {
	return (
		<button type="button" className="ToMapBtn btn btn-link" onClick={onClick}>
			<FontAwesomeIcon icon={['fas', 'map-marked']} color="#4293CC" style={{ fontSize: '24px' }} />
		</button>
	);
}

ToMapBtn.propTypes = {
	onClick: PropTypes.func
};

export default ToMapBtn;
