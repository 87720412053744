import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANG, WITH_LANGUAGES } from './config';
import { readSessionStorage, writeSessionStorage } from './services/sessionStorageManagement';

import translations from './translations';

if (!readSessionStorage('i18nextLng')) writeSessionStorage('i18nextLng', LANG);

i18n.use(initReactI18next).init({
	resources: translations,
	fallbackLng: WITH_LANGUAGES ? readSessionStorage('i18nextLng', LANG) : LANG,
	interpolation: {
		escapeValue: false // not needed for react as it escapes by default
	}
});

export default i18n;
