import React, { useEffect, useState, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Label, Button } from 'reactstrap';
import BasicTable from '../Ticketing/BasicTable';
import authenticatedFetcher from '../../services/fetcher';
import { fileUploader } from '../../services/commonServices';
import { toastError, toastSuccess } from '../../utils/ToastConfig';
import { MEDIA_BASE_URL } from '../../config';
import LoadingSpinner from '../LoadingSpinner';
import { useTranslation } from 'react-i18next';
import Multiselect from '../DynamicForm/TypeForm/TypeMultiSelect';

const requiredFields = ['formation_type', 'file', 'mifid', 'lcci', 'esg', 'insurance', 'thematics'];
const initialState = {
	formation_type: parseInt(process.env.REACT_APP_FORMATION_DEFAULT),
	mifid: 1,
	lcci: 0,
	esg: 0,
	insurance: 0
};

const multiselectOptions = [
	{ label: 'MIFID', value: 1 },
	{ label: 'LCCI', value: 2 },
	{ label: 'ESG', value: 3 },
	{ label: 'SEGUROS', value: 4 }
];

export default function CustomModalsPartnersCourse(props) {
	const { toggle, open } = props;
	const [entitiesSelect, setEntitiesSelect] = useState([]);
	const [formationSelect, setFormationSelect] = useState([]);
	const [tableConfig, setTableConfig] = useState();
	const [haveError, setHaveError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [importLoading, setImportLoading] = useState(false);
	const [loadingErrors, setLoadingErrors] = useState(false);
	const [resetCount, setResetCount] = useState(0);
	const [t] = useTranslation('app');
	const [formData, setFormData] = useState(initialState);

	useEffect(() => {
		getSelectsData();
	}, []);

	const resetModal = () => {
		setFormData(initialState);
		setTableConfig(null);
		setHaveError(false);
		setResetCount((prev) => prev + 1);
	};

	const getSelectsData = async () => {
		const entitesItems = await authenticatedFetcher({
			path: '/enterprises/import-courses/select/'
		});
		const formationsItems = await authenticatedFetcher({
			path: '/persons/hours/formation-types/'
		});
		setEntitiesSelect(entitesItems);
		setFormationSelect(formationsItems);
	};

	const handleCheck = (inputName) => () => {
		setFormData((prev) => ({
			...prev,
			mifid: 0,
			lcci: 0,
			esg: 0,
			insurance: 0,
			[inputName]: prev[inputName] ? 0 : 1
		}));
	};

	const handleSelectChange = ({ target: { value, name } }) =>
		setFormData((prev) => ({ ...prev, [name]: Array.isArray(value) ? value.join(',') : parseInt(value) }));

	const handleFile = async (inputEvent) => {
		const fileUploaded = await fileUploader(inputEvent);
		if (!fileUploaded) return;
		setFormData((prev) => ({ ...prev, ...fileUploaded }));
		inputEvent.value = '';
	};

	async function onPreloadExcel() {
		if (requiredFields.some((item) => !(item in formData)) || !formData.thematics)
			return toastError(t('errors.requiredFields'));
		setLoading(true);
		const res = await authenticatedFetcher({
			path: '/persons/hours-virtual-classroom/import-courses/previous/',
			method: 'POST',
			body: formData
		});
		setLoading(false);
		if ('error' in res) {
			const msg = res.error_data
				? Object.values(res.error_data || {})
						.map((item) => item[0])
						.join('<br/>')
				: res.error;
			return toastError(msg, { autoClose: false }, true);
		}
		setTableConfig({
			...res,
			header: res.header.map((item) => ({ title: item.label, cellFieldName: item.field }))
		});
		setHaveError(Array.isArray(res.items) && res.items.some((item) => item.error));
	}

	const onExportErrors = async () => {
		setLoadingErrors(true);
		const res = await authenticatedFetcher({
			path: '/persons/hours-virtual-classroom/import-courses/errors/',
			method: 'POST',
			body: formData
		});
		setLoadingErrors(false);
		if (res && 'response' in res) {
			return toastSuccess(res.response, { autoClose: !res.print_fixed_message_response }, true);
		}
	};

	const onSubmit = async () => {
		setImportLoading(true);
		const res = await authenticatedFetcher({
			path: '/persons/hours-virtual-classroom/import-courses/',
			method: 'POST',
			body: formData
		});
		setImportLoading(false);
		if (res?.action) {
			resetModal();
			toastSuccess(res.action);
		} else {
			toastError('Error');
		}
	};

	const renderSecondColumn = () => {
		if (!tableConfig) return null;
		return (
			<Fragment>
				<div className="PartnerCourse-row">
					<div className="col-6">
						<Label className="PartnerCourse-label" for={'entidad'}>
							{t('total_lines')}:
						</Label>
					</div>
					<div className="col-6">
						<input className="form-control mt-3" type="text" disabled value={tableConfig.total_lines} />
					</div>
				</div>
				<div className="PartnerCourse-row">
					<div className="col-6">
						<Label className="PartnerCourse-label" for={'entidad'}>
							{t('modals.correct_assoc')}:
						</Label>
					</div>
					<div className="col-6">
						<input className="form-control mt-3" type="text" disabled value={tableConfig.persons_ok} />
					</div>
				</div>
				<div className="PartnerCourse-row">
					<div className="col-6">
						<Label className="PartnerCourse-label" for={'entidad'}>
							{t('modals.unidentified_assoc')}:
						</Label>
					</div>
					<div className="col-6">
						<input className="form-control mt-3" type="text" disabled value={tableConfig.person_ko} />
					</div>
				</div>
			</Fragment>
		);
	};

	const renderTable = () => {
		if (!tableConfig) return null;
		return (
			<Fragment>
				<div className="col-12">
					<div className="mt-5 ml-0 mr-0">
						<BasicTable
							className="pl-0 pr-0 ExportTest-table"
							header={tableConfig.header}
							items={tableConfig.items}
							useActions={false}
						/>
					</div>
				</div>
				<div className="col-12">
					<div className="d-flex justify-content-end w-100">
						<Button className="mt-3 mb-4 mr-3 submitButton" onClick={onSubmit}>
							Importar
							<LoadingSpinner show={importLoading} className="m-0" />
						</Button>
						{haveError && (
							<Button className="mt-3 mb-4 mr-3 submitButton" onClick={onExportErrors}>
								{t('export_errors')}
								<LoadingSpinner show={loadingErrors} className="m-0" />
							</Button>
						)}
						<Button className="mt-3 mb-4 submitButton" onClick={resetModal}>
							{t('restart_import')}
						</Button>
					</div>
				</div>
			</Fragment>
		);
	};

	return (
		<Modal toggle={toggle} isOpen={open} className="persona">
			<ModalHeader toggle={toggle} charCode="x">
				{t('modals.import_manual_courses')}
			</ModalHeader>
			<ModalBody>
				<Card>
					<CardBody>
						<div className="PartnerCourse">
							<div className="row">
								<div className="col-12 col-md-8">
									<div className="PartnerCourse-field dynamic-form">
										<div className="row align-items-center">
											<div className="col-12 col-md-3">
												<Label className="PartnerCourse-label" for={'entidad'}>
													{t('modals.template')}:
												</Label>
											</div>
											<div className="col-12 col-md-9">
												<a
													href={`${MEDIA_BASE_URL}/media/import-templates-examples/plantilla-importacion-cursos-manuales.xlsx`}
													target="_blank"
													rel="noopener noreferrer">
													{t('modals.donwload_sample_template')}
												</a>
											</div>
											<div className="col-12 col-md-3">
												<Label className="PartnerCourse-label" for={'entidad'}>
													{t('entity')}:
												</Label>
											</div>
											<div className="col-12 col-md-9">
												<select
													className="form-control mb-3"
													name="enterprise"
													onChange={handleSelectChange}
													key={`enterprise-${resetCount}`}
													id="entidad">
													{entitiesSelect.map((item, i) => (
														<option value={item.pk} key={`entitiesoption-${i}`}>
															{item.name}
														</option>
													))}
												</select>
											</div>
											<div className="col-12 col-md-3">
												<Label className="PartnerCourse-label" for={'entidad'}>
													{t('training_type')} (*):
												</Label>
											</div>
											<div className="col-12 col-md-3">
												<select
													className="form-control mb-3"
													name="formation_type"
													onChange={handleSelectChange}
													value={formData['formation_type']}
													key={`formation_type-${resetCount}`}
													id="entidad">
													{formationSelect.map((item, i) => (
														<option value={item.pk} key={`formationOption-${i}`}>
															{item.name}
														</option>
													))}
												</select>
											</div>
											<div className="col-12 col-md-3">
												<Label className="PartnerCourse-label" for={'entidad'}>
													{t('modals.training_valid_for')} (*):
												</Label>
											</div>
											<div className="col-12 col-md-3">
												<div className="PartnerCourse-radios">
													<label className="PartnerCourse-labelRadio">
														<input
															className="mr-2"
															type="radio"
															name="type"
															onChange={handleCheck('mifid')}
															checked={formData['mifid']}
														/>
														Mifid
													</label>
													<label className="PartnerCourse-labelRadio">
														<input
															className="mr-2"
															type="radio"
															name="type"
															onChange={handleCheck('lcci')}
															checked={formData['lcci']}
														/>
														LCCI
													</label>
													<label className="PartnerCourse-labelRadio">
														<input
															className="mr-2"
															type="radio"
															name="type"
															onChange={handleCheck('esg')}
															checked={formData['esg']}
														/>
														ESG
													</label>
													<label className="PartnerCourse-labelRadio">
														<input
															className="mr-2"
															type="radio"
															name="type"
															onChange={handleCheck('insurance')}
															checked={formData['insurance']}
														/>
														SEGUROS
													</label>
												</div>
											</div>
											<div className="col-3">
												<Label className="PartnerCourse-label" for={'entidad'}>
													{t('modals.file_to_import')} (*):
												</Label>
											</div>
											<div className="col-9">
												<span>{formData['file']}</span>
												<input
													type="file"
													key={`file-${resetCount}-${formData['file']}`}
													name="file"
													onChange={handleFile}
												/>
											</div>
											<div className="col-12">
												<Multiselect
													items={multiselectOptions}
													label="Temáticas (*):"
													name="thematics"
													onChange={handleSelectChange}
													labelColumn={{
														xs: 6,
														md: 3,
														className: 'PartnerCourse-label'
													}}
													inputColumn={{
														xs: 6,
														md: 9
													}}
													withoutFetch
													editable
												/>
											</div>
										</div>
										<Button className="mt-3 mb-4 submitButton" onClick={onPreloadExcel}>
											{t('modals.preload_excel')}
											<LoadingSpinner show={loading} className="m-0" />
										</Button>
									</div>
								</div>
								<div className="col-12 col-md-4">{renderSecondColumn()}</div>
								{renderTable()}
							</div>
						</div>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
}
