import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useState } from 'react';
import { searchParams, stringToStyleObj } from '../../services/commonServices';
import parse from 'html-react-parser';
import { TABLE_REDIRECT_DOUBLECLICK } from '../../config';
import authenticatedFetcher from '../../services/fetcher';

const getTdProps = ({ columnInfo = {}, rowData = {}, redirectToDetail = () => {}, editable }) => {
	const spanStyle = columnInfo.style ? stringToStyleObj(columnInfo.style) : {};
	const isSpanClassString = typeof columnInfo?.class === 'string';
	const haveEvent =
		columnInfo.map ||
		columnInfo.allData?.field === 'state' ||
		((columnInfo.allData.type === 'check' ||
			columnInfo.allData.type === 'select' ||
			columnInfo.allData.type === 'integer') &&
			!rowData.block_form);
	let newProps = { spanStyle, isSpanClassString };
	return editable
		? {
				...newProps,
				onClick: haveEvent || TABLE_REDIRECT_DOUBLECLICK ? null : redirectToDetail,
				onDoubleClick: haveEvent || TABLE_REDIRECT_DOUBLECLICK === false ? null : redirectToDetail,
				className: haveEvent ? '' : 'tdWithEvent'
		  }
		: newProps;
};

const TableItemTd = (props) => {
	const { columnInfo, handleOpenMapModal, mainKey, rowData, handleColumnCheck, handleChangeInput } = props;
	const isSelect = columnInfo.allData.type === 'select';
	const [{ spanStyle, isSpanClassString, ...tdProps }] = useState(getTdProps(props));
	const [loading, setLoading] = useState(isSelect);
	const [selectData, setSelectData] = useState([]);
	const [hasChange, setHasChange] = useState(false);

	useEffect(() => {
		if (isSelect) {
			const { allData } = columnInfo;
			const urlParams = searchParams(allData.options, rowData);
			authenticatedFetcher({
				path: allData.options.endpoint,
				urlParams
			}).then((res) => {
				setLoading(false);
				setSelectData(res);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleHasChange = () => setHasChange(true);

	const renderDynamicTd = () => {
		if (loading) return '...';
		if (columnInfo.map)
			return (
				<span onClick={handleOpenMapModal(columnInfo.map)}>
					<FontAwesomeIcon icon={['fas', 'map-marked']} color="#4293CC" style={{ fontSize: '18px' }} />
				</span>
			);
		if (columnInfo.allData.type === 'check' && !rowData.block_form)
			return (
				<input
					type="checkbox"
					defaultChecked={columnInfo.content}
					onChange={handleColumnCheck(columnInfo.allData, mainKey)}
				/>
			);
		if (isSelect && !rowData.block_form)
			return (
				<select
					className="form-control form-control-sm mt-1 mr-1"
					value={columnInfo.content}
					onChange={handleChangeInput(columnInfo.allData, mainKey)}>
					<option value={null}>Seleccione una opción</option>
					{Array.isArray(selectData) &&
						selectData.map((item, i) => (
							<option
								key={`Select: ${columnInfo.allData.field} - option: ${i}`}
								value={item[columnInfo.allData.options.key]}>
								{item[columnInfo.allData.options.print]}
							</option>
						))}
				</select>
			);
		if (columnInfo.allData.type === 'integer' && !rowData.block_form)
			return (
				<input
					className="form-control form-control-sm BasicInput-typeNumber"
					defaultValue={columnInfo.content}
					type="number"
					onChange={handleHasChange}
					onBlur={hasChange ? handleChangeInput(columnInfo.allData, mainKey) : undefined}
				/>
			);
		if (typeof columnInfo.content === 'boolean' && !columnInfo.map) {
			return (
				<span
					style={spanStyle || {}}
					className={cx('status-from-buttons-in-table-item-list', isSpanClassString && columnInfo?.class)}
					dangerouslySetInnerHTML={{
						__html: columnInfo.content === true ? 'Sí' : 'No'
					}}
				/>
			);
		}
		if (
			'link' in columnInfo.allData ||
			'blank' in columnInfo.allData ||
			'title_link_fixed' in columnInfo.allData ||
			'title_link' in columnInfo.allData
		) {
			let label = rowData[columnInfo.allData.title_link] || '';
			if (!label && columnInfo.allData.title_link_fixed) label = columnInfo.allData.title_link_fixed;
			if (!label) label = columnInfo.content;
			const aProps = columnInfo.allData.blank ? { target: '_blank', rel: 'noopener noreferrer' } : {};
			return (
				<span
					style={spanStyle || {}}
					className={cx('status-from-buttons-in-table-item-list', isSpanClassString && columnInfo?.class)}>
					<a href={columnInfo.content} {...aProps}>
						{label}
					</a>
				</span>
			);
		}
		return (
			<span
				style={spanStyle || {}}
				className={cx('status-from-buttons-in-table-item-list', isSpanClassString && columnInfo?.class)}
				dangerouslySetInnerHTML={{ __html: columnInfo.content }}
			/>
		);
	};
	const renderDescription = () =>
		`${columnInfo.allData.field}__description` in rowData ? (
			<div className="d-block">{parse(rowData[`${columnInfo.allData.field}__description`])}</div>
		) : null;

	return (
		<td {...tdProps}>
			{renderDynamicTd()}
			{renderDescription()}
		</td>
	);
};

TableItemTd.propTypes = {
	columnInfo: PropTypes.object,
	handleOpenMapModal: PropTypes.func,
	mainKey: PropTypes.number,
	rowData: PropTypes.object,
	handleColumnCheck: PropTypes.func,
	redirectToDetail: PropTypes.func,
	editable: PropTypes.bool
};

export default TableItemTd;
