import React from 'react';
import PropTypes from 'prop-types';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const defaultOptions = {
	responsive: true,
	plugins: {
		legend: {
			display: false
		},
		title: {
			display: true,
			text: 'Chart.js Line Chart'
		}
	}
};

const getGraphData = ({ xTitle, yTitle, title, xLabels, lineColor = 'rgb(255, 0, 0)', data }) => {
	let options = { ...defaultOptions };
	let graphData = {
		labels: xLabels,
		datasets: [
			{
				data: Array.isArray(data) && data,
				backgroundColor: 'rgb(255, 0, 0)',
				minBarLength: 5
			}
		]
	};
	if (title)
		options['plugins'] = {
			legend: {
				display: false
			},
			title: {
				display: true,
				text: title
			}
		};
	if (xTitle)
		options['scales'] = {
			...options['scales'],
			xAxes: {
				title: {
					display: true,
					text: xTitle
				}
			}
		};
	if (yTitle)
		options['scales'] = {
			...options['scales'],
			yAxes: {
				title: {
					display: true,
					text: yTitle
				},
				ticks: {
					stepSize: 1
				}
			}
		};
	return { options, data: graphData };
};

const BarGraph = (props) => {
	const { className } = props;
	const { options, data } = getGraphData(props);

	return <div className={className}>{data && options && <Bar options={options} data={data} />}</div>;
};

BarGraph.propTypes = {
	className: PropTypes.string,
	xTitle: PropTypes.string,
	yTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	xLabels: PropTypes.array.isRequired,
	lineColor: PropTypes.string,
	data: PropTypes.array.isRequired
};

export default BarGraph;
