import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import ImgsWithLightBox from '../ImgsWithLightBox';
import authenticatedFetcher from '../../services/fetcher';
import ImageUpload from '../ImageUpload';
import DeleteModal from '../TableComponents/DeleteModal';
import { useTranslation } from 'react-i18next';

// const dummyData = [
// 	{ path: 'https://picsum.photos/700/400?random' },
// 	{ path: 'https://picsum.photos/701/400?random' },
// 	{ path: 'https://picsum.photos/700/401?random' },
// 	{ path: 'https://picsum.photos/702/400?random' },
// 	{ path: 'https://picsum.photos/700/402?random' },
// 	{ path: 'https://picsum.photos/703/400?random' }
// ];

const ImatgesComponent = ({ module, id, postParams, getParams, disabled, title }) => {
	const [images, setImages] = useState([]);
	const [imgToDelete, setImgToDelete] = useState();
	const [t] = useTranslation('app');

	useEffect(() => {
		if ((module && id) || typeof getParams === 'object') getImages();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getImages = async () => {
		const imgs = await authenticatedFetcher({
			path: '/tele-photos/',
			urlParams: getParams || { [module]: id, "nitems": 1000 }
		});
		if (Array.isArray(imgs)) setImages(imgs.map((item) => ({ src: item.photo, alt: item.photo, ...item })));
	};

	const onAddImg = async (dataToSave) => {
		if (!((module && id) || typeof getParams === 'object') || disabled) return null;
		const res = await authenticatedFetcher({
			path: '/tele-photos/',
			body: postParams ? { ...postParams, ...dataToSave } : { ...dataToSave, [module]: id },
			method: 'POST'
		});
		if (res) getImages();
	};

	const handleDelete = async () => {
		if (!((module && id) || typeof getParams === 'object') || disabled) return null;
		const res = await authenticatedFetcher({
			path: `/tele-photos/${imgToDelete.pk}/`,
			method: 'DELETE'
		});
		if (res) getImages();
		closeDeleteModal();
	};

	const onDelete = (imgData) => setImgToDelete(imgData);
	const closeDeleteModal = () => setImgToDelete(null);

	const renderImgs = () => {
		if (!images.length) return t('imatgeComponent.noData');
		return <ImgsWithLightBox images={images} onDelete={onDelete} disabled={disabled} />;
	};

	return (
		<Card className="card-box mb-3">
			<div className="card-header py-3">
				<div className="card-header--title font-size-lg d-flex justify-content-between">{`${
					title || 'IMATGES'
				} (${images.length} imatges)`}</div>
				<div className="card-header--actions">
					<ImageUpload onChange={onAddImg} name="photo_url" noAutoSave disabled={disabled} />
				</div>
			</div>
			<div className="card-body py-3">{renderImgs()}</div>
			<DeleteModal isOpen={!!imgToDelete} toggle={closeDeleteModal} onDelete={handleDelete} />
		</Card>
	);
};

ImatgesComponent.propTypes = {
	onAddImage: PropTypes.func,
	module: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired
};

export default ImatgesComponent;
