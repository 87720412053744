import React from 'react';
import { Button, Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BasicIcon from '../BasicIcon';

const BasicModal = ({
	isOpen,
	toggle,
	title,
	description,
	children,
	onConfirm,
	onCancel,
	confirmText,
	cancelText,
	variant
}) => {
	const [t] = useTranslation('app');
	return (
		<Modal zIndex={2000} centered isOpen={isOpen} toggle={toggle}>
			<div className="text-center p-5">
				<div className="avatar-icon-wrapper rounded-circle m-0">
					<div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
						<BasicIcon
							iconName={variant === 'delete' ? 'faTimes' : 'faQuestion'}
							className="d-flex align-self-center display-3"
						/>
					</div>
				</div>
				<h4 className="font-weight-bold mt-4">{title}</h4>
				{description && <p className="mb-0 font-size-lg text-muted">{description}</p>}
				<div className="container">{children}</div>
				<div className="pt-4">
					<Button onClick={onCancel} color="neutral-secondary" className="btn-pill mx-1">
						<span className="btn-wrapper--label">{cancelText ? cancelText : t('trashModal.cancel')}</span>
					</Button>
					<Button onClick={onConfirm} color="danger" className="btn-pill mx-1">
						<span className="btn-wrapper--label">{confirmText ? confirmText : t('trashModal.remove')}</span>
					</Button>
				</div>
			</div>
		</Modal>
	);
};

BasicModal.propTypes = {
	isOpen: PropTypes.bool,
	toggle: PropTypes.func,
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	children: PropTypes.node,
	onConfirm: PropTypes.func,
	onCancel: PropTypes.func,
	confirmText: PropTypes.string,
	cancelText: PropTypes.string,
	variant: PropTypes.oneOf("delete")
};

export default BasicModal;
