import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import faker from 'faker';
import LineGraph from './LineGraph';
import authenticatedFetcher from '../../../../services/fetcher';
import BarGraph from './BarGraph';

const graphTypes = {
	line: LineGraph,
	bar: BarGraph
};

const TypeGraphs = (props) => {
	const { value } = props;
	const refreshTimeout = useRef(null);
	const [graphsData, setGraphsData] = useState();

	useEffect(() => {
		setGraphsData(value?.graphs || []);
		if (value?.refreshTimeout && value?.refreshConfig && value?.refreshConfig.endpoint)
			startRefreshTimeout(value.refreshConfig);
		return () => {
			clearTimeout(refreshTimeout.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const startRefreshTimeout = (config) => {
		clearTimeout(refreshTimeout.current);
		refreshTimeout.current = setTimeout(() => {
			authenticatedFetcher(config).then((res) => {
				setGraphsData(res);
				startRefreshTimeout(config);
			});
		}, value.refreshConfig);
	};

	return (
		<div className="row">
			{Array.isArray(graphsData) &&
				graphsData.map((item, i) => {
					if (!item) return null;
					const GraphComponent = graphTypes[item.type || 'line'];
					return <GraphComponent key={item.title + i} className="col-12 col-lg-6" {...item} />;
				})}
		</div>
	);
};

TypeGraphs.propTypes = {
	value: PropTypes.shape({
		refreshConfig: PropTypes.shape({
			endpoint: PropTypes.string,
			method: PropTypes.string,
			params: PropTypes.shape
		}),
		refreshTimeout: PropTypes.number,
		graphs: [
			{
				xTitle: PropTypes.string,
				yTitle: PropTypes.string,
				title: PropTypes.string,
				xLabels: PropTypes.array,
				lineColor: PropTypes.string,
				data: PropTypes.array
			}
		]
	})
};

export default TypeGraphs;
